import { Form, Slider } from 'antd';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    CaretDown,
    CaretUp,
    FadersHorizontal,
    MagnifyingGlass,
    X
} from 'phosphor-react';
//
import { ICombineReducerType } from '../../redux/reducers';
import { useTranslatedOptions } from '../../utils/Hooks/TranslatedOptions';
import {
    $FIXME,
    COUNTRY_TOP_LEVEL_DOMAIN,
    IMAGE_URL,
    ROUTES
} from '../../constants';
import { getTopLevelDomain } from '../../utils/translation';
import Selects from '../ui/Select';
import RangeFilterModal from '../ui/RangeFilterModal';
import {
    contentAccordToCurrentLang,
    formatKm
} from '../../utils/Helper/jsHelpers';
import text_truncate from '../../utils/Helper/TextTruncate';
import ClickOutside from '../ClickOutside';
import { useLgMediaQuery } from '../../utils/Hooks/MediaQuery';
import { api } from '../../helpers/api';
import { getCarAvailableBrand } from '../../redux/actions/carModelsAction';
import {
    decodeCompactNumber,
    formatNumberCompact
} from '../../utils/Helper/PriceFormatter';

// TODO fix all the typing
interface searchOptions {
    fuelType: string[];
    transmission: string[];
    bodyType: string[];
    condition: string[];
    color: string[];
    minMonthlyInstallment: string;
    maxMonthlyInstallment: string;
    minModelYear: string;
    maxModelYear: string;
}

const ExpandFilterSection = ({
    children,
    title
}: {
    children: ReactNode;
    title: string;
}) => {
    const [collapse, setCollapse] = useState(false);
    return (
        <div className="filter-expand-container">
            <div className="filter-expanded-header">
                <span className="filter-expand-title">{title}</span>
                <button
                    className="filter-expand-btn"
                    onClick={() => setCollapse(!collapse)}
                >
                    {collapse ? (
                        <CaretDown color="#175ADC" weight="bold" />
                    ) : (
                        <CaretUp color="#175ADC" weight="bold" />
                    )}
                </button>
            </div>
            {collapse ? <></> : <>{children}</>}
        </div>
    );
};
const CarFilterSection = ({
    activeBrand,
    activeModel,
    activeBodyType,
    priceRange
}: {
    activeBrand?: string;
    activeModel?: string;
    activeBodyType?: string;
    priceRange?: string;
}) => {
    const [modelYears, setModelYears] = useState([]);
    const [modelsList, setModelsList] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [show_more_filter, setShowMoreFilter] = useState(false);
    const [show_mileage_range_modal, setShowMileageRangeModal] =
        useState(false);
    const [show_price_range_model, setShowPriceRangeModal] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({
        brand: activeBrand ? [activeBrand] : searchParams.getAll('brand') ?? [],
        model: activeModel ? [activeModel] : searchParams.getAll('model') ?? [],
        fuelType: searchParams.getAll('fuelType') ?? [],
        transmission: searchParams.getAll('transmission') ?? [],
        bodyType: activeBodyType
            ? [activeBodyType]
            : searchParams.getAll('bodyType') ?? [],
        condition: searchParams.getAll('condition') ?? [],
        color: searchParams.getAll('color') ?? [],
        minModelYear: searchParams.get('minModelYear') ?? '',
        maxModelYear: searchParams.get('maxModelYear') ?? '',
        minMonthlyInstallment: '',
        maxMonthlyInstallment: '',
        isNewCar: searchParams.getAll('isNewCar') ?? '',
        isElectric: searchParams.getAll('isElectric') ?? '',
        imported: searchParams.getAll('imported') ?? '',
        minPrice: searchParams.get('minPrice') ?? '',
        maxPrice: priceRange ? priceRange : searchParams.get('maxPrice') ?? ''
    });

    const carBrands = useSelector(
        (state: ICombineReducerType) => state.carBrands.carAvailableBrands
    );
    const carModels = useSelector(
        (state: ICombineReducerType) => state.carModels.carAvilableModel
    );
    const bodyTypes = useSelector(
        (state: ICombineReducerType) => state.car.availableBodyTypes
    );
    const carColors = useSelector(
        (state: ICombineReducerType) => state.car.availableCarColor
    );
    const { fuelTypes, transmissionTypes } = useTranslatedOptions();
    const topLevelDomain = getTopLevelDomain();
    const dispatch = useDispatch();

    const handleSearchChangeDebounce = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        if (!event.target.value)
            setSearchParams((prevSearchParam) => {
                const newParam = new URLSearchParams(prevSearchParam);
                newParam.delete('search');
                return newParam;
            });
        else
            setSearchParams((prev) => ({
                ...prev,
                search: event.target.value
            }));
    };

    const handleSelectChange = (value: string[] | string, key: string) => {
        setSearchParams((prevSearchParam) => {
            const newParam = new URLSearchParams(prevSearchParam);
            newParam.delete(key);

            if (Array.isArray(value)) {
                value.forEach((v) => {
                    newParam.append(key, v);
                });

                if (key === 'brand') {
                    const selectedBrands = newParam.getAll('brand');
                    const modelParams = newParam.getAll('model');
                    const validModels = modelParams.filter((mp) =>
                        selectedBrands.some((brand) => mp.includes(brand))
                    );

                    newParam.delete('model');
                    validModels.forEach((model) => {
                        newParam.append('model', model);
                    });
                }
            } else if (value) {
                newParam.set(key, value);
            }

            return newParam;
        });
    };

    const handleSingleSelectChange = (value: string, key: string) => {
        if (key === 'bodyType') {
            if (value) {
                navigate(`${t('/body-type', { ns: 'routes' })}/${value}`);
                setSelectedOptions({
                    ...selectedOptions,
                    [key]: [value]
                });
            }
        }
        if (key === 'brand') {
            if (value) {
                navigate(`${t('/buy-cars', { ns: 'routes' })}/${value}`);

                setSelectedOptions({
                    ...selectedOptions,
                    model: [],
                    [key]: [value]
                });
            }
        }
        if (key === 'model') {
            if (value) {
                navigate(
                    `${t('/buy-cars', {
                        ns: 'routes'
                    })}/${value}`
                );

                setSelectedOptions({
                    ...selectedOptions,
                    [key]: [value]
                });
            }
        }
        if (key === 'clearModel') {
            navigate(
                `${t('/buy-cars', {
                    ns: 'routes'
                })}/${activeBrand}`
            );

            setSelectedOptions({
                ...selectedOptions,
                brand: activeBrand ? [activeBrand] : []
            });
        }
    };

    const formatMoney = (n: any, isCurrency = true) => {
        return isCurrency
            ? `${
                  topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza
                      ? process.env.REACT_APP_DEFAULT_CURRENCY
                      : ''
              }` +
                  ' ' +
                  (Math.round(n * 100) / 100)
                      .toLocaleString()
                      .replaceAll(',', ' ') +
                  ' ' +
                  `${
                      topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.coza
                          ? process.env.REACT_APP_DEFAULT_CURRENCY
                          : ''
                  }`
            : (Math.round(n * 100) / 100).toLocaleString().replaceAll(',', ' ');
    };

    const handleSelectFieldChange = (
        key: 'transmission' | 'bodyType' | 'fuelType' | 'condition' | 'color',
        value: string
    ) => {
        const fieldValues = selectedOptions[key] || [];
        let newFieldValue = [...fieldValues];
        const valueIndex = newFieldValue.indexOf(value);
        if (valueIndex === -1) newFieldValue.push(value);
        else newFieldValue.splice(valueIndex, 1);
        setSelectedOptions((prevSelectedOption) => ({
            ...prevSelectedOption,
            [key]: newFieldValue
        }));
    };

    const handleApplyFilter = () => {
        setSearchParams((prevSearchParams) => {
            const newParam = new URLSearchParams(prevSearchParams);
            Object.keys(selectedOptions).forEach((key) => {
                const objectKey = key as keyof searchOptions;
                const value = selectedOptions[objectKey];
                if ((Array.isArray(value) && value.length === 0) || !value)
                    newParam.delete(key);
                else {
                    if (Array.isArray(value))
                        value.map((el) => newParam.append(key, el));
                    else newParam.set(key, value);
                }
            });
            if (activeBodyType) {
                newParam.delete('bodyType');
            }
            return newParam;
        });
        setShowMoreFilter(false);
    };
    const handleClearAll = () => {
        setSelectedOptions({
            model: [],
            brand: [],
            fuelType: [],
            transmission: [],
            bodyType: [],
            condition: [],
            color: [],
            minModelYear: '',
            maxModelYear: '',
            minMonthlyInstallment: '',
            maxMonthlyInstallment: '',
            isNewCar: [],
            isElectric: [],
            imported: [],
            minPrice: '',
            maxPrice: ''
        });
        navigate(t('/buy-cars', { ns: 'routes' }));
    };

    useEffect(() => {
        api.get('/cars/get-car-years').then((res) => {
            setModelYears(res.data.modelYears);
        });
    }, []);

    useEffect(() => {
        if (carModels.filter((item: $FIXME) => item).length > 0) {
            const carModelsList = carModels.map((items: $FIXME) => ({
                label: `${items.model}`,
                value: items.slug,
                _id: items._id
            }));
            setModelsList([...carModelsList]);
        }
    }, [carModels, activeBrand]);

    useEffect(() => {
        if (activeBrand) {
            getCarAvailableBrand(dispatch, activeBrand);
        }
    }, [activeBrand, dispatch, selectedOptions]);

    useEffect(() => {
        const fetchCarModels = async () => {
            let value = searchParams.getAll('brand');
            let modelsListData = [];

            if (Array.isArray(value)) {
                for (const val of value) {
                    const carModels = await getCarAvailableBrand(dispatch, val);
                    modelsListData.push(...carModels);
                }
            }

            const carModelsList = modelsListData.map((item: any) => ({
                label: `${item.model}`,
                value: item.slug,
                _id: item._id
            }));

            setModelsList(carModelsList);
        };

        fetchCarModels();
    }, [searchParams]);

    return (
        <>
            <section className="bg-white">
                <div className="padding">
                    <div className="car-list-filter-section">
                        <div className="car-list-filter-search-field">
                            <input
                                className="car-list-search-field"
                                type="search"
                                name="search"
                                value={searchParams.get('search') || ''}
                                placeholder={t('searchBarPlaceholder', {
                                    ns: 'hero'
                                })}
                                onChange={handleSearchChangeDebounce}
                            />
                            <div className="input-field-icon">
                                <MagnifyingGlass size={20} color="#B3B3B3" />
                            </div>
                        </div>
                        <div className="car-filter-item">
                            <Selects
                                placeholder={t('brand')}
                                options={
                                    carBrands.length > 0
                                        ? carBrands.map((item) => ({
                                              label: item.brand,
                                              value: item.slug,
                                              _id: item._id
                                          }))
                                        : []
                                }
                                onSelect={
                                    activeBrand
                                        ? handleSingleSelectChange
                                        : handleSelectChange
                                }
                                name="brand"
                                value={
                                    activeBrand
                                        ? activeBrand
                                        : searchParams.getAll('brand')
                                }
                                mode={activeBrand ? 'single' : 'multiple'}
                                maxTagCount="responsive"
                                class="car-filter-select-field select-ant-override"
                                iconSize={20}
                            />
                        </div>
                        <div className="car-filter-item">
                            <Selects
                                placeholder={t('model')}
                                options={modelsList}
                                onSelect={
                                    activeBrand
                                        ? handleSingleSelectChange
                                        : handleSelectChange
                                }
                                showSearch={true}
                                name="model"
                                value={
                                    activeModel
                                        ? activeModel
                                        : searchParams.getAll('model')
                                }
                                disabled={
                                    !activeBrand &&
                                    searchParams.getAll('brand').length === 0
                                }
                                mode={activeBrand ? 'single' : 'multiple'}
                                maxTagCount="responsive"
                                class="car-filter-select-field select-ant-override"
                                iconSize={20}
                                onClearSelect={() =>
                                    handleSingleSelectChange(
                                        'clearModel',
                                        'clearModel'
                                    )
                                }
                            />
                        </div>
                        <div className="car-filter-item">
                            <div>
                                <div
                                    className="car-filter-select-field"
                                    onClick={() =>
                                        setShowMileageRangeModal(
                                            !show_mileage_range_modal
                                        )
                                    }
                                >
                                    {!!searchParams.get('maxMileage') ? (
                                        <span className="show-range">
                                            {searchParams.get('minMileage')}-
                                            {searchParams.get('maxMileage')} KM
                                            <span
                                                className="reset-icon"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSearchParams(
                                                        (prevSearchParam) => {
                                                            const newParam =
                                                                new URLSearchParams(
                                                                    prevSearchParam
                                                                );
                                                            newParam.delete(
                                                                'maxMileage'
                                                            );
                                                            newParam.delete(
                                                                'minMileage'
                                                            );
                                                            return newParam;
                                                        }
                                                    );
                                                }}
                                            >
                                                <X size={8} color="white" />
                                            </span>
                                        </span>
                                    ) : (
                                        <span className="placeholder-text">
                                            {t('mileage')
                                                .charAt(0)
                                                .toLocaleUpperCase() +
                                                t('mileage').slice(1)}
                                        </span>
                                    )}

                                    <CaretDown
                                        size={20}
                                        color="#b3b3b3"
                                        weight="bold"
                                    />
                                </div>
                                {/* Mileage Range Modal */}
                                <ClickOutside
                                    hideModal={() =>
                                        setShowMileageRangeModal(false)
                                    }
                                    is_popup_visible={show_mileage_range_modal}
                                >
                                    <RangeFilterModal
                                        showAt="left"
                                        inputPrefix="KM"
                                        visible={show_mileage_range_modal}
                                        setvisible={setShowMileageRangeModal}
                                        minValue={
                                            searchParams.get('minMileage') || ''
                                        }
                                        maxValue={
                                            searchParams.get('maxMileage') || ''
                                        }
                                        handleSave={(range) => {
                                            setSearchParams((prevParam) => {
                                                const newParam =
                                                    new URLSearchParams(
                                                        prevParam
                                                    );
                                                newParam.set(
                                                    'minMileage',
                                                    range.min
                                                );
                                                newParam.set(
                                                    'maxMileage',
                                                    range.max
                                                );
                                                return newParam;
                                            });
                                            setShowMileageRangeModal(false);
                                        }}
                                        minTitle={formatKm(0)}
                                        maxTitle={formatKm(120000)}
                                        defaultStep={10000}
                                        defaultMin={0}
                                        defaultMax={120000}
                                        minInputTitle={t('minDistance')}
                                        maxInputTitle={t('maxDistance')}
                                        icon="/icon/dashboard.svg"
                                    />
                                </ClickOutside>
                            </div>
                        </div>
                        <div className="car-filter-item">
                            <div>
                                <div
                                    className="car-filter-select-field"
                                    onClick={() =>
                                        setShowPriceRangeModal(
                                            !show_price_range_model
                                        )
                                    }
                                >
                                    {!!searchParams.get('maxPrice') ||
                                    priceRange ? (
                                        <span className="show-range">
                                            {
                                                process.env
                                                    .REACT_APP_DEFAULT_CURRENCY
                                            }
                                            {text_truncate(
                                                formatMoney(
                                                    searchParams.get(
                                                        'minPrice'
                                                    ) ||
                                                        topLevelDomain ===
                                                            COUNTRY_TOP_LEVEL_DOMAIN.ma
                                                        ? '5000'
                                                        : '100000'
                                                )?.replaceAll(/[aA-zZ]/g, ''),
                                                12,
                                                '...'
                                            )}{' '}
                                            -
                                            {text_truncate(
                                                formatMoney(
                                                    priceRange
                                                        ? decodeCompactNumber(
                                                              priceRange
                                                          )
                                                        : searchParams.get(
                                                              'maxPrice'
                                                          )
                                                )?.replaceAll(/[aA-zZ]/g, ''),
                                                12,
                                                '...'
                                            )}{' '}
                                            <span
                                                className="reset-icon"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSearchParams(
                                                        (prevSearchParams) => {
                                                            const newParam =
                                                                new URLSearchParams(
                                                                    prevSearchParams
                                                                );
                                                            newParam.delete(
                                                                'minPrice'
                                                            );
                                                            newParam.delete(
                                                                'maxPrice'
                                                            );
                                                            return newParam;
                                                        }
                                                    );
                                                }}
                                            >
                                                <X size={8} color="white" />
                                            </span>
                                        </span>
                                    ) : (
                                        <span className="placeholder-text">
                                            {t('price')
                                                .charAt(0)
                                                .toUpperCase() +
                                                t('price').slice(1)}
                                        </span>
                                    )}

                                    <CaretDown
                                        size={20}
                                        color="#b3b3b3"
                                        weight="bold"
                                    />
                                </div>
                            </div>
                            {/* Price Range Modal */}
                            <ClickOutside
                                hideModal={() => setShowPriceRangeModal(false)}
                                is_popup_visible={show_price_range_model}
                            >
                                <RangeFilterModal
                                    handleSave={(range) => {
                                        setSearchParams((prevSearchParam) => {
                                            const newParam =
                                                new URLSearchParams(
                                                    prevSearchParam
                                                );
                                            newParam.set('minPrice', range.min);
                                            newParam.set('maxPrice', range.max);
                                            return newParam;
                                        });
                                        if (priceRange) {
                                            navigate(
                                                `${t(
                                                    ROUTES.buyCarsUnder
                                                )}/${formatNumberCompact(
                                                    range.max
                                                )}`
                                            );
                                        }
                                        setShowPriceRangeModal(false);
                                    }}
                                    showAt="left"
                                    visible={show_price_range_model}
                                    setvisible={setShowPriceRangeModal}
                                    minTitle={formatMoney(
                                        topLevelDomain ===
                                            COUNTRY_TOP_LEVEL_DOMAIN.ma
                                            ? 5000
                                            : 100000
                                    )}
                                    maxTitle={formatMoney(45000000)}
                                    minValue={
                                        searchParams.get('minPrice') ||
                                        topLevelDomain ===
                                            COUNTRY_TOP_LEVEL_DOMAIN.ma
                                            ? '5000'
                                            : '100000'
                                    }
                                    maxValue={
                                        priceRange
                                            ? decodeCompactNumber(
                                                  priceRange
                                              )?.toString() || '0'
                                            : searchParams.get('maxPrice') ||
                                              '45000000'
                                    }
                                    defaultMin={
                                        topLevelDomain ===
                                        COUNTRY_TOP_LEVEL_DOMAIN.ma
                                            ? 5000
                                            : 100000
                                    }
                                    defaultMax={45000000}
                                    defaultStep={10000}
                                    minInputTitle={t('minPrice')}
                                    maxInputTitle={t('maxPrice')}
                                    icon="/images/priceIcon.svg"
                                />
                            </ClickOutside>
                        </div>
                        <button
                            type="button"
                            className="more-car-filter-btn"
                            onClick={() => setShowMoreFilter(!show_more_filter)}
                        >
                            <FadersHorizontal size={20} color="#464646" />
                            {useLgMediaQuery() ? t('filter') : t('moreFilters')}
                        </button>
                    </div>
                </div>
            </section>
            {show_more_filter ? (
                <div className="more-filter-container">
                    <div className="more-filter">
                        <div className="filter-card">
                            <div className="filter-scroll-section no-scrollbar">
                                <div className="more-filter-header">
                                    <span className="more-filter-header-title">
                                        {t('moreFilters')}
                                    </span>
                                    <button
                                        className="more-filter-close-btn"
                                        onClick={() => setShowMoreFilter(false)}
                                    >
                                        <X
                                            size={24}
                                            color="#454545"
                                            weight="bold"
                                        />
                                    </button>
                                </div>
                                <div className="expanded-filter">
                                    <ExpandFilterSection
                                        title={t('transmission')}
                                    >
                                        <div className="expanded-filter-transmission">
                                            <button
                                                className={`transmission-filter-btn ${
                                                    selectedOptions.transmission.includes(
                                                        transmissionTypes[0]
                                                            .value
                                                    )
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    handleSelectFieldChange(
                                                        'transmission',
                                                        transmissionTypes[0]
                                                            .value
                                                    )
                                                }
                                            >
                                                {transmissionTypes[0].label}
                                            </button>
                                            <button
                                                className={`transmission-filter-btn ${
                                                    selectedOptions.transmission.includes(
                                                        transmissionTypes[1]
                                                            .value
                                                    )
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    handleSelectFieldChange(
                                                        'transmission',
                                                        transmissionTypes[1]
                                                            .value
                                                    )
                                                }
                                            >
                                                {transmissionTypes[1].label}
                                            </button>
                                        </div>
                                    </ExpandFilterSection>
                                    <hr className="expand-filter-section-divider" />
                                    <ExpandFilterSection title={t('bodyType')}>
                                        <div className="expanded-filter-body-type">
                                            {bodyTypes.map((el, index) => (
                                                <div
                                                    key={`${el._id}-${index}`}
                                                    className={`body-type-card ${
                                                        selectedOptions.bodyType.includes(
                                                            el.slug || ''
                                                        )
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    onClick={() =>
                                                        el.slug &&
                                                        activeBodyType
                                                            ? handleSingleSelectChange(
                                                                  el.slug,
                                                                  'bodyType'
                                                              )
                                                            : el.slug &&
                                                              handleSelectFieldChange(
                                                                  'bodyType',
                                                                  el.slug
                                                              )
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            IMAGE_URL + el.image
                                                        }
                                                        alt={el.bodyType}
                                                    />
                                                    <div className="body-type-name text-capitalize">
                                                        {contentAccordToCurrentLang(
                                                            el
                                                        ).bodyType.toLowerCase() ??
                                                            'n/a'}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </ExpandFilterSection>
                                    <hr className="expand-filter-section-divider" />
                                    <ExpandFilterSection title={t('fuelType')}>
                                        <div className="expanded-filter-fuel-type">
                                            {fuelTypes.map((fuel, index) => (
                                                <button
                                                    key={`${fuel._id}-${index}`}
                                                    className={`fuel-selection-btn ${
                                                        selectedOptions.fuelType.includes(
                                                            fuel.value
                                                        )
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    onClick={() =>
                                                        handleSelectFieldChange(
                                                            'fuelType',
                                                            fuel.value
                                                        )
                                                    }
                                                >
                                                    {fuel.label}
                                                </button>
                                            ))}
                                        </div>
                                    </ExpandFilterSection>
                                    <hr className="expand-filter-section-divider" />
                                    <ExpandFilterSection title={t('color')}>
                                        <div className="expanded-filter-color-type">
                                            {carColors.map(
                                                (carColor, index) => (
                                                    <button
                                                        key={`${carColor.id}-${index}`}
                                                        className={`color-item ${
                                                            selectedOptions.color.includes(
                                                                carColor.slug
                                                            )
                                                                ? 'active'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            handleSelectFieldChange(
                                                                'color',
                                                                carColor.slug
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className="colored-circle"
                                                            style={{
                                                                backgroundColor:
                                                                    carColor.hex_color
                                                            }}
                                                        ></div>
                                                        <span className="color-name text-capitalize">
                                                            {
                                                                contentAccordToCurrentLang(
                                                                    carColor
                                                                ).color
                                                            }
                                                        </span>
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    </ExpandFilterSection>
                                    <hr className="expand-filter-section-divider" />
                                    <ExpandFilterSection title={t('modelYear')}>
                                        <Form.Item className="expanded-filter-model-year">
                                            <Slider
                                                className="car-model-slider"
                                                range
                                                min={
                                                    modelYears.length > 0
                                                        ? parseInt(
                                                              modelYears[0]
                                                          )
                                                        : 0
                                                }
                                                max={
                                                    modelYears.length > 0
                                                        ? parseInt(
                                                              modelYears[
                                                                  modelYears.length -
                                                                      1
                                                              ]
                                                          )
                                                        : 0
                                                }
                                                defaultValue={
                                                    modelYears.length > 0
                                                        ? [
                                                              parseInt(
                                                                  modelYears[0]
                                                              ),
                                                              parseInt(
                                                                  modelYears[
                                                                      modelYears.length -
                                                                          1
                                                                  ]
                                                              )
                                                          ]
                                                        : [0, 1]
                                                }
                                                step={1}
                                                value={
                                                    selectedOptions.minModelYear ||
                                                    selectedOptions.maxModelYear
                                                        ? [
                                                              parseInt(
                                                                  selectedOptions.minModelYear
                                                              ),
                                                              parseInt(
                                                                  selectedOptions.maxModelYear
                                                              )
                                                          ]
                                                        : [
                                                              parseInt(
                                                                  modelYears[0]
                                                              ),
                                                              parseInt(
                                                                  modelYears[
                                                                      modelYears.length -
                                                                          1
                                                                  ]
                                                              )
                                                          ]
                                                }
                                                onChange={(e) => {
                                                    setSelectedOptions(
                                                        (prev) => ({
                                                            ...prev,
                                                            minModelYear:
                                                                e[0] + '',
                                                            maxModelYear:
                                                                e[1] + ''
                                                        })
                                                    );
                                                }}
                                            />
                                            <div className="expanded-filter-input-fields">
                                                <Selects
                                                    options={modelYears.map(
                                                        (year) => ({
                                                            label: year,
                                                            value: year,
                                                            _id: year
                                                        })
                                                    )}
                                                    placeholder={t(
                                                        'selectYear'
                                                    )}
                                                    onSelect={(
                                                        new_value: string
                                                    ) =>
                                                        setSelectedOptions(
                                                            (prev) => ({
                                                                ...prev,
                                                                minModelYear:
                                                                    new_value
                                                            })
                                                        )
                                                    }
                                                    name="min-year"
                                                    value={
                                                        selectedOptions.minModelYear ||
                                                        parseInt(modelYears[0])
                                                    }
                                                    class="year-range select-ant-override"
                                                    iconSize={20}
                                                    model="single"
                                                />
                                                <hr className="year-range-divide" />

                                                <Selects
                                                    options={modelYears.map(
                                                        (year) => ({
                                                            label: year,
                                                            value: year,
                                                            _id: year
                                                        })
                                                    )}
                                                    placeholder={t(
                                                        'selectYear'
                                                    )}
                                                    onSelect={(
                                                        new_value: string
                                                    ) =>
                                                        setSelectedOptions(
                                                            (prev) => ({
                                                                ...prev,
                                                                maxModelYear:
                                                                    new_value
                                                            })
                                                        )
                                                    }
                                                    name="max-year"
                                                    value={
                                                        selectedOptions.maxModelYear ||
                                                        parseInt(
                                                            modelYears[
                                                                modelYears.length -
                                                                    1
                                                            ]
                                                        )
                                                    }
                                                    class="year-range select-ant-override"
                                                    iconSize={20}
                                                    model="multiple"
                                                />
                                            </div>
                                        </Form.Item>
                                    </ExpandFilterSection>
                                </div>
                            </div>
                            <div className="filter-action">
                                <button
                                    className="clear-all-btn text-uppercase"
                                    onClick={() => handleClearAll()}
                                >
                                    {t('clearAll')}
                                </button>
                                <button
                                    className="apply-filter-btn text-uppercase"
                                    onClick={() => handleApplyFilter()}
                                >
                                    {t('applyFilter')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default CarFilterSection;
