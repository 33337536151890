import { useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import { appendCurrency, SetMeta } from '../utils/Helper/jsHelpers';
import { useTranslation } from 'react-i18next';
import TrackerLayout from '../components/ui/TrackerLayout';
import { getCitizenDemonym } from '../utils/translation';
import { Money } from 'phosphor-react';
import { Input, Radio, Slider, Space } from 'antd';
import { sliderFormatter } from '../components/LoanCalculator';

interface ILoanData {
    tenureYear: number;
    downPayment: number;
    interestRate: number;
    loanAmount: number;
}

export const interestRageTooltipFormatter = (value: any) => {
    return `${value} %`;
};

const CarLoanCalculator = () => {
    const LoanTenures = [
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 }
    ];

    const [minDownPayment, setMinDownPayment] = useState<number>(10000);
    const downPaymentIncrBy = 1000;
    const minInterest = 5;
    const maxInterest = 18;
    const interestIncrBy = 0.5;

    const [loanData, setLoanData] = useState<ILoanData>({
        tenureYear: LoanTenures[0].value,
        downPayment: minDownPayment,
        interestRate: minInterest,
        loanAmount: 10000
    });
    const [estimated_monthly_payment, setEstimatedMonthlyPayment] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();

    const formatCalculatedPrice = () => {
        return estimated_monthly_payment === 0 ? (
            <span>{t('enterAllValues')}</span>
        ) : (
            <h3>{appendCurrency(estimated_monthly_payment)}</h3>
        );
    };

    const contentData = () => {
        return (
            <div className="tracker-content">
                <div
                    className="tracker-content-data"
                    dangerouslySetInnerHTML={{
                        __html: t('loanCalculatorContent', {
                            ns: 'content'
                        })
                    }}
                />
            </div>
        );
    };

    const handleDataChange = (name: string, value: string | number | null) => {
        setLoanData({ ...loanData, [name]: value });
    };

    useEffect(() => {
        const downPayment = Number(loanData.loanAmount) * 0.2;

        setLoanData({ ...loanData, downPayment: downPayment });
        setMinDownPayment(downPayment);
    }, [loanData.loanAmount]);

    const calculateMonthlyPayment = (
        downPayment: number,
        interestRate: number,
        loanTenure: number, // in years
        carPrice: number
    ): { totalAmount: number; monthlyPayment: number } => {
        // Validate inputs
        if (
            downPayment < 0 ||
            interestRate < 0 ||
            loanTenure <= 0 ||
            carPrice <= 0
        ) {
            return {
                totalAmount: 0,
                monthlyPayment: 0
            };
        }

        const principal = carPrice - downPayment;
        if (principal <= 0) {
            return {
                totalAmount: 0,
                monthlyPayment: 0
            };
        }

        const totalPayments = loanTenure * 12; // Convert years to months
        let emi: number;

        // Handle the case when interest rate is 0
        if (interestRate === 0) {
            emi = principal / totalPayments;
        } else {
            const monthlyRate = interestRate / 100 / 12; // Convert annual rate to monthly
            emi =
                (principal *
                    monthlyRate *
                    Math.pow(1 + monthlyRate, totalPayments)) /
                (Math.pow(1 + monthlyRate, totalPayments) - 1);
        }

        const totalAmount = emi * totalPayments; // Total repayment amount over the tenure

        return {
            totalAmount: Math.round(totalAmount), // Round to nearest whole number
            monthlyPayment: Math.round(emi) // Round to nearest whole number
        };
    };

    useEffect(() => {
        setEstimatedMonthlyPayment(
            calculateMonthlyPayment(
                loanData.downPayment,
                loanData.interestRate,
                loanData.tenureYear,
                loanData.loanAmount
            ).monthlyPayment
        );
    }, [loanData]);

    const converterNode = () => {
        return (
            <div className="calculator-ui-container">
                <div className="calculator-ui">
                    <div className="calc-form" style={{ width: '100%' }}>
                        <div className="calc-form-row">
                            <Space direction="vertical">
                                <label>
                                    {' '}
                                    <span className="slider-label-header">{`${t(
                                        'loanAmount'
                                    )} (In ${
                                        process.env.REACT_APP_DEFAULT_CURRENCY
                                    })`}</span>
                                </label>
                                <Input
                                    prefix={<Money weight="bold" size={20} />}
                                    value={loanData.loanAmount}
                                    onChange={(e) =>
                                        handleDataChange(
                                            'loanAmount',
                                            e.target.value
                                        )
                                    }
                                    type="number"
                                />
                            </Space>
                            <Space direction="vertical">
                                <label className="slider-label">
                                    <span className="slider-label-header">
                                        {t('interestRate')}
                                    </span>
                                    <span className="slider-label-value">{`${loanData.interestRate} %`}</span>
                                </label>
                                <Slider
                                    className="interest-slider loan-calculator-slider"
                                    min={minInterest}
                                    max={maxInterest}
                                    defaultValue={minInterest}
                                    value={loanData.interestRate}
                                    step={interestIncrBy}
                                    onChange={(e) =>
                                        handleDataChange('interestRate', e)
                                    }
                                    tooltip={{
                                        formatter: interestRageTooltipFormatter
                                    }}
                                />
                            </Space>
                        </div>
                        <div className="calc-form-row">
                            <Space direction="vertical">
                                <label className="slider-label">
                                    <span className="slider-label-header">{`${t(
                                        'downPayment'
                                    )} (In ${
                                        process.env.REACT_APP_DEFAULT_CURRENCY
                                    })`}</span>
                                    <span className="slider-label-value">
                                        {appendCurrency(loanData.downPayment)}
                                    </span>
                                </label>
                                <Slider
                                    className="down-payment-slider loan-calculator-slider"
                                    min={minDownPayment}
                                    max={loanData.loanAmount * 0.9}
                                    defaultValue={minDownPayment}
                                    value={loanData.downPayment}
                                    step={downPaymentIncrBy}
                                    onChange={(e) =>
                                        handleDataChange('downPayment', e)
                                    }
                                    tooltip={{
                                        formatter: sliderFormatter
                                    }}
                                />
                            </Space>
                        </div>
                        <div className="calc-form-row">
                            <Space direction="vertical">
                                <label className="slider-label">
                                    {' '}
                                    <span className="slider-label-header">{`${t(
                                        'loanTenure'
                                    )} ${t('years')}`}</span>
                                </label>
                                <Radio.Group
                                    onChange={(event) =>
                                        handleDataChange(
                                            'tenureYear',
                                            event.target.value
                                        )
                                    }
                                    value={loanData.tenureYear}
                                    buttonStyle="solid"
                                    className="car-loan-tenure-radio-group"
                                    defaultValue={LoanTenures[0].value}
                                >
                                    {LoanTenures.map((tenure) => (
                                        <Radio.Button
                                            key={tenure.value}
                                            value={tenure.value}
                                            className="tenure-button"
                                        >
                                            {tenure.label}
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                            </Space>
                        </div>
                    </div>
                    <div className="calc-result">
                        <h6>{t('yourEstimatedMonthlyPayment')}</h6>
                        {formatCalculatedPrice()}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <SetMeta
                title={t('carLoanCalculatorTitle', {
                    ns: 'meta'
                })}
                desc={t('carLoanCalculatorDescMeta', {
                    ns: 'meta'
                })}
                keywords={t('carLoanCalculatorKeywords', {
                    ns: 'meta'
                })}
                properties={[
                    {
                        key: 'og:title',
                        value: t('carLoanCalculatorTitle', {
                            ns: 'meta'
                        })
                    },
                    {
                        key: 'og:description',
                        value: t('carLoanCalculatorDescMeta', {
                            ns: 'meta'
                        })
                    },
                    {
                        key: 'og:keywords',
                        value: t('carLoanCalculatorKeywords', {
                            ns: 'meta'
                        })
                    }
                ]}
            />
            <Layout showWhiteNavbar>
                <TrackerLayout
                    effectiveDate={''}
                    tableData={[]}
                    tableHeading={''}
                    coverImage="/images/hero/loan-calculator.webp"
                    title={t('carLoanCalculator')}
                    description={t('carLoanCalculatorDesc', {
                        Country_name: getCitizenDemonym()
                    })}
                    content={contentData()}
                    converterNode={converterNode()}
                />
            </Layout>
        </>
    );
};
export default CarLoanCalculator;
