import { Navigation, SwiperOptions, Pagination, Swiper as SwiperType } from "swiper";
import { Swiper } from "swiper/react";
import { ReactNode, useEffect, useRef, useState } from "react";
//
import { NextArrow, PrevArrow } from "./icons/common.icons";
import { useTranslation } from "react-i18next";

interface ISliderProps {
    children: ReactNode,
    additionalSliderClasses?: String,
    swiperOptions: SwiperOptions,
    totalItem: number
}

const Slider = ({ children, swiperOptions, additionalSliderClasses = ""}: ISliderProps) => {

    const [isPrevDisabled, setIsPrevDisabled] = useState(false);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const sliderRef = useRef<SwiperType>();
    const {i18n} = useTranslation();

    const updateNavigationButtons = (swiper: SwiperType) => {
        setIsPrevDisabled(swiper.isBeginning);
        setIsNextDisabled(swiper.isEnd);
    }

    useEffect(() => {
        const swiper = sliderRef.current;
        if (swiper) {
            updateNavigationButtons(swiper);

            swiper.on('slideChange', () => updateNavigationButtons(swiper));
            swiper.on('resize', () => updateNavigationButtons(swiper));
        }

        return () => {
            if (swiper) {
                swiper.off('slideChange');
                swiper.off('resize');
            }
        };
    }, [i18n.language]);


    return (
        <Swiper
            className={`section-slider ${additionalSliderClasses}`}
            modules={[Navigation]}
            onBeforeInit={(swiper) => {
                sliderRef.current = swiper;
            }}
            speed={1000}
            {...swiperOptions}
        >
            {
                (
                    <button
                        type="button"
                        className="cardArrow cardArrow__left"
                        aria-label="slide-left-arrow"
                        onClick={() => sliderRef.current?.slidePrev()}
                        disabled={isPrevDisabled}
                    >
                        <PrevArrow />
                    </button>
                )
            }
            {children}
            {
                (
                    <button
                        onClick={() => sliderRef.current?.slideNext()}
                        type="button"
                        className="cardArrow cardArrow__right"
                        aria-label="slide-right-arrow"
                        disabled={isNextDisabled}
                    >
                        <NextArrow />
                    </button>
                )
            }
        </Swiper>


    )
}

export default Slider;