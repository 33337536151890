import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//
import { $FIXME } from "../constants";
import SectionContainer from "./SectionContainer";
import CarsSlider from "./CarsSlider";
import { CAR_LIST_TYPE_ENUM, ICarListType } from "../pages/Home";

interface ICarSection {
    sectionTitle: string,
    sectionSubtitle?: string,
    type?: ICarListType,
    carData?: Array<$FIXME>,
    maxPrice?: number,
    queryParams?: string,
    additionalSectionClass?: string
}

const CarSection = ({ carData, type, sectionTitle, sectionSubtitle, maxPrice, queryParams, additionalSectionClass = "" }: ICarSection) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function getPathnameByType() {
        switch (type) {
            case CAR_LIST_TYPE_ENUM.cheapestCars:
                return `?maxPrice=${maxPrice}`;
            case CAR_LIST_TYPE_ENUM.custom:
                return queryParams;
            default:
                return '';
        }
    }

    const handleViewAllCarsClick = () => {
        const searchParams = getPathnameByType();
        navigate({
            pathname: t('/buy-cars', { ns: 'routes' }),
            search: searchParams
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return carData && carData.length > 0 ? <SectionContainer
        sectionTitle={t(sectionTitle)}
        sectionSubTitle={sectionSubtitle}
        detailObj={{
            detailLinkText: t('viewAllCars'),
            clickHandler: handleViewAllCarsClick
        }}
        additionalClasses={additionalSectionClass}>

        <CarsSlider carData={carData} />
    </SectionContainer>
        : <></>

}

export default CarSection;