import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//
import useLanguageDir from '../utils/Hooks/useLanguageDir';
import { getTopLevelDomain } from '../utils/translation';
import { formatToK, getMaxPriceAccToCountry } from '../utils/Helper/jsHelpers';
import { NextChevron } from './icons/common.icons';
import { fetchCarsByBudget } from '../redux/actions/fetchNewCarListAction';
import { ICombineReducerType } from '../redux/reducers';
import CarsSlider from './CarsSlider';
import NoMatchingCarCard from './NoMatchingCarCard';
import { formatNumberCompact } from '../utils/Helper/PriceFormatter';
import { ROUTES } from '../constants';

type TabOptionType = { minPrice?: number; maxPrice?: number };
type TabOptionArrayType = Array<TabOptionType>;

const MatchBudgetSection = () => {
    const [active_tab_index, setActiveTabIndex] = useState(0);
    const dispatch = useDispatch();
    const { budgetedCars } = useSelector(
        (state: ICombineReducerType) => state.car
    );
    const { t } = useTranslation();
    const { language_dir } = useLanguageDir();
    const [price_range, setPriceRange] = useState<TabOptionArrayType>([]);
    const topLevelDomain = getTopLevelDomain();

    const navigate = useNavigate();

    const handleViewAllCarsClick = () => {
        const activeRange = price_range.find(
            (_, index) => index === active_tab_index
        );

        if (active_tab_index === 0) {
            navigate(
                `${t(ROUTES.buyCarsUnder, {
                    ns: 'routes'
                })}/${formatNumberCompact(activeRange?.maxPrice)}`
            );
        } else {
            navigate(t('/buy-cars', { ns: 'routes' }));
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const fetCarData = async (range: TabOptionType) => {
        const params = {
            page: 1,
            limit: 4,
            random: true
        };
        fetchCarsByBudget(dispatch, range.maxPrice, range.minPrice, params);
    };

    const handleTabClick = async (range: TabOptionType, index: number) => {
        if (active_tab_index === index) return;
        setActiveTabIndex(index);
        fetCarData(range);
    };

    useEffect(() => {
        const max_price = getMaxPriceAccToCountry(topLevelDomain);
        const min_price = max_price * 10;
        let new_price_range: TabOptionArrayType = [];
        new_price_range.push({
            maxPrice: max_price
        });
        const tab_price_difference = (min_price - max_price) / 4;
        let maxPrice = max_price;
        for (let i = 0; i < 4; i++) {
            new_price_range.push({
                minPrice: maxPrice,
                maxPrice: maxPrice + tab_price_difference
            });
            maxPrice = maxPrice + tab_price_difference;
        }
        new_price_range.push({
            minPrice: maxPrice
        });

        setPriceRange(new_price_range);
        fetCarData(new_price_range[0]);
    }, [topLevelDomain]);

    return (
        <section className="section-main-container">
            <div className="match-budget-container padding">
                <div className="match-budget-header">
                    <div className="match-budget-title">
                        <h2
                            className="section-container-title"
                            dir={language_dir}
                        >
                            {t('perfectMatchYourBudget')}
                        </h2>
                        <div className="match-budget-tabs no-scrollbar">
                            {price_range.map(
                                (range: TabOptionType, index: number) => {
                                    let childNode = <></>;
                                    if (range.minPrice && range.maxPrice)
                                        childNode = (
                                            <>
                                                {formatToK(range.minPrice)} -{' '}
                                                {formatToK(range.maxPrice)}
                                            </>
                                        );
                                    else if (range.minPrice)
                                        childNode = (
                                            <>
                                                Above:{' '}
                                                {formatToK(range.minPrice)}
                                            </>
                                        );
                                    else if (range.maxPrice)
                                        childNode = (
                                            <>
                                                Below:{' '}
                                                {formatToK(range.maxPrice)}
                                            </>
                                        );
                                    return (
                                        <button
                                            key={index}
                                            className={
                                                index === active_tab_index
                                                    ? 'active'
                                                    : ''
                                            }
                                            onClick={() =>
                                                handleTabClick(range, index)
                                            }
                                        >
                                            {childNode}
                                        </button>
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <button
                        style={{ background: 'transparent' }}
                        className="match-budget-detail-link text-uppercase"
                        onClick={handleViewAllCarsClick}
                    >
                        <span dir={language_dir}>{t('viewAllCars')}</span>
                        <NextChevron />
                    </button>
                </div>
                {budgetedCars.cars.length > 0 ? (
                    <CarsSlider carData={budgetedCars.cars} />
                ) : (
                    <NoMatchingCarCard />
                )}
            </div>
        </section>
    );
};

export default MatchBudgetSection;
