import { IFilteredLang } from './interface/generalInterface';

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;
export const IMAGE_URL = `${process.env.REACT_APP_BASE_URL}/`;
export const googleSignInLink = 'https://mail.google.com/mail/u/0/#inbox';

export const getCars = 'cars';
export const getCarsByUid = 'cars/by-uid';
export const footerContent = 'footer-content';
export const getWishList = 'wishlists';
export const getservicehistories = 'service-histories';
export const serviceHistoryByCarIds = 'service-histories/by-car';
export const getfaqs = 'faqs';
export const carCertification = 'car-certifications';
export const carBrands = 'car-brands';
export const carAvailableBrands = 'car-brands/by/car';
export const sellingCars = 'selling-cars';
export const modelByBrands = 'car-models/by-brand';
export const contactQuery = 'contact-queries';
export const contactInformation = 'contact-information';
export const pages = 'pages';
export const financePartner = 'financing-partners';
export const subscription = 'subscribers';
export const instagramUrl = 'contact-information/get-instagram-url';
export const filteredCarsFooter = 'car-models/filter';

export type $FIXME = any;

export const whatsappNumber = '212663311575';

// export const whatsappUrl = 'https://wa.me/212663311575';
export const whatsappUrl = 'https://wa.me/';
export const faceBooKLink = 'https://www.facebook.com/auto24africa';
export const instagramLink = 'https://www.instagram.com/auto24africa';
export const linkedInLink = 'https://www.linkedin.com/company/auto24africa';
export const tiktokLink =
    'https://www.tiktok.com/@auto24africa?_t=8V9nZUUpo1u&_r=1';
export const emailRegs =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegs = /^(?:\+?)(?:[0-9]{1,3}\ ?)([0-9]{9})$/;

export const numberRegx = /^[0-9]+$/;
export const carPageUrl = `${process.env.REACT_APP_FRONT_END_URL}/buy`;
export const blogPageUrl = `${process.env.REACT_APP_FRONT_END_URL}/blog`;
export const sessionExpireDate = 13;

export const CSWIPER = {
    startOfSlider: 0,
    slidesPerView: 4,
    slidesToSkip: 5,
    maxCardSliderWidth: 1280
};

// Add the possible country top level domain in websites.
// Need to add here if you want to add new country domain
export const COUNTRY_TOP_LEVEL_DOMAIN: { [key: string]: string } = {
    asterdio: 'asterdio.xyz',
    bj: 'bj',
    ci: 'ci',
    coke: 'co.ke',
    coza: 'co.za',
    ma: 'ma',
    rw: 'rw',
    sn: 'sn',
    tg: 'tg',
    tz: 'tz',
    ug: 'ug'
} as const;

// All the languages
// Need to add here if you want to add new language
export const ALL_LANGUAGES: { [key: string]: IFilteredLang } = {
    arabic: {
        label: 'Arabic',
        value: 'ar',
        code: 'MA',
        includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.ma]
    },
    english: {
        label: 'English',
        value: 'en',
        code: 'US',
        includedInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
            COUNTRY_TOP_LEVEL_DOMAIN.bj,
            COUNTRY_TOP_LEVEL_DOMAIN.ci,
            COUNTRY_TOP_LEVEL_DOMAIN.coke,
            COUNTRY_TOP_LEVEL_DOMAIN.coza,
            COUNTRY_TOP_LEVEL_DOMAIN.ma,
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.sn,
            COUNTRY_TOP_LEVEL_DOMAIN.tg,
            COUNTRY_TOP_LEVEL_DOMAIN.tz,
            COUNTRY_TOP_LEVEL_DOMAIN.ug
        ]
    },
    french: {
        label: 'French',
        value: 'fr',
        code: 'FR',
        includedInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
            COUNTRY_TOP_LEVEL_DOMAIN.bj,
            COUNTRY_TOP_LEVEL_DOMAIN.ci,
            COUNTRY_TOP_LEVEL_DOMAIN.ma,
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.sn,
            COUNTRY_TOP_LEVEL_DOMAIN.tg,
        ]
    },
    kinyarnwanda: {
        label: 'Kinyarnwanda',
        value: 'rw',
        code: 'RW',
        includedInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
            COUNTRY_TOP_LEVEL_DOMAIN.rw
        ]
    },
    wolof: {
        label: 'Wolof',
        value: 'sn',
        code: 'SN',
        includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.sn]
    },
    xhosa: {
        label: 'Xhosa',
        value: 'xh',
        code: 'ZA',
        includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.coza]
    },
    zulu: {
        label: 'Zulu',
        value: 'zu',
        code: 'ZA',
        includedInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.coza]
    }
};

// Filter language on the basis of top level domain name.
export const FILTERED_LANG = Object.values(ALL_LANGUAGES);

// Routes List
export const ROUTES = {
    home: '/home',
    slash: '/',
    buyCars: '/buy-cars',
    buy: '/buy',
    sellCar: '/sell-car',
    technicalDocs: '/vehicle-technical-documents',
    carFinance: '/car-finance',
    carInsurance: '/car-insurance',
    about: '/about',
    faqs: '/faqs',
    contactUs: '/contact-us',
    blog: '/blog',
    account: '/account',
    imInterested: '/interested',
    bodyType: '/body-type',
    category: '/category',

    privacyPolicy: '/privacy-policy',
    termsOfUse: '/terms-of-use',
    unsubscribeBlog: '/unsubscribe-blog',
    // forgotPassword : "/forgot-password"

    editProfile: '/edit-profile',
    sellYourCar: '/sell-your-car',
    financing: '/financing',
    favourites: '/favourites',
    resetPassword: '/reset-password',
    ourTeam: 'our-team',
    findCarPrice: '/pricing-tool',
    press: '/press',
    carFinanceForm: '/car-finance-form',
    buyCarsUnder: '/buy-cars-under',

    requestCarPage: '/request-car',
    fuelPriceTracker: '/fuel-price-tracker',
    electricityPriceTracker: '/electricity-price-tracker',
    currencyConverter: '/currency-converter',
    distanceConverter: '/distance-converter',
    taxCalculator: '/vehicle-tax-calculator',
    topCarSales: `/best-selling-cars-of-the-year`,
    emissionEstimator: '/co2-emission-estimator',
    drivingCostCalculator: '/driving-cost-calculator',
    insuranceEstimator: '/insurance-premium-estimator',
    carLoanCalculator: '/car-loan-calculator'
};

export const AFRICAN_COUNTRY_CODES = [
    'dz',
    'ao',
    'bj',
    'bw',
    'bf',
    'bi',
    'cv',
    'cm',
    'cf',
    'td',
    'km',
    'cg',
    'cd',
    'dj',
    'eg',
    'gq',
    'er',
    'sz',
    'et',
    'ga',
    'gm',
    'gh',
    'gn',
    'gw',
    'ci',
    'ke',
    'ls',
    'lr',
    'ly',
    'mg',
    'mw',
    'ml',
    'mr',
    'mu',
    'ma',
    'mz',
    'na',
    'ne',
    'ng',
    'rw',
    'st',
    'sn',
    'sc',
    'sl',
    'so',
    'za',
    'ss',
    'sd',
    'tg',
    'tn',
    'ug',
    'zm',
    'zw'
];

export const EUROPEAN_COUNTRY_CODES = [
    'al',
    'ad',
    'am',
    'at',
    'az',
    'by',
    'be',
    'ba',
    'bg',
    'hr',
    'cy',
    'cz',
    'dk',
    'ee',
    'fi',
    'fr',
    'ge',
    'de',
    'gr',
    'hu',
    'is',
    'ie',
    'it',
    'kz',
    'xk',
    'lv',
    'lt',
    'lu',
    'mk',
    'md',
    'mc',
    'me',
    'nl',
    'no',
    'pl',
    'pt',
    'ro',
    'ru',
    'rs',
    'sk',
    'si',
    'es',
    'se',
    'ch',
    'tr',
    'ua',
    'gb',
    'va'
];

export const PressArray = [
    {
        image: '/svgs/presse-techcabal.svg',
        link: 'https://techcabal.com/2022/09/16/stellantis-and-africar-partner-to-launch-auto24-a-used-car-marketplace/',
        alt: 'techcabal',
        includeInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
            COUNTRY_TOP_LEVEL_DOMAIN.ci,
            COUNTRY_TOP_LEVEL_DOMAIN.ma,
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.sn,
            COUNTRY_TOP_LEVEL_DOMAIN.tg,
            // Just to view data:
            COUNTRY_TOP_LEVEL_DOMAIN.bj,
            COUNTRY_TOP_LEVEL_DOMAIN.coke,
            COUNTRY_TOP_LEVEL_DOMAIN.tg,
            COUNTRY_TOP_LEVEL_DOMAIN.tz,
            COUNTRY_TOP_LEVEL_DOMAIN.ug
        ]
    },
    {
        image: '/svgs/press-logo-techinafrica.svg',
        alt: 'techinafrica',
        link: 'https://www.techinafrica.com/auto24-expands-its-pre-owned-car-marketplace-to-4-thriving-african-markets/',
        includeInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
            COUNTRY_TOP_LEVEL_DOMAIN.ci,
            COUNTRY_TOP_LEVEL_DOMAIN.coza,
            COUNTRY_TOP_LEVEL_DOMAIN.ma,
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.sn,
            COUNTRY_TOP_LEVEL_DOMAIN.tg
        ]
    },
    {
        image: '/svgs/presse-lebrief.svg',
        alt: 'lebrief',
        link: 'https://www.lebrief.ma/stellantis-investit-dans-africar-group-pour-creer-auto24/',
        includeInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
            COUNTRY_TOP_LEVEL_DOMAIN.ci,
            COUNTRY_TOP_LEVEL_DOMAIN.ma,
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.sn,
            COUNTRY_TOP_LEVEL_DOMAIN.tg
        ]
    },
    {
        image: '/svgs/presse-lejournaldelautomobile.svg',
        alt: 'lejournaldelautomobile',
        link: 'https://journalauto.com/constructeurs/stellantis-entre-au-capital-dafricar-pour-creer-auto24/',
        includeInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
            COUNTRY_TOP_LEVEL_DOMAIN.ci,
            COUNTRY_TOP_LEVEL_DOMAIN.ma,
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.sn,
            COUNTRY_TOP_LEVEL_DOMAIN.tg
        ]
    },
    {
        image: '/svgs/presse-largus.svg',
        alt: 'largus',
        link: 'https://www.largus.fr/pros/actualite-automobile/stellantis-se-lance-dans-la-vente-de-voitures-doccasion-en-afrique-11030393.html',
        includeInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
            COUNTRY_TOP_LEVEL_DOMAIN.ci,
            COUNTRY_TOP_LEVEL_DOMAIN.ma,
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.sn,
            COUNTRY_TOP_LEVEL_DOMAIN.tg
        ]
    },
    {
        image: '/images/logo-press-forbes.webp',
        alt: 'forbes',
        link: '',
        includeInDomain: [
            COUNTRY_TOP_LEVEL_DOMAIN.asterdio,
            COUNTRY_TOP_LEVEL_DOMAIN.ci,
            COUNTRY_TOP_LEVEL_DOMAIN.coza,
            COUNTRY_TOP_LEVEL_DOMAIN.ma,
            COUNTRY_TOP_LEVEL_DOMAIN.rw,
            COUNTRY_TOP_LEVEL_DOMAIN.sn,
            COUNTRY_TOP_LEVEL_DOMAIN.tg
        ]
    },
    {
        image: '/images/logo-topauto.webp',
        alt: 'topauto',
        link: 'https://topauto.co.za/news/87664/new-used-car-marketplace-launched-in-south-africa/',
        includeInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.coza]
    },

    {
        image: '/images/logo-technext.webp',
        alt: 'technext',
        link: 'https://technext24.com/2023/09/28/auto24-expands-used-cars-marketplace/',
        includeInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.coza]
    },
    {
        image: '/images/logo-iol.webp',
        alt: 'iol',
        link: 'https://www.iol.co.za/business-report/companies/stellantis-auto24-platform-to-take-on-sas-booming-online-used-car-market-d33cc828-227b-4668-8e26-4b231abd2d91',
        includeInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.coza]
    },

    {
        image: '/images/logo-itweb.webp',
        alt: 'itweb',
        link: 'https://itweb.africa/content/G98YdMLGzrL7X2PD',
        includeInDomain: [COUNTRY_TOP_LEVEL_DOMAIN.coza]
    }
];

export const CarDetailSpecificationsIds = {
    about: 'car-about',
    overview: 'car-overview',
    specification: 'car-specification',
    features: 'car-features',
    additional_photos: 'car-additional-photos',
    car_loan: 'car-loan'
};
