import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AuthFlow from '../components/AuthFlow/AuthFlow';
import { $FIXME, COUNTRY_TOP_LEVEL_DOMAIN } from '../constants';
import { getContactInfo } from '../redux/actions/contactActions';
import { ICombineReducerType } from '../redux/reducers';
import useOnScreen from '../utils/Hooks/ViewPortDetect';
import { getTopLevelDomain } from '../utils/translation';
import { getBrandModel } from '../redux/actions/carBrandsAction';

type IFooterProps = {
    openAuthModal?: $FIXME;
    props?: $FIXME;
    setIsFooterVisible: $FIXME;
};
interface BrandModelData {
    brands: any[]; // Replace `any` with the correct type if known
    models: any[]; // Replace `any` with the correct type if known
    bodyTypes: any[]; // Replace `any` with the correct type if known
}

interface BrandModelResponse {
    data: {
        data: BrandModelData;
    };
}

export const SocialIcons = () => {
    const { t } = useTranslation();
    const { social } = useSelector(
        (state: ICombineReducerType) => state.contact.contact
    );
    return (
        <>
            {!!social?.facebook && (
                <li>
                    <a
                        rel="noopener noreferrer"
                        href={social.facebook}
                        target="_blank"
                    >
                        {t('facebook')}
                    </a>
                </li>
            )}
            {!!social?.instagram && (
                <li>
                    <a
                        rel="noopener noreferrer"
                        href={social.instagram}
                        target="_blank"
                    >
                        {t('instagram')}
                    </a>
                </li>
            )}
            {!!social?.tiktok && (
                <li>
                    <a
                        rel="noopener noreferrer"
                        href={social.tiktok}
                        target="_blank"
                    >
                        {t('tiktok')}
                    </a>
                </li>
            )}
            {!!social?.twitter && (
                <li>
                    <a
                        rel="noopener noreferrer"
                        href={social.twitter}
                        target="_blank"
                    >
                        {t('twitter')}
                    </a>
                </li>
            )}
            {!!social?.youtube && (
                <li>
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={social.youtube}
                    >
                        {t('youtube')}
                    </a>
                </li>
            )}
            {!!social?.linkedIn && (
                <li>
                    <a
                        rel="noopener noreferrer"
                        href={social?.linkedIn}
                        target="_blank"
                    >
                        {t('linkedin')}
                    </a>
                </li>
            )}
        </>
    );
};
const Footer: React.FC<IFooterProps> = (props: $FIXME) => {
    const currentLocation = useLocation();

    const [showAuthModal, setShowAuthModal] = useState(false);
    const { address, copyright_details, footer_details } = useSelector(
        (state: ICombineReducerType) => state.contact.contact
    );
    const { isloggedIn } = useSelector(
        (state: ICombineReducerType) => state.auth
    );
    const [brandFooter, setBrandFooter] = useState<BrandModelData>({
        brands: [],
        models: [],
        bodyTypes: []
    });
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const footerRef = useRef(null);
    const isVisible = useOnScreen(footerRef);
    const dispatch = useDispatch();
    const locale = i18n.language;

    const topLevelDomain = getTopLevelDomain();

    useEffect(() => {
        if (!address) getContactInfo(dispatch);
        getBrandModel({ limit: 8 })
            .then((brandModel) => {
                const apidata = (brandModel as BrandModelResponse).data;
                setBrandFooter(apidata.data);
            })
            .catch((error) => {
                console.error('Error fetching brandModel:', error);
            });
    }, []);
    useEffect(() => {
        props.setIsFooterVisible(isVisible);
    }, [isVisible]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    const changeModal = () => {
        setShowAuthModal(!showAuthModal);
    };
    const copyToClipboard = (str: any) => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };
    const sendTo = (item: $FIXME) => {
        if (item.items === 'account' && !isloggedIn) {
            setShowAuthModal(!showAuthModal);
        } else {
            navigate({ pathname: t(item.path, { ns: 'routes' }) });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };
    function handleClick(brand: any) {
        navigate({
            pathname: t('/buy-cars', { ns: 'routes' }),
            search: `?brand=${brand.slug}`
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const Explore = [
        {
            name: t('buyCars'),
            url: t('/buy-cars', { ns: 'routes' })
        },
        {
            name: t('sellCars'),
            url: t('/sell-car', { ns: 'routes' })
        },
        {
            name: t('blogs'),
            url: t('/blog', { ns: 'routes' })
        },
        {
            name: t('finance'),
            url: t('/car-finance', { ns: 'routes' })
        },
        {
            name: t('insurance'),
            url: t('/car-insurance', { ns: 'routes' })
        },
        {
            name: t('account'),
            url: '#'
        },
        {
            name: t('press'),
            url: t('/press', { ns: 'routes' })
        }
    ];

    const AboutUs = [
        {
            name: t('contactUs'),
            url: t('/contact-us', { ns: 'routes' })
        },
        {
            name: t('aboutUs'),
            url: t('/about', { ns: 'routes' })
        },
        {
            name: t('FAQ'),
            url: t('/faqs', { ns: 'routes' })
        },
        {
            name: t('careers'),
            url: 'https://apply.workable.com/auto24/'
        },
        {
            name: t('termOfUse'),
            url: t('/terms-of-use', { ns: 'routes' })
        },
        {
            name: t('privacyPolicy'),
            url: t('/privacy-policy', { ns: 'routes' })
        }
    ];

    const getFooterText = useCallback(() => {
        if (footer_details) {
            let currentLang = i18n.language;
            const footerDetail = footer_details.find(
                (item) => item.lang === currentLang
            );
            return footerDetail?.footer;
        }
        return '';
    }, [footer_details, currentLocation?.pathname]);

    const getCopyrightText = useCallback(() => {
        if (copyright_details) {
            let currentLang = i18n.language;
            const footerDetail = copyright_details.find(
                (item) => item.lang === currentLang
            );
            if (footerDetail) return footerDetail ? footerDetail.copyright : '';
            return '';
        }
        return '';
    }, [copyright_details, currentLocation?.pathname]);

    const COUNTRIES_WITH_FLAGS = [
        {
            name: 'Benin',
            flagsrc: '/images/flags/benin.svg',
            flagalt: `Benin Flag`,
            link: 'https://auto24.bj/',
            omit: topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.asterdio //Only for dev server
        },
        {
            name: 'Côte d’Ivoire',
            flagsrc: '/images/flags/cote-divoire-flat.svg',
            flagalt: `Côte d'Ivoire Flag`,
            link: 'https://auto24.ci/',
            omit: false
        },
        {
            name: 'Kenya',
            flagsrc: '/images/flags/kenya.svg',
            flagalt: `Kenya Flag`,
            link: 'https://auto24.co.ke/',
            omit: topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.asterdio //Only for dev server
        },
        {
            name: 'Morocco',
            flagsrc: '/images/flags/morocco-flat.svg',
            flagalt: 'Morocco Flag',
            link: 'https://auto24.ma/',
            omit: false
        },
        {
            name: 'Rwanda',
            flagsrc: '/images/flags/rwanda-flat.svg',
            flagalt: 'Rwanda Flag',
            link: 'https://auto24.rw/',
            omit: false
        },
        {
            name: 'Senegal',
            flagsrc: '/images/flags/senegal-flat.svg',
            flagalt: 'Senegal Flag',
            link: 'https://auto24.sn/',
            omit: false
        },
        {
            name: 'South Africa',
            flagsrc: '/images/flags/south-africa-flat.svg',
            flagalt: 'South Africa Flag',
            link: 'https://auto24.co.za/',
            omit: false
        },
        {
            name: 'Tanzania',
            flagsrc: '/images/flags/tanzania.svg',
            flagalt: 'Tanzania Flag',
            link: 'https://auto24.tz/',
            omit: topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.asterdio //Only for dev server
        },
        {
            name: 'Togo',
            flagsrc: '/images/flags/togo.svg',
            flagalt: 'Togo Flag',
            link: 'https://auto24.tg/',
            omit: topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.asterdio //Only for dev server
        },
        {
            name: 'Uganda',
            flagsrc: '/images/flags/uganda.svg',
            flagalt: 'Uganda Flag',
            link: 'https://auto24.ug/',
            omit: topLevelDomain !== COUNTRY_TOP_LEVEL_DOMAIN.asterdio //Only for dev server
        }
    ];
    const FILTERED_COUNTRY_FLAGS = COUNTRIES_WITH_FLAGS.filter(
        (item: any) => !item.omit
    );
    const totalLength = FILTERED_COUNTRY_FLAGS.length;

    return (
        <>
            <div className="footer-container" ref={footerRef}>
                <div className="footer padding">
                    <div className="footer-content">
                        <div className="footer-section">
                            <div className="title">
                                <span className="text-uppercase">
                                    {locale === 'fr' ? (
                                        <>
                                            {t('makes')} {t('popular')}
                                        </>
                                    ) : (
                                        <>
                                            {t('popular')} {t('makes')}
                                        </>
                                    )}
                                </span>
                                <div className="border-2px"></div>
                            </div>

                            <div className="content">
                                <ul>
                                    {brandFooter?.brands.map((brand, index) => (
                                        <li key={index}>
                                            <Link
                                                className="text-capitalize"
                                                to={`${t('/buy-cars', {
                                                    ns: 'routes'
                                                })}/${brand.slug}`}
                                            >
                                                {locale === 'fr' ? (
                                                    <>
                                                        {brand.name.toLowerCase()}{' '}
                                                        {t('used')}
                                                    </>
                                                ) : (
                                                    <>
                                                        {' '}
                                                        {t('used')}{' '}
                                                        {brand.name.toLowerCase()}
                                                    </>
                                                )}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content">
                        <div className="footer-section">
                            <div className="title">
                                <span className="text-uppercase">
                                    {locale === 'fr' ? (
                                        <>
                                            {t('carTypes')} {t('popular')}
                                        </>
                                    ) : (
                                        <>
                                            {t('popular')} {t('carTypes')}
                                        </>
                                    )}
                                </span>
                                <div className="border-2px"></div>
                            </div>

                            <div className="content">
                                <ul>
                                    {brandFooter?.bodyTypes.map(
                                        (bodyType, index) => (
                                            <li key={index}>
                                                <Link
                                                    className="text-capitalize"
                                                    to={`${t('/body-type', {
                                                        ns: 'routes'
                                                    })}/${bodyType.slug}`}
                                                >
                                                    {locale === 'fr' ? (
                                                        <>
                                                            {bodyType.name.toLowerCase()}{' '}
                                                            {t('used')}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {t('used')}{' '}
                                                            {bodyType.name.toLowerCase()}
                                                        </>
                                                    )}
                                                </Link>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content">
                        <div className="footer-section">
                            <div className="title">
                                <span className="text-uppercase">
                                    {' '}
                                    {t('explore')}
                                </span>
                                <div className="border-2px"></div>
                            </div>

                            <div className="content">
                                <ul>
                                    {Explore.map((explore, index) => (
                                        <li key={index}>
                                            {' '}
                                            <Link
                                                to={explore.url}
                                                onClick={
                                                    explore.name === 'Account'
                                                        ? changeModal
                                                        : undefined
                                                }
                                            >
                                                {explore.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content">
                        <div className="footer-section">
                            <div className="title">
                                <span className="text-uppercase">
                                    {' '}
                                    {t('aboutUs')}
                                </span>
                                <div className="border-2px"></div>
                            </div>

                            <div className="content">
                                <ul>
                                    {AboutUs.map(
                                        (link, index) =>
                                            link.url && (
                                                <li key={index}>
                                                    <Link
                                                        to={link.url}
                                                        target={
                                                            link.name ===
                                                            'Careers'
                                                                ? '_blank'
                                                                : undefined
                                                        }
                                                    >
                                                        {link.name}
                                                    </Link>
                                                </li>
                                            )
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content">
                        <div className="footer-section">
                            <div className="title">
                                <span className="text-uppercase">
                                    {' '}
                                    {t('followUS')}{' '}
                                </span>
                                <div className="border-2px"></div>
                            </div>

                            <div className="content">
                                <ul>
                                    <SocialIcons />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="footer-copyright-text">
                        <p>{getFooterText()}</p>
                        <div
                            className="padding"
                            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                            dangerouslySetInnerHTML={{
                                __html: getCopyrightText()
                            }}
                        ></div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '0.5rem',
                            gap: '12px',
                            flexWrap: 'wrap'
                        }}
                    >
                        <p>{t('buySellUsedCar')}</p>
                        {FILTERED_COUNTRY_FLAGS.map((item, index: number) => (
                            <div key={`${item?.name}-${index}`}>
                                <a
                                    rel="noopener noreferrer"
                                    href={item.link}
                                    target="_blank"
                                    className="country-link"
                                >
                                    <img
                                        className="flag-img"
                                        src={item.flagsrc}
                                        alt={item.flagalt}
                                        loading="lazy"
                                    />{' '}
                                    <span>{item.name}</span>
                                </a>
                                {index < totalLength - 1 && (
                                    <span className="flag-seperator"></span>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {showAuthModal && (
                <AuthFlow
                    isVisible={showAuthModal}
                    handleCancel={changeModal}
                />
            )}
        </>
    );
};

export default Footer;
