/**
 * Convert date string to MMM D, YYYY
 * @param dateString 
 * @returns 
 */
export function formatDateString(dateString: string) {
    const date = new Date(dateString);

    if(isNaN(date.getTime())) return '';
    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    }).format(date);

    // Append the type and return the formatted string
    return formattedDate;
}
