const commonKinyarwanda = {
    and: 'and',
    or: 'or',
    buy: 'Gura',
    sell: 'Gurisha',
    send: 'Ohereza',
    finance: 'Ubukungu',
    about: 'About',
    blogs: 'Ibikurikirane',
    blog: 'Urubuga',
    tools: 'Ibikoresho',
    callUsAt: 'Duhamagare kuri',
    signIn: 'Injiramo',
    signUp: 'Iyandikishe',
    insure: 'Shinganisha',
    cancel: 'Hagarika',
    search: 'Shakisha',
    enteremailAddress: 'Shyiramo aderesi imeyili',
    enterPassword: 'Shyiramo ijambobanga',
    enterFullName: 'Injiza izina ryawe ryuzuye',
    enterEmailToGetResetLinkInfo:
        'Shyiramo imeyili ifatanye na konti yawe nyuma turakohereza imeyili irimo amabwiriza yo gusiba ijambobanga ryawe',
    enterOldPassword: 'Shyiramo ijambobanga rya kera',
    enterPrice: 'Injira Igiciro cyawe',
    callBackSubtitle:
        "Shyiramo izina ryawe n'imero yawe ya telefoni tuzakubazaho haruguru.",
    pleaseEnterFullName: 'Shyiramo izina ryawe ryose.',
    pleaseprovideusyourdetails: 'Tubwire byinshi birambuye',
    pleaseprovideusyourcardetails: 'Duhe byinshi birambuye ku modoka yawe',
    desiredPleaseprovideusyourcardetails:
        'Tubwire byinshi ku modoka ukeneye, hitamo nibura imwe.',
    enterCarDetails: 'Enter Car Details',
    pleaseInputYourName: 'Tanga izina ryawe, kubundi!',
    pleaseInputYourEmail: `Tanga iyandikishe y'ibajanye na email yawe!`,
    pleaseInputValidEmail: 'Shyiramo aderesi imeyili ikora',
    pleaseInputYourPassword: 'Tanga ikimenyetso cyawe!',
    pleaseInputYourPhoneNumber: 'Tanga numero yawe ya telefoni!',
    rememberMe: 'Kunyibuka',
    forgotPassword: `Shyiramo Ikimenyetso cy'Ubuzima Wawe!`,
    carsAvailable: 'Imodoka ziboneka ubu',
    termOfUse: `Ibicuruzwa by'igikoresho`,
    privacyPolicy: `Ibikorerwa by'icyubahiro`,
    alreadyhaveanAccount: 'Already have an account?',
    howItWorks: 'Uburyo gikora',
    howtoBuy: 'Uburyo bwo kugura',
    howtoSell: 'Uburyo bwo kugurisha',
    exploreBlogs: 'Sura imbuga',
    car: 'Car',
    Certified: 'Byemejwe',
    Inspected: 'Byasuzumwe',
    ev: 'Electric',
    hybrid: 'Hybrid',
    phev: 'PHEV',
    petrol: 'Lisansi',
    diesel: 'Diesel',
    carburant: 'Carburant',
    other: 'Other',
    electric: 'Electric',
    automatic: 'Automatic',
    phonenumberisnotvalid: 'Phone number is not valid',
    internalError:
        'Ikosa ryabaye mo imbere. Ihangane wongere cyangwa utuvugishe.',
    knowaboutyourcarbetterandgetevaluation:
        'Know about your car better and get valuation for a better deal.',
    cardetailssendSuccessfully: 'Car details sent sucessfully',
    thanyouforprovidingusdetail:
        'Thank you for provding us the details. We will give you a call back soon.',
    selectbrand: 'Hitamo ikirango',
    selectcountry: 'Select country',
    enterMileage: 'Enter Mileage',
    minMileage: 'Minimum Mileage',
    maxMileage: 'Maximum Mileage',
    selectmodel: 'Select a model',
    selectYear: 'Hitamo umwaka',
    selectorenterbrand: 'Hitamo cyangwa wandike ikirango',
    selectorentermodel: 'Hitamo cyangwa wandike icyitegererezo',
    selectorenterbodyType: 'Hitamo cyangwa wandike ubwoko bwumubiri',
    brand: 'Ikirango',
    model: 'Icyitegererezo',
    modelYear: 'Umwaka yakozwemo',
    selectFuelType: "Hitamo ubwoko bw'igitoro",
    selectACondition: 'Hitamo imimerere',
    phoneNumber: 'Nimero ya telefoni',
    loanTitle: "Tuguha igabanyarizwa rinini cyane, bona inyungu y'10%",
    loanHappyCusSubtitle:
        "Twita ku byishimo by'abakiliya bacu kandi iyo bishimye bidutera imbaraga zo gukomeza gukora",
    loanButton: 'Bara neza inguzanyo yange',
    calculateButton: 'Calculate',
    blogTitle: "Banza urebe mbere y'uko ugura imodoka",
    blogSubtitle:
        "Ibi nibyo bintu ugomba kumenya mbere y'uko ugura imodoka iyo ariyo yose",
    viewAllCars: 'View All Cars',
    viewAllResultsFor: 'Reba ibisubizo byose bya',
    availableNowOn: 'Biraboneka noneho kuri',
    account: 'Konti',
    myAccount: 'My Account',
    ourBrands: "Ubwoko bw'imodoka zacu",
    contactUs: 'Tugusabye',
    contactInformation: "Amakuru ajyanye n'uwo guhamagara",
    aboutUs: 'Tukuri',
    addressLine: 'Address Line',
    contact: 'Contact',
    whyChooseAuto: 'Why Choose AUTO24?',
    allcarshavebeenthoroughlyinspectedandreconditionedHowweguaranteecarquality:
        'All cars have been thoroughly inspected and reconditioned. How we guarantee car quality',
    imInterested: "I'm interested",
    buyOnline: 'Buy Online',
    downPayment: 'Iyishyura mu byiciro',
    hotDeal: 'Hot deal',
    lowPrice: 'Low price',
    likeNew: 'Like new',
    moneybackSubtitle: "You don't like the car? We refund you",
    warrentyTitle: "Garanti y'amezi 6",
    warrentySubtitle:
        'Extendable to 12 months on engine, gearbox and powertrain',
    inspectionPointsSubtitle:
        'Inspected by our team of auto experts on all angles',
    roadSideAssistanceTitle: '1 year assistance',
    roadSideAssistanceSubtitle:
        'Roadside assistance included on all our vehicles, for one year',
    deliveryTitle: 'Home delivery',
    deliverySubtitle: 'We can deliver your car anywhere in Rwanda',
    xDeliverySubtitle: 'We can deliver your car anywhere in {{country}}',
    Summary: 'Summary',
    engine: 'Engine',
    enginePower: 'Engine',
    fuelType: 'Fuel Type',
    buildYear: 'Kubaka Umwaka',
    bodyType: 'Imiterere',
    numberofkeys: 'Number of keys',
    exteriorColor: 'Exterior Color',
    interiorColor: 'Interior Color',
    driveTrain: 'Imiyoboro',
    engineSize: 'Engine size',
    fiscalPower: 'Fiscal power',
    specifications: 'Specifications',
    specification: 'Ibisobanuro',
    lengthWidthHeight: 'Length,Width & Height',
    weight: 'weight',
    emissionCO2: 'Emission CO2',
    fuelConsumption: 'Fuel Consumption',
    enginepower: 'Engine Power',
    fuelTankCapacity: 'Fuel Tank Capacity',
    'performance&Economy': "Uburyo bw'imikorere & ubukungu",
    features: 'Features',
    'weight&Dimensions': 'Weight & Dimensions',
    showMoreFeatures: 'Show More Features',
    showLess: 'Show Less',
    serviceHistory: 'Service History',
    whyAUTO24: 'Why AUTO24',
    serviceCenter: 'Service Center',
    Kilometers: 'Km',
    lookingForaCarQtellUs: 'Urashaka imodoka? Tubwire!',
    sellPageTitle: 'Gurisha imodoka yawe mu ntaambwe ebyiri gusa',
    sellPageSubtitle:
        'Ushobora kuduha byinshi birambuye ku modoka natwe tukaguhamagara ukatubwira ibirushijeho,',
    findCarPricePageTitle: 'Find The Price of Your Car',
    findCarPricePageSubtitle:
        'You can provide details about your car and we will call you back for futher deals.',
    whyFinanceYourCarwithAuto24: 'Kuki wagura imodoka hamwe na AUTO24',
    financeSubtitle:
        'Tuguha igabanyirizwa ryiza rijyanye no gukopa harimo inyungu nkeya no kwishyura mu byiciro.',
    competitiveRates: 'Ibiciro bihebuje',
    competitiveDesc: 'APR from 5% (indicative)',
    competitiveDescMA: 'Birashoboka inguzanyo kubuntu mubihe bisabwa',
    flexiableDuration: 'Ibihe byiza byoroshye',
    flexiableDes:
        'Ishyura imodoka yawe mu byiciro kuva ku mezi 12 kugeza kuri 72',
    Percent100OnlineApproach: 'Kwegera neza 100%',
    Percent100OnlineApproachDesc: 'Sign all you documents at home',
    loanCalculatorTitle: "Mubazi ibara inguzanyo y'imodoka",
    loadCalculatorSubtitle:
        "Ikigero cy'inyungu ku kwezi gishobora guhinduka bitewe no gukorana n'ibigo by'imari bitandukanye",
    interestRate: "Ikigero cy'inyungu",
    years: 'years',
    ourFinancingPartners: "Umufatanyabikorwa wacu mu by'imari",
    ourInsurePartners: "Umufatanyabikorwa wacu mu by'ubwishingizi",
    ourPartners: 'Our Partners',
    copyrightfooter: 'Copyright Â© Africar Group Pty Ltd - All rights reserved',
    insureSubtitle: "Ryoherwa n'ubwishingizi bwacu hamwe n'ibiciro byiza",
    aboutFinanceSub: "Ngaba bamwe mu bafatanyabikorwa bacu mu by'imari",
    selectMileageRange: 'Hitamo ibirometero imaze kugenda',
    selectTransmissionType: 'Hitamo ubwoko bwo kuyitwara',
    backToHome: 'Gusubira inyuma',
    getACallBack: 'Hamagarwa',
    personalDetails: 'Byinshi byisumbuyeho ku muntu',
    carDetails: 'Byinshi birambuye ku modoka',
    ourBrandsSubtitle:
        "Dufatanyije n'ikirango cy'imodoka zikomeye byatsindiye ibihembo kubera imikorere myiza",
    ourTeam: 'Itorero ryacu',
    findCarPrice: `Ikinyamakuru cy'ibyerekeye`,
    teamMembers: `Abacuruzi b'itorero`,
    ourTeamSubtitle:
        "Tumenye birushijeho ugendeye ku bitekerezo b'itsinda ryacu.",
    latestPosts: 'Ibyashyizweho vuba',
    lastestPostSubtitle:
        'Ngibi ibyashyizweho vuba bituruka ku bantu batandukanye',
    featuredPosts: 'Ibyashyizweho vuba',
    featuredPostSubtitle:
        'Ngibi ibyashyizweho vuba bituruka ku bantu batandukanye',
    newsLetterTitle: 'Ntukabure inkuru',
    newsLetterSubtitle:
        'Iyandikishe ku rubuga rwa AUTO24 kugirango uhore umenya amakuru kuri buri modoka.',
    buySellTitle: 'Uriteguye?',
    buySellSubtitle:
        "Whether it's selling your used car or buying your next ride, we can do it all.",
    sellYourCar: 'Gurisha imodoka yawe',
    buyaCar: 'Gura imodoka',
    footerInfo:
        'AUTO24.ci ni ishami rya Africar Group Pty Ltd, yanditswe muri Kote Divuwari ku itegeko IDU CI-2022-0027564 O',
    FAQ: 'Ibibazo bikunze kwibazwa',
    buyCars: 'Gura imodoka',
    sellCars: 'Gurisha imodoka',
    categories: 'Ibyiciro',
    topStories: 'Amakuru abanza',
    donthaveanaccount: "Don't have an account",
    resetPassword: 'Siba ijambobanga',
    editProfile: 'Hindura porofile',
    createNewPassword: 'Rema ijambobanga rishya',
    passwordresetsuccessfully: 'Password reset successfully',
    passwordisRequired: 'Password is required!',
    minimum8chars: 'Minimum 8 characters are required',
    usersuccessfullyregistered: 'User successfully registered !',
    shortBio: "Ibijyanye n'umuntu bigufi",
    Save: 'Bika',
    Share: 'Sangira',
    delete: 'Siba',
    saveChanges: 'Save Changes?',
    youcanstillchangetheseinformation: "Ushobora n'ubundi guhindura aya makuru",
    changessavedsuccessfully: 'Impinduka zawe zabitswe neza',
    yourchangessavedsuccessfully: 'Impinduka zawe zabitswe neza',
    deleteyourAccount: 'Urasiba konte yawe?',
    deleteAccountsubtitile:
        'Once you delete your account your all data will be lost and you cant  get access to your account.',
    accountdeletedsuccessfully: 'Konti yasibwe neza',
    youcanstillexpolrecarwithouthavinganaccount:
        "Ushobora n'ubundi kubona imodoka n'iyo waba udafite konti.",
    logout: 'Gusohokamo',
    sortBy: 'Sort By:',
    filterBy: 'Muyunguruzi By:',
    lowestPriceFirst: 'Habanje igiciro gito',
    highestMileageFirst: 'Highest Mileage First',
    lowestMileageFirst: 'Lowest Mileage First',
    price: 'price',
    mileage: 'Mileage',
    selectBodyType: 'Hitamo imiterere',
    filter: 'Filters',
    moreFilters: 'Ibindi',
    color: 'Ibara',
    transmission: 'Uburyo bwo kuyitwara',
    highestPriceFirst: 'Highest Price First',
    provideUsYourDetail: 'Provide Us Your Detail',
    interestcartitle: 'Urifuza kugura imodoka?',
    interestcarsubtitle:
        'Hitamo kimwe, ibindi nabyo birakomeza kuboneka nugura.',
    bookThisCar: 'Toranya iyi modoka.',
    bookThisCarSubtitle:
        'Proceed to purchase this car and book it right now. Finish your purchase in 7 days. 10% refundable section',
    scheduleAVisit: 'Panga gusura',
    scheduleAVisitSubtitle:
        'Sura ububiko bwacu nuko ubone uko ureba imodoka wifuza. Izaba ikiboneka no ku bandi baguzi.',
    popularblog: 'Imbuga rusange',
    popularblogSub: 'Here are Popular blogs from different users',
    category: 'Ibyiciro',
    carOverview: 'Car Overview',
    Detailssentsuccessfully: 'Details sent successfully',
    DetailChangeSubtitle:
        'Thank you for providing us your details. We will give you a call back soon.',
    continue: 'Komeza',
    carnotfoundSubtitle:
        "Nta modoka yabonetse ijyanye n'ibyahiswemo. Dufashe kumenya ibyisumbuyeho kuri wowe nuko turagufasha kubona imodoka.",
    logoutModelTitle: 'Uremeza neza ko ushaka gusohoka?',
    french: 'French',
    QuickLinks: 'Imiyoboro yihuse',
    similarResults: 'Similar Results ',
    copyrightInfo:
        "AUTO24.CI is a subsidiary of Africar Group Pty Ltd, registered under the unique identification number CI-2022-0027564 O in the Republic of Cote d'Ivoire",
    recentlyAdded: 'Ibyashyizweho vuba',
    recentlyAddedSubtitle: 'Aha waharebera imodoka zitandukanye zongeweho vuba',
    newCarList: 'Ibicuruzwa bya buri gihe',
    newCarListSubtitle: `Hano waba wifuza kubona ibicuruzwa by'ibindi bya gihe `,
    electricCarList: `Ibicuruzwa by'izuba`,
    electricCarListSubtitle: `Hano waba wifuza kubona ibicuruzwa by'izuba by'ibindi`,
    importedCarList: `Ibicuruzwa byakozwe`,
    importedCarListSubtitle: `Hano waba wifuza kubona ibicuruzwa byakozwe by'ibindi`,
    cheapestCarList: `Ibicuruzwa by'ibibera mu gihe cya {{X}}`,
    cheapestCarListSubtitle: `Hano waba wifuza kubona ibicuruzwa byiza by'ibibera mu gihe cya {{X}}`,
    hereyoucanseedifferentsimilarcars:
        'Here you can see different similar cars',
    carNotFound: 'Imodoka ntiyabonetse',
    whatsappUrlText: 'Hello! I am interested in this car',
    latestService: 'Latest Service',
    resetSuccessMsg:
        'Now you can sign in with your new password or you can still explore cars',
    bothPasswordMustMatch: 'Both passwords must match',
    mustBeAtleast8Characters: 'Must be at least 8 characters',
    sendResetLink: 'Ohereza igisibisho',
    returnTo: 'Return to',
    resetPasswordModalTitle: 'Are you sure you want reset your password?',
    yourPasswordChangedSuccessfully:
        'Ijambobanga ryawe ryamaze guhindurwa neza',
    confirmPassword: 'Confirm Password',
    shortDescription: 'Ibisobanuro bigufi',
    carImages: "Amashusho y'imodoka",
    uploadImage: 'Upload Image',
    browse: 'Browse',
    listAnother: 'Shyiraho indi',
    listCarRequestSentSucessfully: 'List Car Request Sent Successfully',
    basicDetails: "Amakuru y'ibanze yisumbuyeho",
    manual: 'Manual',
    postYouHaveLiked: 'Amaposite wakunze',
    enterNewPassword: 'Ijambobanga rishya',
    confirmNewPassword: 'Emeza ijambobanga rishya',
    passwordChangeSuccessfully: 'Ijambobanga ryahinduwe neza',
    verificationTitle:
        'We have sent you a verification link to you email address',
    viewLink: 'Reba umuyoboro',
    resendingLink: 'Undi muyoboro uri koherezwa',
    tokenExpired: 'Your token is expired please create new one',
    tokenExpiredTitle: 'Account Verification Link Expired',
    provideUsYourDetails: 'Provide us Your Detail',
    showCars: 'Erekana imodoka',
    accountVerifiedSuccessfully: 'Konti yasuzumwe neza',
    engineSound: 'Engine Sound',
    showAllImage: 'Erekana amafoto yose',
    ourBenefits: 'Inyungu zacu',
    inspectionPoints:
        "Amanota 100 y'isuzuma Imodoka zacu zose zarasuzumwe uko bikwiriye",
    warranties: '6 months warranty (engine,gearbox and axle)',
    maintenances: '1 year maintenance package with AUTO24.rw',
    assistance:
        "Ubufasha bujyanye no gukurura imodoka igihe yagize ikibazo mu gihe cy'amezi 12",
    multiCurrency: 'Multi-currency payment RWF, €, $, £',
    moneyBack: "Ibijyanye no gusubizwa amafaranga mu gihe cy'iminsi 5",
    financing: 'Financing ',
    dragAndDrop: 'Drag & drop image or',
    imageFormat: 'Supported formats: JPEG, PNG',
    sellNow: 'Gurisha nonaha',
    confirm: 'Emeza',
    didntFindQuestion: "Didn't find the question you're looking for?",
    yourMessage: 'Ubutumwa bwawe',
    sendMessage: 'Send Message',
    loanTenure: "Ingwate y'inguzanyo",
    selectLoanTenure: "hitamo igihe cy'inguzanyo",
    interestInput: 'Enter amount between ({{X}} and {{Y}})',
    months: 'Amezi',
    from: 'From',
    upto: 'Upto',
    rateOfInterest: "Ikigero cy'inyungu",
    tenure: 'Ingwate',
    monthlyEmi: 'Buri kwezi',
    loadMore: 'Shyiraho ibindi',
    contactSubtitle: 'Hari ikibazo ufite? Wemerewe kutubaza.',
    inspector: 'Umugenzuzi',
    inspectorSubtitle: 'Saba kubona umugenzuzi',
    insurance: 'Ubwishingizi',
    insuranceSubtitle: 'Saba kubona ubwishingizi bwiza',
    financeSubtitles: 'Saba ubukungu bwiza',
    diagnostic: 'Isuzuma',
    minPrice: 'Igiciro gito ntarengwa',
    maxPrice: 'Igiciro ntarengwa',
    minDistance: 'Intera nto ntarengwa',
    maxDistance: 'Max Distance',
    min: 'Igiciro',
    max: 'Igiciro',
    diagonosticSubtitle: 'Saba kubona isuzuma',
    VINVerify: 'Kureba VIN',
    VINVerifySubtitle: 'Saba kureberwa VIN byihuse',
    learnMore: 'Menya byinshi',
    speedRegular: 'Speed Regulator',
    airCondition: 'Air Condition',
    sequential: 'Sequential',
    CDPlayer: 'CD Player',
    reversingCamera: 'Reversing Camera',
    reversingRadar: 'Reversing Radar',
    GPSNavigation: 'GPS Navigation',
    parkAssist: 'Park Assist',
    startAndStop: 'Start and Stop',
    airbag: 'Airbag',
    ABS: 'ABS',
    computer: 'Computer',
    rims: 'Rims',
    sunroof: 'Sun Roof',
    electricMirrors: 'Electric mirrors',
    electricWindows: 'Electric windows',
    dateFirstUse: 'Date first use',
    seats: 'Seats',
    certifiedUsedCars: 'Imodoka zemejwe zakoze',
    certifiedUsedCarsSubtitle: 'Gura imodoka zakoze mu kizere no mu mucyo',
    inpectionsPoints: "Amanota 100 y'isuzuma",
    inpectionsPointsSubtitle: 'Imodoka zacu zose zarasuzumwe neza',
    refundPolicy: 'Ibijyanye no gusubiza amafaranga mu minsi 5',
    refundPolicySubtitle: "You don't like it, get refunded!",
    warrentySubtitles: "Moteri, buwate n'imbaraga, bifite garanti y'amezi 6",
    excellentCondition: 'Excellent condition',
    goodCondition: 'Good condition',
    averageCondition: 'Average condition',
    damagedCar: 'Damaged car',
    lessThan10: 'Less than 10 000 Km',
    moreThan100: 'More than 100 000 Km',
    serviceTitle: 'Kugura cg kugurisha? Twagufasha',
    serviceSubtitle:
        'Explore our services and make your car buying / selling experience seamless ',
    verifyAccount: 'Genzura konti',
    verifyNumber: 'Genzura nimero',
    didntGetTheCode: "Didn't get the code?",
    resendCode: 'Re-send code',
    bluetooth: 'Bluetooth',
    home: 'Ahabanza',
    lookingForBestInsurance: 'Urashaka ubwishingizi bwiza?',
    ourCustomerRepresentativesWillGetBackInTouchWithYou:
        'Ushinzwe kwita ku bakiliya wacu arakuvugisha vuba',
    upload: 'Upload',
    profileUpload: 'Shyiraho indi photo ya porofile',
    remove: 'Vanaho',
    number: 'Number',
    backToCarDetail: 'Back To Detail Page',
    backToTheCarDescription: 'Back to the car description',
    backToTheCarListing: 'Back To Car Listing',
    phoneVerifiedSuccesfully: 'Phone Verified successfully',
    signWithPhone: 'Sign in with Phone',
    signWithEmail: 'Sign in with Email',
    newPriceFrom: 'New Car Price From',
    theySpeakAboutUs: 'Baratuvugaho',
    theySpeakAboutUsSubTitle:
        "Ihitamo ry'abanyamakuru mpuzamahanga bari kuvuga kuri AUTO24",
    carLoanSubTitle: "Igihe kigereranyije cyo kwishyura mu gihe cy'ukwezi",
    carLoanTotalRepayment: 'Total Repayment Amount',
    forgotTokenExpired: 'Password Reset Link is Expired',
    discover: 'Discover the car',
    paperWork: 'Vehicles paperwork are up to date and verified',
    verifyhistory: 'We verify history of all the vehicles we buy',
    buyBack: 'We buy back your old car',
    additionalPhotos: 'Additional Photos',
    technicalVisit: 'Technical visit certificate',
    keysPhotos: 'Keys photo',
    seeKeyPhotos: 'See the photo',
    pleaseTryAfterSomeTime: 'Please try after some time',
    favourites: 'Ibyiza umuntu akunda',
    autonomy: 'Autonomy',
    productionYear: 'Production Year',
    payWithOtherCurrencytitle: 'Pay in an other currency with AUTO24',
    payWithOtherCurrencySubtitle:
        'With AUTO24, you can pay on one of our international bank accounts in any of the currencies below. Here is the price you would need to transfer.',
    convertedPrice: 'Converted Price',
    favouritesNotFoundSubtitle:
        "You haven't added any cars to favourites. Explore cars and add your favourites",
    noFavouritesFound: 'No Favourites Found',
    frontCrankOilSeal: 'Front crank oil seal',
    engineOverHaul: 'Engine overhaul',
    rearCrankOilSeal: 'Rear crank oil seal',
    engineTuneUp: 'Engine tune up',
    engineMount: 'Engine Mount',
    oilPanGasket: 'Oil Pan Gasket',
    valveCoverGasket: 'Valve cover gasket',
    oilFilterBracketOring: 'Oil filter bracket oring',
    intakeManifoldGasket: 'Intake Manifold Gasket',
    waterLeak: 'Water leak',
    radiator: 'Radiator',
    engineTimingCoverGasket: 'Engine Timing Cover Gasket',
    waterPump: 'Water Pump',
    belts: 'Belts',
    fuelFilter: 'Fuel Filter',
    airFilter: 'Air Filter',
    underChassis: 'Under Chassis',
    tieRod: 'Tie Rod',
    ballJointLandR: 'Ball Joint L&R',
    idlerArm: 'idler Arm',
    pitmanArm: 'Pitman Arm',
    steeringPump: 'Steering Pump',
    steeringHoses: 'Steering Hoses',
    steeringBox: 'Steering Box',
    steeringRackAndPinion: 'Steering Rack & Pinion',
    frontShockLandR: 'Front Shock L&R',
    rearShockLandR: 'Rear Shock L&R',
    stabilizerLinkage: 'Stabilizer Linkage',
    crossJointPropellerShaft: 'Cross Joint Propeller Shaft',
    upperArmBushing: 'Upper Arm Bushing',
    rearLowerArmBushing: 'Rear Lower Arm Bushing',
    suspensionSpring: 'Suspension Spring',
    breakSystem: 'Break System',
    brakePad: 'Brake Pad',
    brakeDiscRotor: 'Brake Disc Rotor',
    wheelBearing: 'wheel Bearing',
    brakeMasterCLY: 'Brake Master CLY',
    backBooster: 'Back Booster',
    brakeWheelCall: 'Brake Wheel Call',
    handBrakeCable: 'Hand Brake Cable',
    brakeHoses: 'Brake Hoses',
    rearAxlesAndDriveShafts: 'Rear Axles & Drive Shafts',
    frontAxle: 'Front Axle',
    rearAxle: 'Rear Axle',
    axleDifference: 'Axle Difference',
    differenceFrontOrRear: 'Difference Front Or Rear',
    axleSealFrontOrRear: 'Axle Seal Front Or Rear',
    oilPumpGasket: 'Oil Pump Gasket',
    frontOilSeal: 'Front Oil Seal',
    rearOilSeal: 'Rear Oil Seal',
    transmissionMount: 'Transmission Mount',
    gearStickSouta: 'Gear Stick Souta',
    oilCollerPipe: 'Oil Cooler Pipe',
    gearPerformance: 'Gear Performance',
    liquidSystem: 'Liquid System',
    engineOil: 'Engine Oil',
    transmissionOil: 'Transmission Oil',
    brakeFluid: 'Brake Fluid',
    engineCoolant: 'Engine Coolant',
    washerFluid: 'Washer Fluid',
    electricitySystem: 'Electricity System',
    battery: 'Battery',
    alternator: 'Alternator',
    starler: 'Starler',
    lights: 'Lights',
    ac: 'A/C',
    centerLock: 'Center Lock',
    wiperBlades: 'Wiper Blades',
    troubleCode: 'Trouble Code',
    computerCods: 'Computer Cods',
    acCondensor: 'A/C Condenser',
    acRadiator: 'A/C Radiator',
    interior: 'Interior',
    seatAndBelts: 'Seats And Belts',
    interiorControlsAndSwitches: 'Interior Controls And Switches',
    sunRoofAndWindows: 'Sun Roof And Windows',
    fuelAndTemperatureGauge: 'Fuel And Temperature Gauge',
    dashboardAndGauges: 'Dashboard And Gauges',
    radioOrMusicSystem: 'Radio Or Music System',
    sRSAirbags: 'SRS Airbags',
    steeringWheelTiltOrlock: 'Steering Wheel Tilt Or Lock',
    mirrors: 'Mirrors',
    bodyInspection: 'Body Inspection',
    inspectionDate: 'Inspection Date',
    carInspectionReport: 'Car Inspection Report',
    showPicture: 'Show Picture',
    pageNotFound: 'Page Not Found',
    pageRemoved: "This page doesn't exist or was removed!",
    askQuestionOnWhatsapp: 'Questions? Ask us on whatsapp!',
    whatsappsPlaceholder: 'Ask anything!',
    customerSupport: 'Customer Support',
    needHelpChatWithUs: 'Need Help? Chat with us on Whatsapp',
    instaTitle: 'Dukurikire ku rubuga rwa Instagram',
    instaSubTitle:
        "Bona amakuru agezweho ku bijyanye na poromosiyo, ibicuruzwa n'amakuru ako kanya!",
    questionSentSuccessfully: 'Ibibazo byoherejwe neza',
    questionSentSuccessfullySubtitle:
        'Your question has been sent successfully. We will get back to you for your answer',
    unSubscribeMessage:
        'You have been successfully unsubscribed from the Auto24 Blogs.',
    numberMustBeUnique: 'Number must be unique',
    saving: 'Saving',
    sending: 'Sending',
    signingIn: 'Signing In',
    signingUp: 'Signing Up',
    loggingout: 'Logging out',
    deleting: 'Deleting',
    querySubmittedSuccessfully: 'Query submitted successfully',
    somethingWentWrong: 'Something went wrong!',
    removedFromWishlist: 'Removed from wishlist !!',
    addedToWishlist: 'Added to wishlist !!',
    subscribedSuccessfully: 'Subscribed successfully',
    alreadySubscribed: 'Already subscribed',
    messageIsRequired: 'Message is required',
    fullName: 'Full Name',
    phoneNumberVerifiedSuccess:
        'Phone number verified successfully, Please login.',
    accountVerifiedSuccessfullySubtitle:
        'Your account has been verified successfully. Do you want to login?',
    phoneVerifiedSubtitle:
        'Your phone number has been verified successfully. Do you want to login',
    doyouwantDeleteAccount: 'Urifuza gusiba konte yawe burundu?',
    verifyEmail:
        'Ihangane ugenzure imeyili yawe kuri Auto24, umuyoboro wo kuyigenzura woherejwe kuri imeyili yawe',
    betweenXAndY: 'Between {{X}} and {{Y}} km',
    xIsrequired: '{{name}} is required',
    xInspectionPoints: '{{number}} inspection points',
    xlistingPageResultTitle: '{{number}} Cars Found',
    xlistingPageResultsTitle: '{{number}} Cars Found',
    carViewsXTimes: 'Viewed {{number}} times today',
    maximumXdigitsisallowed: 'Maximum {{X}} digits is allowed',
    minimumXdigit: 'Minimum {{X}} digits is allowed',
    byClickingXButton: 'By clicking {{name}} Button, you agree to our',
    otpVerificationSubtitle:
        'Enter the 5 digit code sent to {{code}} {{number}}',
    otpVerificationEmail: 'Enter the 5 digit code sent to {{email}}',
    sold: 'Guhura',
    new: 'Gishya',
    used: 'Byakoreshejwe',
    popular: 'Populaires',
    makes: 'Amamuko',
    carTypes: `Ibyiciro by'imodoka`,
    explore: 'Gusuzuma',
    followUS: 'Dukurikire',
    none: 'Nta na kimwe',
    all: 'Byose',
    discount: 'Discount',
    careers: 'Imirasire',
    year: 'Year {{X}} and above',
    discountOff: '{{X}} Remise',
    financeYourCarWith: 'Finance Your Car With',
    creditButton: 'CREDIT GRATUIT',
    loanPaymentSimulator: "Kalkilateri y'inguzanyo y'imodoka",
    loanPaymentContent:
        'Turagufasha kubona imodoka wifuza byoroshye. Tureke tugufashe kubona inguzanyo, uhereye ku gushyikiriza impapuro kugeza ku kwemezwa byihuse.',
    loanCalculatorContent:
        'Igipimo cyinyungu cyukwezi kirashobora gutandukana nkuko dufatanya nibigo byimari bitandukanye.',
    vehiclePrice: 'Vehicle price',
    noOfMonthlyPayments: 'Number of monthly payments',
    monthlyPaymentAmount: 'Monthly payment amount',
    creditRequestedAmount: 'Credit Requested Amount',
    overallCostOfCredit: 'Inguzanyo yose',
    simulatorContribution: 'Simulator contribution',
    percentageOfContribution: 'Percentage of contribution',
    applicationFees: 'Application fees',
    monthlyInsurance: 'Monthly insurance',
    noOfOwners: 'Number Of Owners',
    condition: 'Condition',
    maisonMere: 'Maison Mere',
    imported: 'Imported',
    tamperedOdometer: 'Tampered Odometer',
    carIssue: 'Car Issues',
    evaluateAgain: 'Evaluate Again',
    marketValue: 'Market Value',
    downloadAuto24App: `Kubyibuza i-App ya Auto24`,
    browseMore: `Koramya, kwishyura, no gukora ishami n’ubutabera - umukorani wawe w’imodoka w’ikiremwamuntu`,
    saveTimeAndEffort: `Gutura Isaha n'Umurimo`,
    saveTimeAndEffortSubtitle: `Duhamagaremo guhora turebe ibyo bishobora gutuma gutunganya n'ukuvugisha kugirango gutangire akazi, kugeza ibyo gutanga akazi bigira uburenganzira n'ubuhamya`,
    freeNoCost: `Mahoro Ku Rumenyerwa`,
    freeNoCostSubtitle: `Wibagiremo gukora inspectation, ibyangombwa, amafaranga yo kwishyura no kwemera - dukuri ku byose`,
    competetivePrice: `Igiciro Gikomeye`,
    competetivePriceSubtitle: `Gutanga umugeso wo gikomeye n'uburere bwo guha uburyo bwacu; dukorana n'ububiko bwose bukoresheje abakiriya n'ibitabibazo`,
    carTitle: `Ibisobanuro by'igurisha`,
    carDesc: `Cyangira ntitushake ibisobanuro byawe by'igurisha`,
    personalDetail: `Ibisobanuro by'Umuntu`,
    personalDetailDesc: `Cyangira ntitushake ibisobanuro byawe`,
    sellCarHere: `Gushaka igiciro`,
    sellCarHereDesc: `Uzongera kuba ufite ibicuruzwa byiza kandi dukoresheje ubusobanuro bwawe`,
    buySellUsedCar: `Gutwara n'gushaka imodoka zisebanya mu`,
    auto24Press: `Auto24 Umurongo`,
    auto24PressDesc1: `Auto24 yavutse mu birori by'ibitaramo birimo igisobanuro mu byanditse n'abandi.`,
    auto24PressDesc2: `Auto24 yatangije kwandikwa mu mikorere ibiri hagati y'abanyamahanga mu Mujyi wa Paris/France, Lisbon/Portugal, Barcelona/Spain, Sydney/Australia, London/UK, Kigali/Rwanda, Bangkok/Thailand, Abidjan/Ivory Coast, Johannesburg/South Africa, Dakar/Senegal, Nairobi/Kenya, Casablanca/Morocco…`,
    mediaExposure: `Ibikorwa byacu mu mabwiriza`,
    mediaExposureDesc: `Kuri iyi nkuru hari ikinyamakuru cyacu`,
    press: `Amakuru`,
    enterValidPhoneNumber: 'Andika Nombare Ishingiro yo Telefoni',
    sellCar: 'Kugurisha imodoka',
    findusGoogle: 'Dusange kuri Google Map',
    locateUs: 'Tudushakire',
    whereFindUs: 'Aho wadusanga?',
    aroundLocationText: "Ushobora kudusanga hafi y'izi hantu",
    facebook: 'Facebook',
    instagram: 'Instagram',
    tiktok: 'Tiktok',
    twitter: 'Twitter',
    youtube: 'Youtube',
    linkedin: 'LinkedIn',
    heroTitle:
        'Uburyo bworoshye kandi bwihuse bwo <yellow>Kugura</yellow> cyangwa <green>Kugurisha</green> Imodoka',
    caRefundPolicy: 'Politiki yo kugarura ibicuruzwa',
    caRefundPlicyDesc:
        'Garura nta nkomyi. Urahabwa amafaranga yawe niba utanyuzwe.',
    caWarranty: 'Garanti',
    caWarrantyDesc: "Garanti y'amezi 6. Humura, imodoka yawe irarinzwe.",
    caInspectedDesc: "Ubwiza bwizewe. Byemejwe n'itsinda ry'inzobere.",
    login: 'Injira',
    wantToSellYourCar: 'Urashaka kugurisha imodoka yawe?',
    findUs: 'Dusange',
    featuredCars: 'Imodoka',
    exploreCarByBodyType: 'Shakisha Imodoka Ubwoko bwumubiri',
    searchByBrands: 'Shakisha Kubirango',
    loanSubtitle:
        'Twite kubakiriya bacu nibyishimo byabo bidutera inkunga yo gutanga serivisi nyinshi',
    perfectMatchYourBudget: 'Umukino utunganijwe neza kuri bije yawe',
    sellYourVehicle: 'Urashaka kugurisha imodoka yawe?',
    sellYourVehicleSubtitle:
        'Twite kubakiriya bacu na happyienes badutera inkunga yo gutanga serivisi nyinshi',
    sellMyCar: 'Kugurisha Imodoka yanjye',
    blogTitleMobile: 'Reba Mbere yo Kugura',
    blogSubtitleMobile: 'Ibisomwa bishya kuva auto24',
    readMore: 'Soma Ibikurikira',
    opps: 'Opps!',
    noCarSection:
        'Birasa nkaho nta rutonde ruri muri iki gice. Urashaka gusaba imodoka?',
    requestCar: 'Saba imodoka',
    submitRequestFormSubtitle:
        'Tanga icyifuzo, tuzagufasha kubona imodoka nziza ijyanye nibyo ukeneye.',
    requestCarPageTitle: 'Menya Imodoka Washakishaga',
    requestCarPageSubtitle:
        'Tumenyeshe ibyo ukunda, kandi ikipe yacu izakora kugirango ibone umukino mwiza kuri wewe. Tanga icyifuzo cyawe uyumunsi hanyuma wegere intambwe imwe yo gutwara imodoka yawe yinzozi.',
    viewAll: 'Reba Byose',
    inspection100: "Amapointi 100 y'isuzuma yakozwe n'inzobere z'imodoka zacu",
    sixMonthWarranty:
        "Garanti y'amezi 6 (moteri, agasanduku k'imodoka n'irindiro)",
    twoCarMaintenance: "Serivisi ebyiri z'imodoka na Auto24 mu mezi 12",
    towingAndBreakdownAssistance:
        "Serivisi zo guta imodoka hamwe no gutabara mu gihe cy'umwaka 1",
    multiCurrencyPayment: 'Kwishyura mu madorali menshi FCFA, €, $, £',
    fiveDaysMoneyBack: 'Gusubizwa amafaranga mu minsi 5',
    vehiclesPaperWork: "Impapuro z'imodoka ni iz'ubu kandi zaragenzuwe",
    verifyHistory: "Twemeza amateka y'imodoka zose tugura",
    buyBackOldCar: 'Twishyura imodoka yawe ishaje',
    aboutCar: 'Ibyerekeye Imodoka',
    overview: 'Incamake',
    carLoan: "Inguzanyo y'imodoka",
    cars: 'Imodoka',
    note: 'Icyitonderwa',
    yourEstimatedMonthlyPayment: 'Biteganijwe ko wishyura buri kwezi',
    applyFilter: 'Koresha Akayunguruzo',
    clearAll: 'Kuraho Byose',
    carsUnderPriceDescription:
        'Auto24 niyo mahitamo meza yo kugufasha gushakisha ubwoko butandukanye bw’imodoka buva hirya no hino ku isi. Niba ushaka imodoka nto yo mu mujyi, SUV yagenewe umuryango, cyangwa sedan y’akataraboneka, urubuga rwacu ruguhuza n’amahitamo akwiranye n’ibyo ukeneye n’ibyo ukunda. Buri bwoko bw’imodoka buri kuri Auto24 buzwiho ubuhanga mu bwubatsi, ikoranabuhanga rihambaye, n’ubwizerwe, bikakugirira akamaro mu rugendo rwawe rwo gushaka imodoka nziza ijyanye nawe.',
    fuelPriceTracker: "Ikurikirana ry'ibiciro bya lisansi",
    fuelPriceTrackerDesc:
        "Kurikirana ibiciro bya lisansi byoroshye ukoresheje igikoresho cyacu! Menya amakuru mashya y'ibiciro bya lisansi mu gace kawe, gereranya ibiciro mu masitasiyo atandukanye, kandi ubone ibyiza bikwegereye. Bika amafaranga kandi ufate ibyemezo byiza buri gihe uko ugura lisansi. Gumana ubuyobozi ku ngengo y'imari yawe—kurikirana ibiciro bya lisansi uyu munsi!",
    fuelPriceList: "Urutonde rw'ibiciro by'amavuta",
    lastUpdateDate: "Itariki y'ihinduka rya nyuma",
    electricityPriceTracker: 'Ikurikiranabiciro by’Amashanyarazi',
    electricityPriceTrackerDesc:
        'Ibiciro by’amashanyarazi muri {{ Country_name }} bishobora guhindagurika bitewe n’amadolari, igabanuka ry’umuriro, n’icyifuzo cy’ingo n’inganda. Ikurikiranabiciro by’Amashanyarazi ryacu rikomeza kukugezaho amakuru agezweho ku madolari ya Eskom n’amashanyarazi, kugira ngo ubashe gucunga neza ingengo y’imari y’ingufu.',
    electricityPriceList: 'Urutonde rw’Ibiciro by’Amashanyarazi',
    currencyConverter: 'Guhindura Ifaranga',
    currencyConverterDesc:
        'Komeza kumenya uko amafaranga ahagaze ku isoko. Niba uri mu rugendo, uri guhaha kuri murandasi, cyangwa uri mu bucuruzi bw’ifaranga, iki gikoresho kigufasha kubona igiciro cyiza ako kanya.',
    liveExchangeRates: 'Igiciro cy’ifaranga kiriho ubu',
    updatedAsOf: 'Mis à jour le',
    kmToMi: 'Kilomita kugeza kuri Mile',
    miToKm: 'Mile kugeza kuri Kilomita',
    kmToMiConverter: 'Igikoresho cyo Guhindura Kilomita kugeza kuri Mile',
    miToKmConverter: 'Igikoresho cyo Guhindura Mile kugeza kuri Kilomita',
    distanceConverterDesc:
        'Hindura byihuse kandi neza intera ya mile kugeza kuri kilomita no guhindura—byiza kugirango ugendera, kudiriza no kubara iminsi yose!',
    mile: 'Mile',
    kilometer: 'Kilomita',
    howToConvert: 'Uhindura {{ unit }} gute?',
    benin: 'Benin',
    coteDIvora: "Côte d'Ivoire",
    kenya: 'Kenya',
    southAfrica: "Afurika y'Epfo",
    morocco: 'Maroke',
    rwanda: 'u Rwanda',
    senegal: 'Senegal',
    tanzania: 'Tanzaniya',
    togo: 'Togo',
    uganda: 'Uganda',
    vehicleTaxCalculator: "Umubarizi w'umusoro ku modoka",
    topCarSales: 'Imodoka nshya zigurishwa cyane',
    drivingCostCalculator: "Umubarizi w'igiciro cyo gutwara",
    insuranceEstimator: "Umugenagaciro w'ubwishingizi",
    carLoanCalculator: "Umubarizi w'inguzanyo y'imodoka",
    exploreOtherTools: 'Jyarubura ibindi bikoresho',
    drivingCostCalculatorDesc:
        "Kumva ikiguzi cyo gutwara ni ngombwa mu gucunga ingengo y'imari no kunoza amafaranga y'urugendo. Yaba uri guhora utembera buri munsi, ugiye urugendo rurerure mu modoka, cyangwa ucunga ibinyabiziga byinshi, Ibaruramahwi yacu y'Ikiguzi cyo Gutwara igufasha kubara neza amafaranga y'ibikomoka kuri peteroli. Tegura mbere, gereranya inzira, kandi ufate ibyemezo by'urugendo byiza ku buryo bworoshye.",

    distance: 'Intera',
    fuelPrice: 'Igiciro cya lisansi',
    fuelEconomy: 'Kuzigama lisansi',
    estimatedDrivingCost: 'Ikiguzi cyawe kigenwe cyo gutwara ni',
    enterAllValues: 'Shyiramo imibare yose kugira ngo ubare',
    emissionEstimator: 'Kubara CO2 isohoka',
    emissionEstimatorDesc:
        "Pima ingaruka zawe ku bidukikije kandi umenye uburyo bwo kugabanya CO2 usohora mu bijyanye no gutwara abantu n'ibintu, gukoresha ingufu, n'imibereho ya buri munsi. Genzura ibikorwa byawe by'iterambere rirambye kandi ufashe mu kubungabunga isi yacu.",
    estimatedEmission: 'CO2 yawe ikeka ko isohoka ni',
    emissionFactor: 'Ingano ya CO2 isohoka',
    efficiency: 'Ubushobozi',
    bestSellingCarsOfYear:
        'Imodoka zigurishwa cyane mu mwaka wa {{ CurrentYear }}',
    discoverBestSellingCarsOfYear:
        'Menya imodoka zigurishwa cyane mu mwaka wa {{ CurrentYear }}: Isuzuma ryimbitse ku myitwarire y’imodoka ku isi, imiterere y’imodoka zigurishwa cyane, n’izamuka ry’imodoka z’amashanyarazi.',
    loanAmount: "Inguzanyo y'amafaranga",
    carLoanCalculatorDesc:
        'Teganya kugura imodoka yawe y’igihe kizaza wizeye uko uzishyura buri kwezi, inyungu rusange, n’ikiguzi cy’inguzanyo. Niba ugura nshya cyangwa ishaje, iki gikoresho kigufasha kubona inguzanyo ikwiranye n’ubushobozi bwawe.',
    vehicleTechnicalDocuments: "Impapuro za Tekinike z'Ibinyabiziga",
    vehicleTechnicalDocumentsDesc:
        'Hano hari ibinyabiziga byose bifite impapuro za tekinike.',
    vehicleName: "Izina ry'ikinyabiziga",
    fileSize: "Ingano y'ikinyamakuru",
    modifiedDate: 'Itariki yahinduwe',
    action: 'Igikorwa',
    downloadDoc: 'Kuramo inyandiko',
    technicalDocs: 'Impapuro za tekiniki',
    dataNotAvailable: 'Inyandiko ntiziboneka.',
    documentsAvailable: 'impapuro ziboneka',
    downloadError: 'Byanze gukururwa',
    failedToDownload:
        'Habaye ikosa mu gukurura dosiye. Gerageza nyuma cyangwa twandikire kugira ngo tugufashe.',
    close: 'Funga'
};

export default commonKinyarwanda;
