import { SwiperSlide } from "swiper/react";
//
import CarCard from "./CarCard";
import { ICar } from "../interface/car";
import Slider from "./Slider";

interface ICarSlider {
    carData: Array<ICar>,
}

const CarsSlider = ({ carData }: ICarSlider) => {

    return carData.length > 0 ? <Slider
        totalItem={carData.length}
        additionalSliderClasses="remove-arrow-for-mobile"
        swiperOptions={{
            breakpoints: {
                320: {
                    slidesPerView: 1.15,
                    spaceBetween: 14
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 16
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1400: {
                    slidesPerView: 4,
                    spaceBetween: 16
                },
            }
        }}
    >
        {
            carData.map((data, index) => <SwiperSlide key={`${data._id}-${index}`} >
                <CarCard data={data} />
            </SwiperSlide>)
        }

    </Slider> : <></>
}

export default CarsSlider;