import {
    ChangeEvent,
    FormEvent,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Trans, useTranslation } from 'react-i18next';

import { getCountryImage, getTopLevelDomain } from '../utils/translation';
import { COUNTRY_TOP_LEVEL_DOMAIN, IMAGE_URL } from '../constants';
import { MagnifyingGlass } from 'phosphor-react';
import { Carousel, Dropdown } from 'antd';
import { ICar, ICarResponse } from '../interface/car';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';
import { formatMoney } from './SearchFilter';
import { getDiscountedPriceIfNotSold } from './ui/Card';
import {
    RemoveWhiteSpacesFromString,
    ReplaceWhiteSpaceWithDashString
} from '../utils/Helper/jsHelpers';

const heroImages = [
    {
        src: '/images/hero/hero_image.webp',
        alt: 'A family with husband, wife and two children similing and looking forward in a car'
    },
    {
        src: '/images/hero/hero_image_1.webp',
        alt: 'A family with husband, wife and daughter similing and facing forward in camera'
    },
    {
        src: '/images/hero/hero_image_2.webp',
        alt: 'A group of 3 friends smiling and looking forward in a car'
    }
];

function getBackgroundImageByDomain() {
    const topLevelDomain = getTopLevelDomain();
    switch (topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            return '/images/hero/hero_image_1.webp';
        default:
            return '/images/hero/hero_image.webp';
    }
}

const Hero = ({ recentlyAddedCars }: { recentlyAddedCars: ICarResponse }) => {
    const topLevelDomain = getTopLevelDomain();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(['hero']);
    const { t: trans } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [maxButtonWidth, setMaxButtonWidth] = useState(0);

    const handleDropdownOpen = useCallback((status: boolean) => {
        setIsDropdownOpen(status);
    }, []);

    const handleSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value);
        },
        []
    );

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        navigate(
            `${t('/buy-cars', { ns: 'routes' })}${
                !!searchText ? `?search=${searchText}` : ''
            }`
        );
    };

    const handleCarTypeClick = ({
        brand,
        model,
        price,
        uId,
        modelYear
    }: Pick<ICar, 'brand' | 'model' | 'price' | 'uId' | 'modelYear'>) => {
        navigate(
            `../${t('/buy', { ns: 'routes' })}/${RemoveWhiteSpacesFromString(
                brand?.brand
            )}/${ReplaceWhiteSpaceWithDashString(
                model?.model
            )}/${modelYear}/${price}/${uId}`
        );
    };

    useEffect(() => {
        if (searchInputRef.current) {
            setMaxButtonWidth(searchInputRef.current.offsetWidth);
        }

        const handleResize = () => {
            if (searchInputRef.current) {
                setMaxButtonWidth(searchInputRef.current.offsetWidth);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent): void => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                handleDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleDropdownOpen]);

    const dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

    return (
        <section className="section-hero">
            <div className="hero-container">
                <div className="hero__row">
                    <div className="hero__col--left">
                        <div className="hero__content">
                            <h1 className="hero__title" dir={dir}>
                                <Trans
                                    i18nKey="heroTitle"
                                    components={{
                                        yellow: (
                                            <span className="hero__title--bg-yellow" />
                                        ),
                                        green: (
                                            <span className="hero__title--bg-green" />
                                        )
                                    }}
                                />

                                <span>
                                    <img
                                        src={getCountryImage().src}
                                        alt={getCountryImage().alt}
                                        style={{
                                            width: '65px',
                                            height: '65px'
                                        }}
                                        loading="lazy"
                                    />
                                </span>
                            </h1>
                            <form
                                className="hero__form"
                                onSubmit={handleSearchSubmit}
                            >
                                <div
                                    className="hero__input-container"
                                    ref={dropdownRef}
                                >
                                    <Dropdown
                                        trigger={[]}
                                        open={isDropdownOpen}
                                        onOpenChange={handleDropdownOpen}
                                        getPopupContainer={(triggerNode) => {
                                            return triggerNode.parentNode as HTMLElement;
                                        }}

                                        dropdownRender={() => (
                                            <div className="hero__dropdown-content">
                                                {recentlyAddedCars?.cars
                                                    ?.length > 0 &&
                                                    searchText.length === 0 && (
                                                        <>
                                                            <h4 className="hero__dropdown-title">
                                                                {trans(
                                                                    'recentlyAdded'
                                                                )}
                                                            </h4>
                                                            <div className="hero__dropdown-cards">
                                                                {recentlyAddedCars?.cars?.slice(0, 3).map(
                                                                    (car) => {
                                                                        const images = 
                                                                            JSON.parse(
                                                                                car?.images ??
                                                                                    '[]'
                                                                            ) ??
                                                                            [];
                                                                        const modifiedUrl =
                                                                            IMAGE_URL +
                                                                            images?.[0]?.replace(
                                                                                /(vehicles\/\d{4}\/\d{2}\/)/,
                                                                                '$1small/'
                                                                            );

                                                                        return (
                                                                            <div
                                                                                className="hero__dropdown-card"
                                                                                key={
                                                                                    car?._id
                                                                                }
                                                                                onClick={() => {
                                                                                    handleCarTypeClick(
                                                                                        {
                                                                                            brand: car?.brand,
                                                                                            model: car?.model,
                                                                                            price: car?.price,
                                                                                            uId: car?.uId,
                                                                                            modelYear:
                                                                                                car?.modelYear
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <div className="hero__dropdown-card__image-wrapper">
                                                                                    <img
                                                                                        className="hero__dropdown-card__image"
                                                                                        src={
                                                                                            modifiedUrl
                                                                                        }
                                                                                        alt={
                                                                                            car?.name
                                                                                        }
                                                                                        onError={
                                                                                            addImageFallback
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="hero__dropdown-card__content">
                                                                                    <h3 className="hero__dropdown-card__title">
                                                                                        {
                                                                                            car
                                                                                                ?.brand
                                                                                                ?.brand
                                                                                        }{' '}
                                                                                        {
                                                                                            car
                                                                                                ?.model
                                                                                                ?.model
                                                                                        }{' '}
                                                                                        {
                                                                                            car?.modelYear
                                                                                        }
                                                                                    </h3>
                                                                                    <div className="hero__dropdown-card--info">
                                                                                        <h4 className="hero__dropdown-card__price">
                                                                                            {topLevelDomain ===
                                                                                            COUNTRY_TOP_LEVEL_DOMAIN.coza
                                                                                                ? process
                                                                                                      .env
                                                                                                      .REACT_APP_DEFAULT_CURRENCY
                                                                                                : ''}{' '}
                                                                                            {car?.status !==
                                                                                                'sold' &&
                                                                                            String(
                                                                                                car?.discount
                                                                                            ) !==
                                                                                                '0' &&
                                                                                            String(
                                                                                                car?.discount
                                                                                            ) !==
                                                                                                'null' &&
                                                                                            String(
                                                                                                car?.price
                                                                                            ) !==
                                                                                                '0'
                                                                                                ? getDiscountedPriceIfNotSold(
                                                                                                      car?.price ??
                                                                                                          0,
                                                                                                      car.discount ??
                                                                                                          0
                                                                                                  )
                                                                                                : formatMoney(
                                                                                                      car?.price
                                                                                                  )}{' '}
                                                                                            {topLevelDomain !==
                                                                                            COUNTRY_TOP_LEVEL_DOMAIN.coza
                                                                                                ? process
                                                                                                      .env
                                                                                                      .REACT_APP_DEFAULT_CURRENCY
                                                                                                : ''}
                                                                                        </h4>
                                                                                        <span className="hero__dropdown-card__travelled">
                                                                                            <img
                                                                                                src="/logos/timeline.svg"
                                                                                                alt="timeline"
                                                                                                loading="lazy"
                                                                                            />
                                                                                            {formatMoney(
                                                                                                car?.mileage
                                                                                            )}{' '}
                                                                                            KM
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                <button
                                                    className="hero__button"
                                                    type="submit"
                                                    dir={dir}
                                                    onFocus={() =>
                                                        handleDropdownOpen(true)
                                                    }
                                                    onBlur={() =>
                                                        handleDropdownOpen(
                                                            false
                                                        )
                                                    }
                                                    style={{
                                                        maxWidth:
                                                            maxButtonWidth +
                                                            24 -
                                                            16 -
                                                            40
                                                    }}
                                                >
                                                    {searchText.length ? (
                                                        <span className="hero__button__search-text">
                                                            {trans(
                                                                'viewAllResultsFor'
                                                            )}{' '}
                                                            '{searchText}'
                                                        </span>
                                                    ) : (
                                                        <span className="hero__button__search-text">
                                                            {trans(
                                                                'viewAllCars'
                                                            )}
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        )}
                                    >
                                        <input
                                            ref={searchInputRef}
                                            type="search"
                                            className="hero__input"
                                            placeholder={t(
                                                'searchBarPlaceholder'
                                            )}
                                            onChange={handleSearchChange}
                                            dir={dir}
                                            onFocus={() =>
                                                handleDropdownOpen(true)
                                            }
                                        />
                                    </Dropdown>
                                    <MagnifyingGlass
                                        size={24}
                                        className="hero__input-icon"
                                    />
                                </div>
                                <button
                                    className="hero__button"
                                    type="submit"
                                    dir={dir}
                                >
                                    {trans('viewAllCars')}
                                </button>
                                <p className="hero__sub-text">
                                    {trans('wantToSellYourCar')}{' '}
                                    <Link
                                        to={`${t('/sell-car', {
                                            ns: 'routes'
                                        })}`}
                                        className="hero__sub-text-link"
                                    >
                                        {trans('sellCar')}
                                    </Link>
                                </p>
                            </form>
                        </div>
                    </div>
                    <div className="hero__col--right">
                        <Carousel className="hero__carousel" autoplay>
                            {heroImages.map((heroImage) => (
                                <img
                                    key={heroImage.src}
                                    className="hero__photo"
                                    src={heroImage.src}
                                    alt={heroImage.alt}
                                    width={850}
                                    height={435}
                                />
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
