import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { COUNTRY_TOP_LEVEL_DOMAIN, IMAGE_URL } from '../constants';
import { getDiscountedPriceIfNotSold, LikeOrNot } from './ui/Card';
import AuthFlow from './AuthFlow/AuthFlow';
import { formatMoney } from './SearchFilter';
import { getTopLevelDomain } from '../utils/translation';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';
import { ICar } from '../interface/car';
import {
    getCurrencyFormatWithSpace,
    RemoveWhiteSpacesFromString,
    ReplaceWhiteSpaceWithDashString
} from '../utils/Helper/jsHelpers';
import GasPumpSvg from './ui/icons/GasPumpSvg';
import ManualGearSvg from './ui/icons/ManualGearSvg';
import Speedometer from './ui/icons/SpeedometerSvg';

interface ICardProps {
    data: ICar;
    newCarFlag?: boolean;
    per_month_location?: 'over-image' | 'card-bottom';
}

const CarCard = ({
    data,
    newCarFlag = false,
    per_month_location
}: ICardProps) => {
    const { t } = useTranslation();
    const [showAuthFlow, setAuthFlow] = useState(false);
    const topLevelDomain = getTopLevelDomain();
    const navigate = useNavigate();

    let images = [];
    if (data?.images) images = JSON.parse(data.images);
    const imageTitle = data?.name?.toLowerCase().split(' ').join('_');
    const perMonth_static_value = 26666;
    const goToCarDetails = (car: ICar) => {
        navigate(
            `../${t('/buy', { ns: 'routes' })}/${RemoveWhiteSpacesFromString(
                car.brand?.brand
            )}/${ReplaceWhiteSpaceWithDashString(car?.model?.model)}/${
                car.modelYear
            }/${car.price}/${car.uId}`
        );
    };

    return (
        <>
            <div
                className="car-card-box"
                onClick={() => !!data._id && goToCarDetails(data)}
            >

                <div className="car-card-box-image">
                    {data.isNewCar && (
                        <div className="car-card-box-status left new">
                            <span>{t('new')}</span>
                        </div>
                    )}

                    {data.status !== 'sold' &&
                        String(data.discount) !== '0' &&
                        String(data.discount) !== 'null' && (
                            <div className="car-card-box-status right discount">
                                <span className="">{`${getCurrencyFormatWithSpace(
                                    data.discount
                                )} OFF`}</span>
                            </div>
                        )}

                    {per_month_location === 'over-image' ? (
                        <div className="featured-car-monthly-pill bottom-right car-card-box-monthly-text">
                            <span className="">{`${getCurrencyFormatWithSpace(
                                perMonth_static_value
                            )} / Month`}</span>
                        </div>
                    ) : (
                        <></>
                    )}

                    <img
                        src={
                            IMAGE_URL +
                            images[0]?.replace(
                                /(vehicles\/\d{4}\/\d{2}\/)/,
                                '$1small/'
                            )
                        }
                        alt={`${imageTitle}_img_`}
                        onError={addImageFallback}
                        loading="lazy"
                    />
                </div>
                <div className="car-card-box-content">
                    <div className="car-card-box-title">
                        <span className='text-truncate'>{data.name}</span>
                        {data._id && (
                            <LikeOrNot
                                id={data._id}
                                setAuthFlow={setAuthFlow}
                            />
                        )}
                    </div>
                    <div className="car-card-box-features">
                        {data.transmission && (
                            <span className="car-card-box-features-container">
                                <ManualGearSvg
                                    height={16}
                                    width={16}
                                    stroke="#8B8B8B"
                                />
                                {t(data.transmission)}
                            </span>
                        )}
                        {data.fuelType && (
                            <>
                                <span>•</span>
                                <span className="car-card-box-features-container">
                                    <GasPumpSvg
                                        height={16}
                                        width={16}
                                        stroke="#8B8B8B"
                                    />
                                    {t(data.fuelType)}
                                </span>
                            </>
                        )}

                        {!newCarFlag && (
                            <>
                                <span>•</span>

                                <span className="car-card-box-features-container text-capitalize">
                                    <Speedometer
                                        height={16}
                                        width={16}
                                        stroke="#8B8B8B"
                                    />
                                    {formatMoney(data.mileage)} KM
                                </span>
                            </>
                        )}
                    </div>
                    <hr className="featured-content-divider" />
                    <div className="car-card-box-price">
                        <span className="card-price">
                            {data?.status !== 'sold' &&
                            String(data?.discount) !== '0' &&
                            String(data?.discount) !== 'null' &&
                            String(data?.price) !== '0'
                                ? `${
                                      topLevelDomain ===
                                      COUNTRY_TOP_LEVEL_DOMAIN.coza
                                          ? process.env
                                                .REACT_APP_DEFAULT_CURRENCY
                                          : ''
                                  } ${getDiscountedPriceIfNotSold(
                                      data?.price ?? 0,
                                      data.discount ?? 0
                                  )} ${
                                      topLevelDomain !==
                                      COUNTRY_TOP_LEVEL_DOMAIN.coza
                                          ? process.env
                                                .REACT_APP_DEFAULT_CURRENCY
                                          : ''
                                  }`
                                : `${
                                      topLevelDomain ===
                                      COUNTRY_TOP_LEVEL_DOMAIN.coza
                                          ? process.env
                                                .REACT_APP_DEFAULT_CURRENCY
                                          : ''
                                  } ${formatMoney(data.price)} ${
                                      topLevelDomain !==
                                      COUNTRY_TOP_LEVEL_DOMAIN.coza
                                          ? process.env
                                                .REACT_APP_DEFAULT_CURRENCY
                                          : ''
                                  }`}
                        </span>
                        <span className="featured-car-discount">
                            {data.status !== 'sold' &&
                                String(data.discount) !== '0' &&
                                String(data.discount) !== 'null' && (
                                    <>
                                        {`${
                                            topLevelDomain ===
                                            COUNTRY_TOP_LEVEL_DOMAIN.coza
                                                ? process.env
                                                      .REACT_APP_DEFAULT_CURRENCY
                                                : ''
                                        } ${formatMoney(data.price)} ${
                                            topLevelDomain !==
                                            COUNTRY_TOP_LEVEL_DOMAIN.coza
                                                ? process.env
                                                      .REACT_APP_DEFAULT_CURRENCY
                                                : ''
                                        }`}
                                    </>
                                )}
                        </span>
                    </div>
                    {per_month_location === 'card-bottom' ? (
                        <div className="car-card-box-monthly-text">
                            <span className="">{`${getCurrencyFormatWithSpace(
                                perMonth_static_value
                            )} / Month`}</span>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            {showAuthFlow && (
                <AuthFlow
                    isVisible={showAuthFlow}
                    like_id={data._id}
                    handleCancel={() => {
                        setAuthFlow(!showAuthFlow);
                    }}
                />
            )}
        </>
    );
};

export default CarCard;
