const FiveElementArray = Array(5).fill(0);

interface IStarRatingProps {
    rating: number
}


const StarRating = ({rating}:IStarRatingProps) => {
    const ratingRounded = Math.round(rating);

    return <div className="star-rating-container">
        {
            FiveElementArray.map((el, index) => (
                <span key={index} className={ratingRounded > index ? "active": "inactive"}>*</span>
            ))
        }
    </div>
}

export default StarRating;