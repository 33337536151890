import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { request } from '../../helpers/request';
import {
    FETCH_BLOG_LOADING,
    FETCH_BLOG_SUCCESS,
    FETCH_ONE_BLOG,
    FETCH_RECENT_BLOG_SUCCESS
} from '../types/BlogListTypes';
import { ICarListingPagination } from '../../interface/car';

export const getAllBlogs = (dispatch: Dispatch) => {
    dispatch({ type: FETCH_BLOG_LOADING });
    request
        .getAllBlogs()
        .then((res: AxiosResponse) => {
            dispatch({ type: FETCH_BLOG_SUCCESS, data: res.data });
        })
        .catch((error: AxiosError | Error) => {
            if (axios.isAxiosError(error)) {
            } else {
            }
        });
};

export const getRecentBlogs = (dispatch: Dispatch, params?: ICarListingPagination) => {
    dispatch({ type: FETCH_BLOG_LOADING });
    request
        .getRecentBlogs(params)
        .then((res: AxiosResponse) => {
            dispatch({ type: FETCH_RECENT_BLOG_SUCCESS, data: res?.data });
        })
        .catch((error: AxiosError | Error) => {
            if (axios.isAxiosError(error)) {
            } else {
            }
        });
};

export const getOneBlog = (dispatch: Dispatch, slug: string) => {
    dispatch({ type: FETCH_BLOG_LOADING });
    request
        .getOneBlog(slug)
        .then((res: AxiosResponse) => {
            dispatch({ type: FETCH_ONE_BLOG, data: res.data?.blog });
            return Promise.resolve(res.data);
        })
        .catch((error: AxiosError | Error) => {
            if (axios.isAxiosError(error)) {
                return Promise.reject(error.message);
            } else {
            }
            return Promise.reject(error.message);
        });
};
// export const getOneBlog = (dispatch: Dispatch, id: string) => {
//     dispatch({ type: FETCH_BLOG_LOADING });
//     request
//         .getOneBlog(id)
//         .then((res: AxiosResponse) => {
//             dispatch({ type: FETCH_ONE_BLOG, data: res.data?.blog });
//             return Promise.resolve(res.data);
//         })
//         .catch((error: AxiosError | Error) => {
//             if (axios.isAxiosError(error)) {
//                 return Promise.reject(error.message);
//             } else {
//             }
//             return Promise.reject(error.message);
//         });
// };
