import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BannerSection from "./BannerSection";

const CalculateLoanSection = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const toFinance = () => {
        navigate(t('/car-finance', { ns: 'routes' }))
    }
    return <BannerSection
        title={t('loanTitle')}
        subTitle={t('loanSubtitle')}
        banner_image_url="/images/two-people.webp"
        buttonText={t('loanButton')}
        onButtonClick={toFinance}
        additional_section_class="calculate-loan-section"
    />
}

export default CalculateLoanSection;