import { PressArray } from "../constants";

const MediaRecognitionSection = () => {
    return <section className="padding">
        <div className="media-recognition-section">
            <div className="media-recognition-block top">
                {
                    PressArray.slice(0, 3).map((item, index) => <div key={`${item.alt}-${index}`} className="media-recognition-item">
                        <a href={item.link} target="_blank" rel="noreferrer">
                            <img src={item.image} alt={item.alt} loading="lazy" />
                        </a>
                    </div>)
                }
            </div>
            <div className="media-recognition-block bottom">
                {
                    PressArray.slice(3, 6).map((item, index) => <div key={`${item.alt}-${index}`} className="media-recognition-item">
                        <a href={item.link} target="_blank" rel="noreferrer">
                            <img src={item.image} alt={item.alt} loading="lazy" />
                        </a>
                    </div>)
                }
            </div>
        </div>
    </section>
}

export default MediaRecognitionSection;