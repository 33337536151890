import { useEffect, useState } from 'react';

import Layout from '../layout/Layout';

import { contentAccordToCurrentLang, SetMeta } from '../utils/Helper/jsHelpers';
import ListingOverviewWidget from '../components/ListingOverviewWidget';
import { useParams } from 'react-router-dom';
import { getBodyType } from '../redux/actions/carBodyTypeAction';
import { IMAGE_URL } from '../constants';
import { useTranslation } from 'react-i18next';
import CarFilterSection from '../components/carListingPage/CarListFilterSection';
import CarListSection from '../components/carListingPage/CarListSection';
import Notfound from './Notfound';

const BodyType = () => {
    // This ensures the page scrolls to the top on mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();

    const { bodyTypeName } = useParams();
    const [bodyType, setBodyType] = useState<any>(null);

    useEffect(() => {
        getBodyType(bodyTypeName).then((bodyType: any) => {
            setBodyType(bodyType?.data?.carBodyType);
        });
    }, [bodyTypeName]);

    const metaTitle = bodyType
        ? t('bodyTypePageTitle', {
              ns: 'meta',
              BODY_TYPE: contentAccordToCurrentLang(bodyType)?.bodyType
          })
        : 'Loading...';

    return (
        <>
            <SetMeta
                title={metaTitle}
                desc={
                    bodyType &&
                    t('buyPageDesc', {
                        ns: 'meta',
                        BRAND: contentAccordToCurrentLang(bodyType)?.bodyType
                    })
                }
                keywords="buyPageKeywords"
                properties={[
                    {
                        key: 'og:title',
                        value:
                            bodyType &&
                            contentAccordToCurrentLang(bodyType)?.bodyType
                    },
                    {
                        key: 'og:description',
                        value:
                            bodyType &&
                            t('buyPageDesc', {
                                ns: 'meta',
                                BRAND: contentAccordToCurrentLang(bodyType)
                                    ?.bodyType
                            })
                    },
                    {
                        key: 'og:keywords',
                        value: 'buyPageKeywordsOg'
                    }
                ]}
                image={IMAGE_URL + bodyType?.image}
            />
            <Layout showWhiteNavbar>
                <div className="listing-page">
                    {bodyType && (
                        <>
                            <CarFilterSection activeBodyType={bodyTypeName} />
                            <ListingOverviewWidget
                                title={
                                    contentAccordToCurrentLang(bodyType)
                                        ?.bodyType
                                }
                                description={
                                    contentAccordToCurrentLang(bodyType)
                                        ?.description
                                }
                                image={bodyType.image}
                            />
                            <CarListSection activeBodyType={bodyTypeName} />
                        </>
                    )}
                    {!bodyType && <Notfound hideNav />}
                </div>
            </Layout>
        </>
    );
};
export default BodyType;
