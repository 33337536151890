import { COUNTRY_TOP_LEVEL_DOMAIN } from '../../constants';

const getMetaFrench = (topLevelDomain: string) => {
    switch (topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.bj:
            return {
                homePageTitle:
                    "AUTO24.bj - Voitures d'Occasion Certifiées à Benin | Achetez et Vendez Localement",
                homePageDesc:
                    "À la recherche d'une voiture d'occasion certifiée à Benin? AUTO24.bj est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Vendre Voiture Benin, Marché Automobile, AUTO24 Benin, Voitures de Qualité, Vente Auto Benin",
                homePageTitleOg:
                    "AUTO24.bj - Voitures d'Occasion Certifiées à Benin | Achetez et Vendez Localement",
                homePageDescOg:
                    "À la recherche d'une voiture d'occasion certifiée à Benin? AUTO24.bj est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Vendre Voiture Benin, Marché Automobile, AUTO24 Benin, Voitures de Qualité, Vente Auto Benin",
                buyPageTitle:
                    "Voitures à vendre au Benin | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.bj",
                buyPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées {{ BRAND }} sur AUTO24.bj . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Benin.",
                buyPageKeywords:
                    "Acheter Voitures Benin, Voitures d'Occasion Certifiées, AUTO24 Benin, Shopping de Voitures, Voitures Abordables Benin",
                buyPageTitleOg:
                    "Voitures à vendre au Benin | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.bj",
                buyPageDescOg:
                    "Parcourez une large sélection de voitures d'occasion certifiées sur AUTO24.bj . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Benin.",
                buyPageKeywordsOg:
                    "Acheter Voitures Benin, Voitures d'Occasion Certifiées, AUTO24 Benin, Shopping de Voitures, Voitures Abordables Benin",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.bj",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.bj ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywords:
                    "Vendre Voiture Benin, Vente de Voitures d'Occasion Benin, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Benin",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.bj",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.bj ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywordsOg:
                    "Vendre Voiture Benin, Vente de Voitures d'Occasion Benin, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Benin",
                aboutPageTitle:
                    "À Propos de  AUTO24.bj - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Benin",
                aboutPageDesc:
                    "Découvrez AUTO24.bj , le leader en matière de voitures d'occasion certifiées à Benin. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywords:
                    "À Propos de AUTO24 Benin, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Benin",
                aboutPageTitleOg:
                    "À Propos de  AUTO24.bj - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Benin",
                aboutPageDescOg:
                    "Découvrez AUTO24.bj , le leader en matière de voitures d'occasion certifiées à Benin. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywordsOg:
                    "À Propos de AUTO24 Benin, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Benin",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.bj',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.bj - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    'Financement de Voiture, Auto Crédit, AUTO24 Benin, Options de Financement, Prêt Auto',
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.bj',
                financePageDescOg:
                    "Explorez un financement automobile sans tracas avec Auto24.bj - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywordsOg:
                    'Financement de Voiture, Auto Crédit, AUTO24 Benin, Options de Financement, Prêt Auto',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.bj',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.bj. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Benin, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.bj',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.bj. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Benin, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.bj",
                blogPageDesc:
                    "Visitez Auto24.bj pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywords:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Benin, Astuces Voiture',
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.bj",
                blogPageDescOg:
                    "Visitez Auto24.bj pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywordsOg:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Benin, Astuces Voiture',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24, Benin',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Benin en Benin. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Bénin | Obtenez le meilleur prix sur AUTO24 Bénin',
                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Bénin | Obtenez le meilleur prix sur AUTO24 Bénin',
                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Bénin | Obtenez le meilleur prix sur AUTO24 Bénin',
                carsUnderPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.bj. Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie au Bénin.",
                fuelPriceTrackerTitle:
                    'Derniers prix du carburant au Benin - AUTO24.bj',
                fuelPriceTrackerDescMeta:
                    "Restez informé des derniers prix du carburant à travers le Benin. Consultez les tarifs actuels de l'essence, du diesel et du GPL",
                fuelPriceTrackerKeywords:
                    'Derniers prix du carburant au Benin, actualités sur le carburant au Benin, suivi des prix du carburant, comparaison des prix du carburant, prix du carburant au Benin 2025',
                electricityPriceTrackerTitle:
                    "Derniers prix de l'électricité en Benin - AUTO24.bj",
                electricityPriceTrackerDescMeta:
                    "Restez informé des derniers prix de l'électricité en Benin. Suivez les tarifs de l'électricité, surveillez les fluctuations des prix et gérez efficacement vos coûts énergétiques.",
                electricityPriceTrackerKeywords:
                    "Derniers prix de l'électricité en Benin, mises à jour des tarifs de l'électricité, suivi des prix de l'électricité, comparaison des coûts énergétiques, tarifs de l'électricité en Benin 2025",
                currencyConverterTitle:
                    'Convertisseur de devises - Taux de change en direct aujourd’hui en {{ Country_name }} - Auto24.bj',

                currencyConverterDescMeta:
                    'Calculez les taux de change et de devises en temps réel avec le Convertisseur de devises Auto24 à {{ Country_name }}. Convertissez entre toutes les principales devises mondiales avec cet outil et consultez les taux du marché en direct.',

                currencyConverterKeywords:
                    'Taux de change en temps réel, Calculateur Forex, Taux de change des devises, Convertisseur de devises AUTO 24, Meilleurs taux de change, Taux Forex, Outil de conversion de devises à {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.bj',
                distanceConverterDescMeta:
                    '{{ title }} en {{ Country_name }}. {{ howToConvert }} facilement',
                distanceConverterKeywords:
                    '{{ title }}. Convertir {{ op1 }} et {{ op2 }} en {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Calculateur de Coûts de Conduite | Estimez vos Dépenses de Carburant pour Tout Trajet en {{ Country_name }} - Auto24.bj',
                drivingCostCalculatorDescMeta:
                    'Calculez vos coûts de conduite en {{ Country_name }} avec Auto24.bj. Estimez les dépenses de carburant selon le prix du carburant, la distance et la consommation du véhicule. Planifiez vos trajets intelligemment et économisez sur les coûts de carburant !',
                drivingCostCalculatorKeywords:
                    'Calculateur de coûts de conduite, Estimateur de coûts de carburant, Calculateur de coûts de trajet, Calculateur de consommation de carburant, Calculateur de prix de carburant, Calculateur de coûts de voyage Auto24, Prix du carburant en {{ Country_name }}, Dépenses de voyage en voiture {{ Country_name }}',
                emissionCalculatorTitle:
                    "Calculateur d'Émissions CO2 à {{ Country_name }} – Mesurez Votre Empreinte Carbone - Auto24.bj",
                emissionCalculatorDescMeta:
                    "Utilisez notre calculateur d'émissions CO2 à {{ Country_name }} pour estimer votre empreinte carbone. Calculez les émissions liées aux transports, à la consommation d'énergie et aux activités quotidiennes pour réduire votre impact environnemental.",
                emissionCalculatorKeywords:
                    "Calculateur d'émissions CO2 {{ Country_name }}, calculateur d'empreinte carbone {{ Country_name }}, émissions de gaz à effet de serre {{ Country_name }}, suivi CO2 {{ Country_name }}, réduction des émissions {{ Country_name }}",
                bestSellerTitle:
                    'Voitures les plus vendues de {{ CurrentYear }} au Bénin - Auto24.bj',
                bestSellerDescMeta:
                    "Découvrez les voitures les plus vendues de {{ CurrentYear }} au Bénin. Explorez les véhicules les mieux notés en fonction des ventes, des performances et des avis des clients. Trouvez votre prochaine voiture avec Auto24.bj aujourd'hui !",
                bestSellerKeywords:
                    'Voitures les plus vendues {{ CurrentYear }}, Meilleures voitures {{ CurrentYear }}, voitures populaires {{ CurrentYear }}, Meilleures voitures au Bénin, Voitures les plus vendues au Bénin, Classements des voitures de {{ CurrentYear }}, Meilleurs véhicules au Bénin, Marché automobile {{ CurrentYear }}, Données de ventes de voitures au Bénin',
                carLoanCalculatorTitle:
                    'Calculateur de prêt automobile au Bénin - Estimez vos paiements mensuels et intérêts - Auto24.bj',
                carLoanCalculatorDescMeta:
                    'Utilisez notre calculateur de prêt automobile pour estimer vos paiements mensuels au Bénin, les intérêts totaux et les coûts globaux du prêt. Planifiez votre prochain achat de voiture au Bénin en toute confiance et trouvez le meilleur prêt pour votre budget',
                carLoanCalculatorKeywords:
                    'Calculateur de prêt automobile, Calculateur de prêt auto, Financement de véhicule, Intérêt sur prêt automobile, Paiement mensuel de voiture, Remboursement de prêt, Outil de financement automobile, EMI de prêt automobile, Estimateur de prêt automobile',
                techDocsTitle:
                    "Documents Techniques de Véhicules - AUTO24.bj | Voitures d'Occasion Certifiées au Bénin",
                techDocsDesc:
                    "Explorez une collection complète de documents techniques pour les voitures d'occasion certifiées sur AUTO24.bj. Trouvez des spécifications, des manuels et des guides pour vous aider à prendre des décisions éclairées au Bénin.",
                techDocsKeyword:
                    "documents techniques, manuels de voiture, voitures d'occasion, AUTO24 Bénin, spécifications de voiture, guides de véhicule"
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            return {
                homePageTitle:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDesc:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                homePageTitleOg:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDescOg:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                buyPageTitle:
                    "Voitures à vendre au Maroc | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ma",
                buyPageDesc:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywords:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                buyPageTitleOg:
                    "Voitures à vendre au Maroc | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ma",
                buyPageDescOg:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywordsOg:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ma",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.ma! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywords:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ma",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.ma! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywordsOg:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                aboutPageTitle:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDesc:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywords:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                aboutPageTitleOg:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDescOg:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywordsOg:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ma',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.ma - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ma',
                financePageDescOg:
                    "Découvrez des options de financement flexibles pour votre voiture d'occasion avec AUTO24.ma . Simplifiez l'achat de voitures dans tout le Maroc.",
                financePageKeywordsOg:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ma',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ma. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ma',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ma. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ma",
                blogPageDesc:
                    "Visitez Auto24.ma pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywords:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ma",
                blogPageDescOg:
                    "Visitez Auto24.ma pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywordsOg:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                requestCarPageTitle:
                    'Trouvez la voiture de vos rêves | Demande de la voiture que vous voulez -auto24.ma',
                requestCarPageDesc:
                    'Soumettez une demande et nous vous aiderons à trouver la voiture parfaite adaptée à vos besoins.',
                requestCarPageKeywords:
                    'Trouver une voiture, soumettre une demande, Auto 24 Maroc',
                requestCarPageTitleOg:
                    'Trouvez la voiture de vos rêves | Demande de la voiture que vous voulez -auto24.ma',
                requestCarPageDescOg:
                    'Soumettez une demande et nous vous aiderons à trouver la voiture parfaite adaptée à vos besoins.',
                requestCarPageKeywordsOg:
                    'Trouver une voiture, soumettre une demande, Auto 24 Maroc',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} DH avec AUTO24, Maroc',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Maroc. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',

                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',

                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',
                carsUnderPageDesc:
                    "Découvrez une large sélection de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.ma. Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                fuelPriceTrackerTitle:
                    'Derniers prix du carburant au Maroc - AUTO24.ma',
                fuelPriceTrackerDescMeta:
                    "Restez informé des derniers prix du carburant à travers le Maroc. Consultez les tarifs actuels de l'essence, du diesel et du GPL",
                fuelPriceTrackerKeywords:
                    'Derniers prix du carburant au Maroc, actualités sur le carburant au Maroc, suivi des prix du carburant, comparaison des prix du carburant, prix du carburant au Maroc 2025',
                electricityPriceTrackerTitle:
                    "Derniers prix de l'électricité en Maroc - AUTO24.ma",
                electricityPriceTrackerDescMeta:
                    "Restez informé des derniers prix de l'électricité en Maroc. Suivez les tarifs de l'électricité, surveillez les fluctuations des prix et gérez efficacement vos coûts énergétiques.",
                electricityPriceTrackerKeywords:
                    "Derniers prix de l'électricité en Maroc, mises à jour des tarifs de l'électricité, suivi des prix de l'électricité, comparaison des coûts énergétiques, tarifs de l'électricité en Maroc 2025",
                currencyConverterTitle:
                    'Convertisseur de devises - Taux de change en direct aujourd’hui en {{ Country_name }} - Auto24.ma',

                currencyConverterDescMeta:
                    'Calculez les taux de change et de devises en temps réel avec le Convertisseur de devises Auto24 à {{ Country_name }}. Convertissez entre toutes les principales devises mondiales avec cet outil et consultez les taux du marché en direct.',

                currencyConverterKeywords:
                    'Taux de change en temps réel, Calculateur Forex, Taux de change des devises, Convertisseur de devises AUTO 24, Meilleurs taux de change, Taux Forex, Outil de conversion de devises à {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.ma',
                distanceConverterDescMeta:
                    '{{ title }} en {{ Country_name }}. {{ howToConvert }} facilement',
                distanceConverterKeywords:
                    '{{ title }}. Convertir {{ op1 }} et {{ op2 }} en {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Calculateur de Coûts de Conduite | Estimez vos Dépenses de Carburant pour Tout Trajet en {{ Country_name }} - Auto24.ma',
                drivingCostCalculatorDescMeta:
                    'Calculez vos coûts de conduite en {{ Country_name }} avec Auto24.ma. Estimez les dépenses de carburant selon le prix du carburant, la distance et la consommation du véhicule. Planifiez vos trajets intelligemment et économisez sur les coûts de carburant !',
                drivingCostCalculatorKeywords:
                    'Calculateur de coûts de conduite, Estimateur de coûts de carburant, Calculateur de coûts de trajet, Calculateur de consommation de carburant, Calculateur de prix de carburant, Calculateur de coûts de voyage Auto24, Prix du carburant en {{ Country_name }}, Dépenses de voyage en voiture {{ Country_name }}',
                emissionCalculatorTitle:
                    "Calculateur d'Émissions CO2 à {{ Country_name }} – Mesurez Votre Empreinte Carbone - Auto24.ma",
                emissionCalculatorDescMeta:
                    "Utilisez notre calculateur d'émissions CO2 à {{ Country_name }} pour estimer votre empreinte carbone. Calculez les émissions liées aux transports, à la consommation d'énergie et aux activités quotidiennes pour réduire votre impact environnemental.",
                emissionCalculatorKeywords:
                    "Calculateur d'émissions CO2 {{ Country_name }}, calculateur d'empreinte carbone {{ Country_name }}, émissions de gaz à effet de serre {{ Country_name }}, suivi CO2 {{ Country_name }}, réduction des émissions {{ Country_name }}",
                bestSellerTitle:
                    'Voitures les plus vendues de {{ CurrentYear }} au Maroc - Auto24.ma',
                bestSellerDescMeta:
                    "Découvrez les voitures les plus vendues de {{ CurrentYear }} au Maroc. Explorez les véhicules les mieux notés en fonction des ventes, des performances et des avis des clients. Trouvez votre prochaine voiture avec Auto24.ma aujourd'hui !",
                bestSellerKeywords:
                    'Voitures les plus vendues {{ CurrentYear }}, Meilleures voitures {{ CurrentYear }}, voitures populaires {{ CurrentYear }}, Meilleures voitures au Maroc, Voitures les plus vendues au Maroc, Classements des voitures de {{ CurrentYear }}, Meilleurs véhicules au Maroc, Marché automobile {{ CurrentYear }}, Données de ventes de voitures au Maroc',
                carLoanCalculatorTitle:
                    'Calculateur de prêt automobile au Maroc - Estimez vos paiements mensuels et intérêts - Auto24.ma',
                carLoanCalculatorDescMeta:
                    'Utilisez notre calculateur de prêt automobile pour estimer vos paiements mensuels au Maroc, les intérêts totaux et les coûts globaux du prêt. Planifiez votre prochain achat de voiture au Maroc en toute confiance et trouvez le meilleur prêt pour votre budget',
                carLoanCalculatorKeywords:
                    'Calculateur de prêt automobile, Calculateur de prêt auto, Financement de véhicule, Intérêt sur prêt automobile, Paiement mensuel de voiture, Remboursement de prêt, Outil de financement automobile, EMI de prêt automobile, Estimateur de prêt automobile',
                techDocsTitle:
                    "Documents Techniques de Véhicules - AUTO24.ma | Voitures d'Occasion Certifiées au Maroc",
                techDocsDesc:
                    "Explorez une collection complète de documents techniques pour les voitures d'occasion certifiées sur AUTO24.ma. Trouvez des spécifications, des manuels et des guides pour vous aider à prendre des décisions éclairées au Maroc.",
                techDocsKeyword:
                    "documents techniques, manuels de voiture, voitures d'occasion, AUTO24 Maroc, spécifications de voiture, guides de véhicule"
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.ci:
            return {
                homePageTitle:
                    "AUTO24.ci - Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire | Achetez et Vendez Localement",
                homePageDesc:
                    "À la recherche d'une voiture d'occasion certifiée à Abidjan, Côte d'Ivoire? AUTO24.ci est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Abidjan, Vendre Voiture Côte d'Ivoire, Marché Automobile, AUTO24 Côte d'Ivoire, Voitures de Qualité, Vente Auto Côte d'Ivoire",
                homePageTitleOg:
                    "AUTO24.ci - Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire | Achetez et Vendez Localement",
                homePageDescOg:
                    "À la recherche d'une voiture d'occasion certifiée à Abidjan, Côte d'Ivoire? AUTO24.ci est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Profitez d'évaluations gratuites, de ventes rapides et d'une certification complète.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Abidjan, Vendre Voiture Côte d'Ivoire, Marché Automobile, AUTO24 Côte d'Ivoire, Voitures de Qualité, Vente Auto Côte d'Ivoire",
                buyPageTitle:
                    "Voitures à vendre au Côte d'Ivoire | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ci",
                buyPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées sur AUTO24.ci . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Côte d'Ivoire.",
                buyPageKeywords:
                    "Acheter Voitures Abidjan, Voitures d'Occasion Certifiées, AUTO24 Côte d'Ivoire, Shopping de Voitures, Voitures Abordables Côte d'Ivoire",
                buyPageTitleOg:
                    "Voitures à vendre au Côte d'Ivoire | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ci",
                buyPageDescOg:
                    "Parcourez une large sélection de voitures d'occasion certifiées sur AUTO24.ci . Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Côte d'Ivoire.",
                buyPageKeywordsOg:
                    "Acheter Voitures Abidjan, Voitures d'Occasion Certifiées, AUTO24 Côte d'Ivoire, Shopping de Voitures, Voitures Abordables Côte d'Ivoire",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ci",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.ci ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywords:
                    "Vendre Voiture Abidjan, Vente de Voitures d'Occasion Côte d'Ivoire, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Côte d'Ivoire",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ci",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.ci ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywordsOg:
                    "Vendre Voiture Abidjan, Vente de Voitures d'Occasion Côte d'Ivoire, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Côte d'Ivoire",
                aboutPageTitle:
                    "À Propos de  AUTO24.ci - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire",
                aboutPageDesc:
                    "Découvrez AUTO24.ci , le leader en matière de voitures d'occasion certifiées à Abidjan, Côte d'Ivoire. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywords:
                    "À Propos de AUTO24 Côte d'Ivoire, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Abidjan",
                aboutPageTitleOg:
                    "À Propos de  AUTO24.ci - Votre Plateforme Locale de Voitures d'Occasion Certifiées à Abidjan, Côte d'Ivoire",
                aboutPageDescOg:
                    "Découvrez AUTO24.ci , le leader en matière de voitures d'occasion certifiées à Abidjan, Côte d'Ivoire. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides pour nos clients locaux.",
                aboutPageKeywordsOg:
                    "À Propos de AUTO24 Côte d'Ivoire, Plateforme de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire à Abidjan",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ci',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.ci - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    "Financement de Voiture, Auto Crédit, AUTO24 Côte d'Ivoire, Options de Financement, Prêt Auto",
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ci',
                financePageDescOg:
                    "Explorez un financement automobile sans tracas avec Auto24.ci - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywordsOg:
                    "Financement de Voiture, Auto Crédit, AUTO24 Côte d'Ivoire, Options de Financement, Prêt Auto",
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ci',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ci. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Côte d'Ivoire, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ci',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ci. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Côte d'Ivoire, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ci",
                blogPageDesc:
                    "Visitez Auto24.ci pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywords:
                    "Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Côte d'Ivoire, Astuces Voiture",
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ci",
                blogPageDescOg:
                    "Visitez Auto24.ci pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywordsOg:
                    "Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Côte d'Ivoire, Astuces Voiture",
                requestCarPageTitle:
                    'Trouvez la voiture de vos rêves | Demande de la voiture que vous voulez -auto24.ci',
                requestCarPageDesc:
                    'Soumettez une demande et nous vous aiderons à trouver la voiture parfaite adaptée à vos besoins.',
                requestCarPageKeywords:
                    "Trouver une voiture, soumettre une demande, Auto 24 Côte d'Ivoire",
                requestCarPageTitleOg:
                    'Trouvez la voiture de vos rêves | Demande de la voiture que vous voulez -auto24.ci',
                requestCarPageDescOg:
                    'Soumettez une demande et nous vous aiderons à trouver la voiture parfaite adaptée à vos besoins.',
                requestCarPageKeywordsOg:
                    "Trouver une voiture, soumettre une demande, Auto 24 Côte d'Ivoire",
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24, Abidjan, Côte d’Ivoire',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Abidjan en Côte d’Ivoire. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    "Voitures {{ BRAND }} à vendre en Côte d'Ivoire | Obtenez le meilleur prix sur AUTO24 Côte d'Ivoire",

                bodyTypePageTitle:
                    "Voitures {{ BODY_TYPE }} à vendre en Côte d'Ivoire | Obtenez le meilleur prix sur AUTO24 Côte d'Ivoire",

                carsUnderPageTitle:
                    "Achetez des voitures à moins de {{ PRICE }} en Côte d'Ivoire | Obtenez le meilleur prix sur AUTO24 Côte d'Ivoire",
                carsUnderPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.ci. Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Côte d'Ivoire.",
                fuelPriceTrackerTitle:
                    "Derniers prix du carburant au Côte d'Ivoire - AUTO24.ci",
                fuelPriceTrackerDescMeta:
                    "Restez informé des derniers prix du carburant à travers le Côte d'Ivoire. Consultez les tarifs actuels de l'essence, du diesel et du GPL",
                fuelPriceTrackerKeywords:
                    "Derniers prix du carburant au Côte d'Ivoire, actualités sur le carburant au Côte d'Ivoire, suivi des prix du carburant, comparaison des prix du carburant, prix du carburant au Côte d'Ivoire 2025",
                electricityPriceTrackerTitle:
                    "Derniers prix de l'électricité en Côte d'Ivoir - AUTO24.ci",
                electricityPriceTrackerDescMeta:
                    "Restez informé des derniers prix de l'électricité en Côte d'Ivoir. Suivez les tarifs de l'électricité, surveillez les fluctuations des prix et gérez efficacement vos coûts énergétiques.",
                electricityPriceTrackerKeywords:
                    "Derniers prix de l'électricité en Côte d'Ivoir, mises à jour des tarifs de l'électricité, suivi des prix de l'électricité, comparaison des coûts énergétiques, tarifs de l'électricité en Côte d'Ivoir 2025",
                currencyConverterTitle:
                    'Convertisseur de devises - Taux de change en direct aujourd’hui en {{ Country_name }} - Auto24.ci',

                currencyConverterDescMeta:
                    'Calculez les taux de change et de devises en temps réel avec le Convertisseur de devises Auto24 à {{ Country_name }}. Convertissez entre toutes les principales devises mondiales avec cet outil et consultez les taux du marché en direct.',

                currencyConverterKeywords:
                    'Taux de change en temps réel, Calculateur Forex, Taux de change des devises, Convertisseur de devises AUTO 24, Meilleurs taux de change, Taux Forex, Outil de conversion de devises à {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.ci',
                distanceConverterDescMeta:
                    '{{ title }} en {{ Country_name }}. {{ howToConvert }} facilement',
                distanceConverterKeywords:
                    '{{ title }}. Convertir {{ op1 }} et {{ op2 }} en {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Calculateur de Coûts de Conduite | Estimez vos Dépenses de Carburant pour Tout Trajet en {{ Country_name }} - Auto24.ci',
                drivingCostCalculatorDescMeta:
                    'Calculez vos coûts de conduite en {{ Country_name }} avec Auto24.ci. Estimez les dépenses de carburant selon le prix du carburant, la distance et la consommation du véhicule. Planifiez vos trajets intelligemment et économisez sur les coûts de carburant !',
                drivingCostCalculatorKeywords:
                    'Calculateur de coûts de conduite, Estimateur de coûts de carburant, Calculateur de coûts de trajet, Calculateur de consommation de carburant, Calculateur de prix de carburant, Calculateur de coûts de voyage Auto24, Prix du carburant en {{ Country_name }}, Dépenses de voyage en voiture {{ Country_name }}',
                emissionCalculatorTitle:
                    "Calculateur d'Émissions CO2 à {{ Country_name }} – Mesurez Votre Empreinte Carbone - Auto24.ci",
                emissionCalculatorDescMeta:
                    "Utilisez notre calculateur d'émissions CO2 à {{ Country_name }} pour estimer votre empreinte carbone. Calculez les émissions liées aux transports, à la consommation d'énergie et aux activités quotidiennes pour réduire votre impact environnemental.",
                emissionCalculatorKeywords:
                    "Calculateur d'émissions CO2 {{ Country_name }}, calculateur d'empreinte carbone {{ Country_name }}, émissions de gaz à effet de serre {{ Country_name }}, suivi CO2 {{ Country_name }}, réduction des émissions {{ Country_name }}",
                bestSellerTitle:
                    "Voitures les plus vendues de {{ CurrentYear }} en Côte d'Ivoire - Auto24.ci",
                bestSellerDescMeta:
                    "Découvrez les voitures les plus vendues de {{ CurrentYear }} en Côte d'Ivoire. Explorez les véhicules les mieux notés en fonction des ventes, des performances et des avis des clients. Trouvez votre prochaine voiture avec Auto24.ci aujourd'hui !",
                bestSellerKeywords:
                    "Voitures les plus vendues {{ CurrentYear }}, Meilleures voitures {{ CurrentYear }}, voitures populaires {{ CurrentYear }}, Meilleures voitures en Côte d'Ivoire, Voitures les plus vendues en Côte d'Ivoire, Classements des voitures de {{ CurrentYear }}, Meilleurs véhicules en Côte d'Ivoire, Marché automobile {{ CurrentYear }}, Données de ventes de voitures en Côte d'Ivoire",
                carLoanCalculatorTitle:
                    "Calculateur de prêt automobile en Côte d'Ivoire - Estimez vos paiements mensuels et intérêts - Auto24.ci",
                carLoanCalculatorDescMeta:
                    "Utilisez notre calculateur de prêt automobile pour estimer vos paiements mensuels en Côte d'Ivoire, les intérêts totaux et les coûts globaux du prêt. Planifiez votre prochain achat de voiture en Côte d'Ivoire en toute confiance et trouvez le meilleur prêt pour votre budget",
                carLoanCalculatorKeywords:
                    'Calculateur de prêt automobile, Calculateur de prêt auto, Financement de véhicule, Intérêt sur prêt automobile, Paiement mensuel de voiture, Remboursement de prêt, Outil de financement automobile, EMI de prêt automobile, Estimateur de prêt automobile',
                techDocsTitle:
                    "Documents Techniques de Véhicules - AUTO24.ci | Voitures d'Occasion Certifiées en Côte d'Ivoire",
                techDocsDesc:
                    "Explorez une collection complète de documents techniques pour les voitures d'occasion certifiées sur AUTO24.ci. Trouvez des spécifications, des manuels et des guides pour vous aider à prendre des décisions éclairées en Côte d'Ivoire.",
                techDocsKeyword:
                    "documents techniques, manuels de voiture, voitures d'occasion, AUTO24 Côte d'Ivoire, spécifications de voiture, guides de véhicule"
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.rw:
            return {
                homePageTitle:
                    "AUTO24.rw - Voitures d'occasion certifiées à Kigali, Rwanda | Achetez et vendez localement",
                homePageDesc:
                    "Votre marché local à Kigali pour l'achat et la vente de voitures d'occasion certifiées. Profitez d'évaluations gratuites, de transactions rapides, et de voitures de qualité.",
                homePageKeywords:
                    "Voitures d'occasion certifiées, Acheter des voitures à Kigali, Vendre des voitures au Rwanda, AUTO24 Rwanda, Voitures de qualité à Kigali, Marché automobile au Rwanda",
                homePageTitleOg:
                    "AUTO24.rw - Voitures d'occasion certifiées à Kigali, Rwanda | Achetez et vendez localement",
                homePageDescOg:
                    "Votre marché local à Kigali pour l'achat et la vente de voitures d'occasion certifiées. Profitez d'évaluations gratuites, de transactions rapides, et de voitures de qualité.",
                homePageKeywordsOg:
                    "Voitures d'occasion certifiées, Acheter des voitures à Kigali, Vendre des voitures au Rwanda, AUTO24 Rwanda, Voitures de qualité à Kigali, Marché automobile au Rwanda",
                buyPageTitle:
                    "Voitures à vendre au Kigali | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.rw",
                buyPageDesc:
                    "Parcourez une large gamme de voitures d'occasion certifiées sur AUTO24.rw . Simplifiez votre recherche pour la voiture idéale à Kigali.",
                buyPageKeywords:
                    "Acheter des voitures au Rwanda, Voitures d'occasion certifiées, AUTO24 Rwanda, Shopping de voiture, Voitures abordables à Kigali",
                buyPageTitleOg:
                    "Voitures à vendre au Kigali | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.rw",
                buyPageDescOg:
                    "Parcourez une large gamme de voitures d'occasion certifiées sur AUTO24.rw . Simplifiez votre recherche pour la voiture idéale à Kigali.",
                buyPageKeywordsOg:
                    "Acheter des voitures au Rwanda, Voitures d'occasion certifiées, AUTO24 Rwanda, Shopping de voiture, Voitures abordables à Kigali",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.rw",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.rw ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywords:
                    "Vendre une voiture au Rwanda, Ventes de voitures d'occasion à Kigali, Ventes de voitures rapides, AUTO24 Vendez votre voiture, Évaluation de voiture à Kigali",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.rw",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.rw ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywordsOg:
                    "Vendre une voiture au Rwanda, Ventes de voitures d'occasion à Kigali, Ventes de voitures rapides, AUTO24 Vendez votre voiture, Évaluation de voiture à Kigali",
                aboutPageTitle:
                    "À propos d'AUTO24.rw - Votre marché de voitures d'occasion de confiance à Kigali",
                aboutPageDesc:
                    "Découvrez AUTO24.rw , le principal marché de voitures d'occasion certifiées à Kigali. Nous offrons des voitures de qualité et une expérience d'achat et de vente transparente.",
                aboutPageKeywords:
                    "À propos d'AUTO24 Rwanda, Marché de voitures d'occasion certifiées, Ventes de voitures de confiance, Concessionnaire au Rwanda",
                aboutPageTitleOg:
                    "À propos d'AUTO24.rw - Votre marché de voitures d'occasion de confiance à Kigali",
                aboutPageDescOg:
                    "Découvrez AUTO24.rw , le principal marché de voitures d'occasion certifiées à Kigali. Nous offrons des voitures de qualité et une expérience d'achat et de vente transparente.",
                aboutPageKeywordsOg:
                    "À propos d'AUTO24 Rwanda, Marché de voitures d'occasion certifiées, Ventes de voitures de confiance, Concessionnaire au Rwanda",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.rw',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.rw - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    'Financement de voiture au Rwanda, AUTO24 Financement, Prêts de voiture abordables, Financement de voiture à Kigali',
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.rw',
                financePageDescOg:
                    "Explorez un financement automobile sans tracas avec Auto24.rw - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywordsOg:
                    'Financement de voiture au Rwanda, AUTO24 Financement, Prêts de voiture abordables, Financement de voiture à Kigali',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.rw',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.rw. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    'Assurance voiture au Rwanda, AUTO24 Assurance, Assurance véhicule à Kigali, Assurance abordable',
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.rw',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.rw. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    'Assurance voiture au Rwanda, AUTO24 Assurance, Assurance véhicule à Kigali, Assurance abordable',
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.rw",
                blogPageDesc:
                    "Visitez Auto24.rw pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywords:
                    "Blog d'AUTO24, Conseils de voiture au Rwanda, Actualités automobile au Rwanda, Perspectives sur les voitures d'occasion, Marché de la voiture au Rwanda",
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.rw",
                blogPageDescOg:
                    "Visitez Auto24.rw pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywordsOg:
                    "Blog d'AUTO24, Conseils de voiture au Rwanda, Actualités automobile au Rwanda, Perspectives sur les voitures d'occasion, Marché de la voiture au Rwanda",
                requestCarPageTitle:
                    'Trouvez la voiture de vos rêves | Demande de la voiture que vous voulez -auto24.rw',
                requestCarPageDesc:
                    'Soumettez une demande et nous vous aiderons à trouver la voiture parfaite adaptée à vos besoins.',
                requestCarPageKeywords:
                    'Trouver une voiture, soumettre une demande, Auto 24 Rwanda',
                requestCarPageTitleOg:
                    'Trouvez la voiture de vos rêves | Demande de la voiture que vous voulez -auto24.rw',
                requestCarPageDescOg:
                    'Soumettez une demande et nous vous aiderons à trouver la voiture parfaite adaptée à vos besoins.',
                requestCarPageKeywordsOg:
                    'Trouver une voiture, soumettre une demande, Auto 24 Rwanda',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24,Rwanda',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Rwanda. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Rwanda | Obtenez le meilleur prix sur AUTO24 Rwanda',

                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Rwanda | Obtenez le meilleur prix sur AUTO24 Rwanda',

                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Rwanda | Obtenez le meilleur prix sur AUTO24 Rwanda',
                carsUnderPageDesc:
                    "Parcourez une large gamme de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.rw. Simplifiez votre recherche pour la voiture idéale à Kigali.",
                fuelPriceTrackerTitle:
                    'Derniers prix du carburant au Rwanda - AUTO24.rw',
                fuelPriceTrackerDescMeta:
                    "Restez informé des derniers prix du carburant à travers le Rwanda. Consultez les tarifs actuels de l'essence, du diesel et du GPL",
                fuelPriceTrackerKeywords:
                    'Derniers prix du carburant au Rwanda, actualités sur le carburant au Rwanda, suivi des prix du carburant, comparaison des prix du carburant, prix du carburant au Rwanda 2025',
                electricityPriceTrackerTitle:
                    "Derniers prix de l'électricité en Rwanda - AUTO24.rw",
                electricityPriceTrackerDescMeta:
                    "Restez informé des derniers prix de l'électricité en Rwanda. Suivez les tarifs de l'électricité, surveillez les fluctuations des prix et gérez efficacement vos coûts énergétiques.",
                electricityPriceTrackerKeywords:
                    "Derniers prix de l'électricité en Rwanda, mises à jour des tarifs de l'électricité, suivi des prix de l'électricité, comparaison des coûts énergétiques, tarifs de l'électricité en Rwanda 2025",
                currencyConverterTitle:
                    'Convertisseur de devises - Taux de change en direct aujourd’hui en {{ Country_name }} - Auto24.rw',

                currencyConverterDescMeta:
                    'Calculez les taux de change et de devises en temps réel avec le Convertisseur de devises Auto24 à {{ Country_name }}. Convertissez entre toutes les principales devises mondiales avec cet outil et consultez les taux du marché en direct.',

                currencyConverterKeywords:
                    'Taux de change en temps réel, Calculateur Forex, Taux de change des devises, Convertisseur de devises AUTO 24, Meilleurs taux de change, Taux Forex, Outil de conversion de devises à {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.rw',
                distanceConverterDescMeta:
                    '{{ title }} en {{ Country_name }}. {{ howToConvert }} facilement',
                distanceConverterKeywords:
                    '{{ title }}. Convertir {{ op1 }} et {{ op2 }} en {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Calculateur de Coûts de Conduite | Estimez vos Dépenses de Carburant pour Tout Trajet en {{ Country_name }} - Auto24.rw',
                drivingCostCalculatorDescMeta:
                    'Calculez vos coûts de conduite en {{ Country_name }} avec Auto24.rw. Estimez les dépenses de carburant selon le prix du carburant, la distance et la consommation du véhicule. Planifiez vos trajets intelligemment et économisez sur les coûts de carburant !',
                drivingCostCalculatorKeywords:
                    'Calculateur de coûts de conduite, Estimateur de coûts de carburant, Calculateur de coûts de trajet, Calculateur de consommation de carburant, Calculateur de prix de carburant, Calculateur de coûts de voyage Auto24, Prix du carburant en {{ Country_name }}, Dépenses de voyage en voiture {{ Country_name }}',
                emissionCalculatorTitle:
                    "Calculateur d'Émissions CO2 à {{ Country_name }} – Mesurez Votre Empreinte Carbone - Auto24.rw",
                emissionCalculatorDescMeta:
                    "Utilisez notre calculateur d'émissions CO2 à {{ Country_name }} pour estimer votre empreinte carbone. Calculez les émissions liées aux transports, à la consommation d'énergie et aux activités quotidiennes pour réduire votre impact environnemental.",
                emissionCalculatorKeywords:
                    "Calculateur d'émissions CO2 {{ Country_name }}, calculateur d'empreinte carbone {{ Country_name }}, émissions de gaz à effet de serre {{ Country_name }}, suivi CO2 {{ Country_name }}, réduction des émissions {{ Country_name }}",
                bestSellerTitle:
                    'Voitures les plus vendues de {{ CurrentYear }} au Rwanda - Auto24.rw',
                bestSellerDescMeta:
                    "Découvrez les voitures les plus vendues de {{ CurrentYear }} au Rwanda. Explorez les véhicules les mieux notés en fonction des ventes, des performances et des avis des clients. Trouvez votre prochaine voiture avec Auto24.rw aujourd'hui !",
                bestSellerKeywords:
                    'Voitures les plus vendues {{ CurrentYear }}, Meilleures voitures {{ CurrentYear }}, voitures populaires {{ CurrentYear }}, Meilleures voitures au Rwanda, Voitures les plus vendues au Rwanda, Classements des voitures de {{ CurrentYear }}, Meilleurs véhicules au Rwanda, Marché automobile {{ CurrentYear }}, Données de ventes de voitures au Rwanda',
                carLoanCalculatorTitle:
                    'Calculateur de prêt automobile au Rwanda - Estimez vos paiements mensuels et intérêts - Auto24.rw',
                carLoanCalculatorDescMeta:
                    'Utilisez notre calculateur de prêt automobile pour estimer vos paiements mensuels au Rwanda, les intérêts totaux et les coûts globaux du prêt. Planifiez votre prochain achat de voiture au Rwanda en toute confiance et trouvez le meilleur prêt pour votre budget',
                carLoanCalculatorKeywords:
                    'Calculateur de prêt automobile, Calculateur de prêt auto, Financement de véhicule, Intérêt sur prêt automobile, Paiement mensuel de voiture, Remboursement de prêt, Outil de financement automobile, EMI de prêt automobile, Estimateur de prêt automobile',
                techDocsTitle:
                    "Documents Techniques de Véhicules - AUTO24.rw | Voitures d'Occasion Certifiées au Rwanda",
                techDocsDesc:
                    "Explorez une collection complète de documents techniques pour les voitures d'occasion certifiées sur AUTO24.rw. Trouvez des spécifications, des manuels et des guides pour vous aider à prendre des décisions éclairées au Rwanda.",
                techDocsKeyword:
                    "documents techniques, manuels de voiture, voitures d'occasion, AUTO24 Rwanda, spécifications de voiture, guides de véhicule"
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.sn:
            return {
                homePageTitle:
                    "Voitures d'Occasion Certifiées à Dakar, Sénégal | Achetez et Vendez Localement",
                // "Voitures d'occasion certifiées en Senegal avec AUTO24",
                homePageDesc: `Vous cherchez une voiture d'occasion certifiée à Dakar, Sénégal? AUTO24.sn est votre plateforme locale pour acheter et vendre des voitures d'occasion de qualité. Nous offrons des évaluations gratuites, des ventes rapides et une certification complète.`,
                // 'Le prix de l’occasion avec le service du neuf ! Achetez des voitures d’occasion reconditionnées et certifiées par nos experts avec assurance et garantie.',
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.sn",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture ? Obtenez une offre en espèces immédiate sur auto24.ma ! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                buyPageTitle:
                    "Voitures à vendre au Senegal | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.sn",
                buyPageDesc:
                    'Les meilleurs prix de voitures d’occasion à Senegal avec 100 points d’inspection, 5 jours satisfait ou remboursé, 6 mois de garantie et plus.',
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.sn',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.sn - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                aboutPageTitle:
                    'AUTO24, une nouvelle façon d’acheter et vendre des voitures en Afrique',
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.sn",
                blogPageDesc:
                    "Visitez Auto24.sn pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                requestCarPageTitle:
                    'Trouvez la voiture de vos rêves | Demande de la voiture que vous voulez -auto24.sn',
                requestCarPageDesc:
                    'Soumettez une demande et nous vous aiderons à trouver la voiture parfaite adaptée à vos besoins.',
                requestCarPageKeywords:
                    'Trouver une voiture, soumettre une demande, Auto 24 Senegal',
                requestCarPageTitleOg:
                    'Trouvez la voiture de vos rêves | Demande de la voiture que vous voulez -auto24.sn',
                requestCarPageDescOg:
                    'Soumettez une demande et nous vous aiderons à trouver la voiture parfaite adaptée à vos besoins.',
                requestCarPageKeywordsOg:
                    'Trouver une voiture, soumettre une demande, Auto 24 Senegal',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.sn',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.sn. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} FCFA avec AUTO24, Senegal',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Senegal. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Sénégal | Obtenez le meilleur prix sur AUTO24 Sénégal',

                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Sénégal | Obtenez le meilleur prix sur AUTO24 Sénégal',

                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Sénégal | Obtenez le meilleur prix sur AUTO24 Sénégal',
                carsUnderPageDesc:
                    'Les meilleurs prix de voitures d’occasion à moins de {{ PRICE }} au Sénégal, avec 100 points d’inspection, 5 jours satisfait ou remboursé, 6 mois de garantie et plus.',
                fuelPriceTrackerTitle:
                    'Derniers prix du carburant au Senegal - AUTO24.sn',
                fuelPriceTrackerDescMeta:
                    "Restez informé des derniers prix du carburant à travers le Senegal. Consultez les tarifs actuels de l'essence, du diesel et du GPL",
                fuelPriceTrackerKeywords:
                    'Derniers prix du carburant au Senegal, actualités sur le carburant au Senegal, suivi des prix du carburant, comparaison des prix du carburant, prix du carburant au Senegal 2025',
                electricityPriceTrackerTitle:
                    "Derniers prix de l'électricité en Senegal - AUTO24.sn",
                electricityPriceTrackerDescMeta:
                    "Restez informé des derniers prix de l'électricité en Senegal. Suivez les tarifs de l'électricité, surveillez les fluctuations des prix et gérez efficacement vos coûts énergétiques.",
                electricityPriceTrackerKeywords:
                    "Derniers prix de l'électricité en Senegal, mises à jour des tarifs de l'électricité, suivi des prix de l'électricité, comparaison des coûts énergétiques, tarifs de l'électricité en Senegal 2025",
                currencyConverterTitle:
                    'Convertisseur de devises - Taux de change en direct aujourd’hui en {{ Country_name }} - Auto24.sn',

                currencyConverterDescMeta:
                    'Calculez les taux de change et de devises en temps réel avec le Convertisseur de devises Auto24 à {{ Country_name }}. Convertissez entre toutes les principales devises mondiales avec cet outil et consultez les taux du marché en direct.',

                currencyConverterKeywords:
                    'Taux de change en temps réel, Calculateur Forex, Taux de change des devises, Convertisseur de devises AUTO 24, Meilleurs taux de change, Taux Forex, Outil de conversion de devises à {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.sn',
                distanceConverterDescMeta:
                    '{{ title }} en {{ Country_name }}. {{ howToConvert }} facilement',
                distanceConverterKeywords:
                    '{{ title }}. Convertir {{ op1 }} et {{ op2 }} en {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Calculateur de Coûts de Conduite | Estimez vos Dépenses de Carburant pour Tout Trajet en {{ Country_name }} - Auto24.sn',
                drivingCostCalculatorDescMeta:
                    'Calculez vos coûts de conduite en {{ Country_name }} avec Auto24.sn. Estimez les dépenses de carburant selon le prix du carburant, la distance et la consommation du véhicule. Planifiez vos trajets intelligemment et économisez sur les coûts de carburant !',
                drivingCostCalculatorKeywords:
                    'Calculateur de coûts de conduite, Estimateur de coûts de carburant, Calculateur de coûts de trajet, Calculateur de consommation de carburant, Calculateur de prix de carburant, Calculateur de coûts de voyage Auto24, Prix du carburant en {{ Country_name }}, Dépenses de voyage en voiture {{ Country_name }}',
                emissionCalculatorTitle:
                    "Calculateur d'Émissions CO2 à {{ Country_name }} – Mesurez Votre Empreinte Carbone - Auto24.sn",
                emissionCalculatorDescMeta:
                    "Utilisez notre calculateur d'émissions CO2 à {{ Country_name }} pour estimer votre empreinte carbone. Calculez les émissions liées aux transports, à la consommation d'énergie et aux activités quotidiennes pour réduire votre impact environnemental.",
                emissionCalculatorKeywords:
                    "Calculateur d'émissions CO2 {{ Country_name }}, calculateur d'empreinte carbone {{ Country_name }}, émissions de gaz à effet de serre {{ Country_name }}, suivi CO2 {{ Country_name }}, réduction des émissions {{ Country_name }}",
                bestSellerTitle:
                    'Voitures les plus vendues de {{ CurrentYear }} au Sénégal - Auto24.sn',
                bestSellerDescMeta:
                    "Découvrez les voitures les plus vendues de {{ CurrentYear }} au Sénégal. Explorez les véhicules les mieux notés en fonction des ventes, des performances et des avis des clients. Trouvez votre prochaine voiture avec Auto24.sn aujourd'hui !",
                bestSellerKeywords:
                    'Voitures les plus vendues {{ CurrentYear }}, Meilleures voitures {{ CurrentYear }}, voitures populaires {{ CurrentYear }}, Meilleures voitures au Sénégal, Voitures les plus vendues au Sénégal, Classements des voitures de {{ CurrentYear }}, Meilleurs véhicules au Sénégal, Marché automobile {{ CurrentYear }}, Données de ventes de voitures au Sénégal',
                carLoanCalculatorTitle:
                    'Calculateur de prêt automobile au Sénégal - Estimez vos paiements mensuels et intérêts - Auto24.sn',
                carLoanCalculatorDescMeta:
                    'Utilisez notre calculateur de prêt automobile pour estimer vos paiements mensuels au Sénégal, les intérêts totaux et les coûts globaux du prêt. Planifiez votre prochain achat de voiture au Sénégal en toute confiance et trouvez le meilleur prêt pour votre budget',
                carLoanCalculatorKeywords:
                    'Calculateur de prêt automobile, Calculateur de prêt auto, Financement de véhicule, Intérêt sur prêt automobile, Paiement mensuel de voiture, Remboursement de prêt, Outil de financement automobile, EMI de prêt automobile, Estimateur de prêt automobile',
                techDocsTitle:
                    "Documents Techniques de Véhicules - AUTO24.sn | Voitures d'Occasion Certifiées au Sénégal",
                techDocsDesc:
                    "Explorez une collection complète de documents techniques pour les voitures d'occasion certifiées sur AUTO24.sn. Trouvez des spécifications, des manuels et des guides pour vous aider à prendre des décisions éclairées au Sénégal.",
                techDocsKeyword:
                    "documents techniques, manuels de voiture, voitures d'occasion, AUTO24 Sénégal, spécifications de voiture, guides de véhicule"
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.coza:
            return {
                homePageTitle:
                    "AUTO24.co.za - Voitures d'Occasion Certifiées en Afrique du Sud | Achetez et Vendez Localement",
                homePageDesc:
                    "Votre plateforme de choix en Afrique du Sud pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Afrique du Sud, Vendre Voiture Afrique du Sud, Marketplace Automobile, AUTO24 Afrique du Sud, Voitures de Qualité",
                homePageTitleOg:
                    "AUTO24.co.za - Voitures d'Occasion Certifiées en Afrique du Sud | Achetez et Vendez Localement",
                homePageDescOg:
                    "Votre plateforme de choix en Afrique du Sud pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Afrique du Sud, Vendre Voiture Afrique du Sud, Marketplace Automobile, AUTO24 Afrique du Sud, Voitures de Qualité",
                buyPageTitle:
                    "Voitures à vendre en Afrique du Sud | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.co.za",
                buyPageDesc:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.co.za. Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Afrique du Sud.",
                buyPageKeywords:
                    "Acheter Voitures Afrique du Sud, Voitures d'Occasion Certifiées, AUTO24 Afrique du Sud, Shopping de Voitures, Voitures Abordables",
                buyPageTitleOg:
                    "Voitures à vendre en Afrique du Sud | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.co.za",
                buyPageDescOg:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.co.za. Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Afrique du Sud.",
                buyPageKeywordsOg:
                    "Acheter Voitures Afrique du Sud, Voitures d'Occasion Certifiées, AUTO24 Afrique du Sud, Shopping de Voitures, Voitures Abordables",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.co.za",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.co.za! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement.",
                sellPageKeywords:
                    "Vendre Voiture Afrique du Sud, Vente de Voitures d'Occasion, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.co.za",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.co.za! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement.",
                sellPageKeywordsOg:
                    "Vendre Voiture Afrique du Sud, Vente de Voitures d'Occasion, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture",
                aboutPageTitle:
                    "À Propos de AUTO24.co.za - Votre Marketplace de Voitures d'Occasion de Confiance en Afrique du Sud",
                aboutPageDesc:
                    "En savoir plus sur AUTO24.co.za, la première plateforme de voitures d'occasion certifiées en Afrique du Sud. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides.",
                aboutPageKeywords:
                    "À Propos de AUTO24 Afrique du Sud, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance",
                aboutPageTitleOg:
                    "À Propos de AUTO24.co.za - Votre Marketplace de Voitures d'Occasion de Confiance en Afrique du Sud",
                aboutPageDescOg:
                    "En savoir plus sur AUTO24.co.za, la première plateforme de voitures d'occasion certifiées en Afrique du Sud. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides.",
                aboutPageKeywordsOg:
                    "À Propos de AUTO24 Afrique du Sud, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.co.za',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.co.za - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    'Financement de Voiture, Crédit Auto, AUTO24 Afrique du Sud, Options de Financement, Prêt Auto',
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.co.za',
                financePageDescOg:
                    "Explorez un financement automobile sans tracas avec Auto24.co.za - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywordsOg:
                    'Financement de Voiture, Crédit Auto, AUTO24 Afrique du Sud, Options de Financement, Prêt Auto',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.co.za',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.co.za. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Afrique du Sud, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.co.za',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.co.za. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Afrique du Sud, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.co.za",
                blogPageDesc:
                    "Visitez Auto24.co.za pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé!",
                blogPageKeywords:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Afrique du Sud, Astuces Voiture',
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.co.za",
                blogPageDescOg:
                    "Visitez Auto24.co.za pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé!",
                blogPageKeywordsOg:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Afrique du Sud, Astuces Voiture',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    "AUTO24 - Conditions générales d'utilisation",
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    'Acheter une {{make}} en occasion certifiée à {{PRIX}} ZAR avec AUTO24, Afrique du Sud',
                buyPageDescWhenBrand:
                    "Les meilleurs prix et services pour les voitures d'occasion {{BRAND}} en Afrique du Sud. Satisfait ou remboursé, garantie, assurance, maintenance et plus !",
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre en Afrique du Sud | Obtenez le meilleur prix sur AUTO24 Afrique du Sud',
                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre en Afrique du Sud | Obtenez le meilleur prix sur AUTO24 Afrique du Sud',
                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} en Afrique du Sud | Obtenez le meilleur prix sur AUTO24 Afrique du Sud',
                carsUnderPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.co.za. Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie en Afrique du Sud.",
                fuelPriceTrackerTitle:
                    'Derniers prix du carburant en Afrique du Sud - AUTO24.co.za',
                fuelPriceTrackerDescMeta:
                    "Restez informé des derniers prix du carburant à travers l'Afrique du Sud. Consultez les tarifs actuels de l'essence, du diesel et du GPL",
                fuelPriceTrackerKeywords:
                    'Derniers prix du carburant en Afrique du Sud, actualités sur le carburant en Afrique du Sud, suivi des prix du carburant, comparaison des prix du carburant, prix du carburant en Afrique du Sud 2025',
                electricityPriceTrackerTitle:
                    "Derniers prix de l'électricité en Afrique du Sud - AUTO24.co.za",
                electricityPriceTrackerDescMeta:
                    "Restez informé des derniers prix de l'électricité en Afrique du Sud. Suivez les tarifs de l'électricité, surveillez les fluctuations des prix et gérez efficacement vos coûts énergétiques.",
                electricityPriceTrackerKeywords:
                    "Derniers prix de l'électricité en Afrique du Sud, mises à jour des tarifs de l'électricité, suivi des prix de l'électricité, comparaison des coûts énergétiques, tarifs de l'électricité en Afrique du Sud 2025",
                currencyConverterTitle:
                    "Convertisseur de devises - Taux de change en direct aujourd'hui en {{ Country_name }} - Auto24.co.za",
                currencyConverterDescMeta:
                    'Calculez les taux de change et de devises en temps réel avec le Convertisseur de devises Auto24 à {{ Country_name }}. Convertissez entre toutes les principales devises mondiales avec cet outil et consultez les taux du marché en direct.',
                currencyConverterKeywords:
                    'Taux de change en temps réel, Calculateur Forex, Taux de change des devises, Convertisseur de devises AUTO 24, Meilleurs taux de change, Taux Forex, Outil de conversion de devises à {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.co.za',
                distanceConverterDescMeta:
                    '{{ title }} en {{ Country_name }}. {{ howToConvert }} facilement',
                distanceConverterKeywords:
                    '{{ title }}. Convertir {{ op1 }} et {{ op2 }} en {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Calculateur de Coûts de Conduite | Estimez vos Dépenses de Carburant pour Tout Trajet en {{ Country_name }} - Auto24.co.za',
                drivingCostCalculatorDescMeta:
                    'Calculez vos coûts de conduite en {{ Country_name }} avec Auto24.co.za. Estimez les dépenses de carburant selon le prix du carburant, la distance et la consommation du véhicule. Planifiez vos trajets intelligemment et économisez sur les coûts de carburant !',
                drivingCostCalculatorKeywords:
                    'Calculateur de coûts de conduite, Estimateur de coûts de carburant, Calculateur de coûts de trajet, Calculateur de consommation de carburant, Calculateur de prix de carburant, Calculateur de coûts de voyage Auto24, Prix du carburant en {{ Country_name }}, Dépenses de voyage en voiture {{ Country_name }}',
                emissionCalculatorTitle:
                    "Calculateur d'Émissions CO2 à {{ Country_name }} – Mesurez Votre Empreinte Carbone - Auto24.co.za",
                emissionCalculatorDescMeta:
                    "Utilisez notre calculateur d'émissions CO2 à {{ Country_name }} pour estimer votre empreinte carbone. Calculez les émissions liées aux transports, à la consommation d'énergie et aux activités quotidiennes pour réduire votre impact environnemental.",
                emissionCalculatorKeywords:
                    "Calculateur d'émissions CO2 {{ Country_name }}, calculateur d'empreinte carbone {{ Country_name }}, émissions de gaz à effet de serre {{ Country_name }}, suivi CO2 {{ Country_name }}, réduction des émissions {{ Country_name }}",
                bestSellerTitle:
                    'Voitures les plus vendues de {{ CurrentYear }} en Afrique du Sud - Auto24.co.za',
                bestSellerDescMeta:
                    "Découvrez les voitures les plus vendues de {{ CurrentYear }} en Afrique du Sud. Explorez les véhicules les mieux notés en fonction des ventes, des performances et des avis des clients. Trouvez votre prochaine voiture avec Auto24.co.za aujourd'hui !",
                bestSellerKeywords:
                    'Voitures les plus vendues {{ CurrentYear }}, Meilleures voitures {{ CurrentYear }}, voitures populaires {{ CurrentYear }}, Meilleures voitures en Afrique du Sud, Voitures les plus vendues en Afrique du Sud, Classements des voitures de {{ CurrentYear }}, Meilleurs véhicules en Afrique du Sud, Marché automobile {{ CurrentYear }}, Données de ventes de voitures en Afrique du Sud',
                carLoanCalculatorTitle:
                    'Calculateur de prêt automobile en Afrique du Sud - Estimez vos paiements mensuels et intérêts - Auto24.co.za',
                carLoanCalculatorDescMeta:
                    'Utilisez notre calculateur de prêt automobile pour estimer vos paiements mensuels en Afrique du Sud, les intérêts totaux et les coûts globaux du prêt. Planifiez votre prochain achat de voiture en Afrique du Sud en toute confiance et trouvez le meilleur prêt pour votre budget',
                carLoanCalculatorKeywords:
                    'Calculateur de prêt automobile, Calculateur de prêt auto, Financement de véhicule, Intérêt sur prêt automobile, Paiement mensuel de voiture, Remboursement de prêt, Outil de financement automobile, EMI de prêt automobile, Estimateur de prêt automobile',
                techDocsTitle:
                    "Documents Techniques de Véhicules - AUTO24.co.za | Voitures d'Occasion Certifiées en Afrique du Sud",
                techDocsDesc:
                    "Explorez une collection complète de documents techniques pour les voitures d'occasion certifiées sur AUTO24.co.za. Trouvez des spécifications, des manuels et des guides pour vous aider à prendre des décisions éclairées en Afrique du Sud.",
                techDocsKeyword:
                    "documents techniques, manuels de voiture, voitures d'occasion, AUTO24 Afrique du Sud, spécifications de voiture, guides de véhicule"
            };
        case COUNTRY_TOP_LEVEL_DOMAIN.coke:
            return {
                homePageTitle:
                    "AUTO24.co.ke - Voitures d'Occasion Certifiées au Kenya | Achetez et Vendez Localement",
                homePageDesc:
                    "Votre plateforme de choix au Kenya pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Kenya, Vendre Voiture Kenya, Marketplace Automobile, AUTO24 Kenya, Voitures de Qualité",
                homePageTitleOg:
                    "AUTO24.co.ke - Voitures d'Occasion Certifiées au Kenya | Achetez et Vendez Localement",
                homePageDescOg:
                    "Votre plateforme de choix au Kenya pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Kenya, Vendre Voiture Kenya, Marketplace Automobile, AUTO24 Kenya, Voitures de Qualité",
                buyPageTitle:
                    "Voitures à vendre au Kenya | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.co.ke",
                buyPageDesc:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.co.ke. Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie au Kenya.",
                buyPageKeywords:
                    "Acheter Voitures Kenya, Voitures d'Occasion Certifiées, AUTO24 Kenya, Shopping de Voitures, Voitures Abordables",
                buyPageTitleOg:
                    "Voitures à vendre au Kenya | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.co.ke",
                buyPageDescOg:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.co.ke. Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie au Kenya.",
                buyPageKeywordsOg:
                    "Acheter Voitures Kenya, Voitures d'Occasion Certifiées, AUTO24 Kenya, Shopping de Voitures, Voitures Abordables",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.co.ke",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.co.ke! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement.",
                sellPageKeywords:
                    "Vendre Voiture Kenya, Vente de Voitures d'Occasion, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.co.ke",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.co.ke! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement.",
                sellPageKeywordsOg:
                    "Vendre Voiture Kenya, Vente de Voitures d'Occasion, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture",
                aboutPageTitle:
                    "À Propos de AUTO24.co.ke - Votre Marketplace de Voitures d'Occasion de Confiance au Kenya",
                aboutPageDesc:
                    "En savoir plus sur AUTO24.co.ke, la première plateforme de voitures d'occasion certifiées au Kenya. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides.",
                aboutPageKeywords:
                    "À Propos de AUTO24 Kenya, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance",
                aboutPageTitleOg:
                    "À Propos de AUTO24.co.ke - Votre Marketplace de Voitures d'Occasion de Confiance au Kenya",
                aboutPageDescOg:
                    "En savoir plus sur AUTO24.co.ke, la première plateforme de voitures d'occasion certifiées au Kenya. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides.",
                aboutPageKeywordsOg:
                    "À Propos de AUTO24 Kenya, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.co.ke',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.co.ke - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    'Financement de Voiture, Crédit Auto, AUTO24 Kenya, Options de Financement, Prêt Auto',
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.co.ke',
                financePageDescOg:
                    "Explorez un financement automobile sans tracas avec Auto24.co.ke - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywordsOg:
                    'Financement de Voiture, Crédit Auto, AUTO24 Kenya, Options de Financement, Prêt Auto',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.co.ke',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.co.ke. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Kenya, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.co.ke',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.co.ke. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Kenya, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.co.ke",
                blogPageDesc:
                    "Visitez Auto24.co.ke pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé!",
                blogPageKeywords:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Kenya, Astuces Voiture',
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.co.ke",
                blogPageDescOg:
                    "Visitez Auto24.co.ke pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé!",
                blogPageKeywordsOg:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Kenya, Astuces Voiture',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    "AUTO24 - Conditions générales d'utilisation",
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    'Acheter une {{make}} en occasion certifiée à {{PRIX}} KES avec AUTO24, Kenya',
                buyPageDescWhenBrand:
                    "Les meilleurs prix et services pour les voitures d'occasion {{BRAND}} au Kenya. Satisfait ou remboursé, garantie, assurance, maintenance et plus !",
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Kenya | Obtenez le meilleur prix sur AUTO24 Kenya',
                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Kenya | Obtenez le meilleur prix sur AUTO24 Kenya',
                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Kenya | Obtenez le meilleur prix sur AUTO24 Kenya',
                carsUnderPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.co.ke. Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie au Kenya.",
                fuelPriceTrackerTitle:
                    'Derniers prix du carburant au Kenya - AUTO24.co.ke',
                fuelPriceTrackerDescMeta:
                    "Restez informé des derniers prix du carburant à travers le Kenya. Consultez les tarifs actuels de l'essence, du diesel et du GPL",
                fuelPriceTrackerKeywords:
                    'Derniers prix du carburant au Kenya, actualités sur le carburant au Kenya, suivi des prix du carburant, comparaison des prix du carburant, prix du carburant au Kenya 2025',
                electricityPriceTrackerTitle:
                    "Derniers prix de l'électricité au Kenya - AUTO24.co.ke",
                electricityPriceTrackerDescMeta:
                    "Restez informé des derniers prix de l'électricité au Kenya. Suivez les tarifs de l'électricité, surveillez les fluctuations des prix et gérez efficacement vos coûts énergétiques.",
                electricityPriceTrackerKeywords:
                    "Derniers prix de l'électricité au Kenya, mises à jour des tarifs de l'électricité, suivi des prix de l'électricité, comparaison des coûts énergétiques, tarifs de l'électricité au Kenya 2025",
                currencyConverterTitle:
                    "Convertisseur de devises - Taux de change en direct aujourd'hui en {{ Country_name }} - Auto24.co.ke",
                currencyConverterDescMeta:
                    'Calculez les taux de change et de devises en temps réel avec le Convertisseur de devises Auto24 à {{ Country_name }}. Convertissez entre toutes les principales devises mondiales avec cet outil et consultez les taux du marché en direct.',
                currencyConverterKeywords:
                    'Taux de change en temps réel, Calculateur Forex, Taux de change des devises, Convertisseur de devises AUTO 24, Meilleurs taux de change, Taux Forex, Outil de conversion de devises à {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.co.ke',
                distanceConverterDescMeta:
                    '{{ title }} en {{ Country_name }}. {{ howToConvert }} facilement',
                distanceConverterKeywords:
                    '{{ title }}. Convertir {{ op1 }} et {{ op2 }} en {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Calculateur de Coûts de Conduite | Estimez vos Dépenses de Carburant pour Tout Trajet en {{ Country_name }} - Auto24.co.ke',
                drivingCostCalculatorDescMeta:
                    'Calculez vos coûts de conduite en {{ Country_name }} avec Auto24.co.ke. Estimez les dépenses de carburant selon le prix du carburant, la distance et la consommation du véhicule. Planifiez vos trajets intelligemment et économisez sur les coûts de carburant !',
                drivingCostCalculatorKeywords:
                    'Calculateur de coûts de conduite, Estimateur de coûts de carburant, Calculateur de coûts de trajet, Calculateur de consommation de carburant, Calculateur de prix de carburant, Calculateur de coûts de voyage Auto24, Prix du carburant en {{ Country_name }}, Dépenses de voyage en voiture {{ Country_name }}',
                emissionCalculatorTitle:
                    "Calculateur d'Émissions CO2 à {{ Country_name }} – Mesurez Votre Empreinte Carbone - Auto24.co.ke",
                emissionCalculatorDescMeta:
                    "Utilisez notre calculateur d'émissions CO2 à {{ Country_name }} pour estimer votre empreinte carbone. Calculez les émissions liées aux transports, à la consommation d'énergie et aux activités quotidiennes pour réduire votre impact environnemental.",
                emissionCalculatorKeywords:
                    "Calculateur d'émissions CO2 {{ Country_name }}, calculateur d'empreinte carbone {{ Country_name }}, émissions de gaz à effet de serre {{ Country_name }}, suivi CO2 {{ Country_name }}, réduction des émissions {{ Country_name }}",
                bestSellerTitle:
                    'Voitures les plus vendues de {{ CurrentYear }} en Afrique du Sud - Auto24.co.ke',
                bestSellerDescMeta:
                    "Découvrez les voitures les plus vendues de {{ CurrentYear }} en Afrique du Sud. Explorez les véhicules les mieux notés en fonction des ventes, des performances et des avis des clients. Trouvez votre prochaine voiture avec Auto24.co.za aujourd'hui !",
                bestSellerKeywords:
                    'Voitures les plus vendues {{ CurrentYear }}, Meilleures voitures {{ CurrentYear }}, voitures populaires {{ CurrentYear }}, Meilleures voitures en Afrique du Sud, Voitures les plus vendues en Afrique du Sud, Classements des voitures de {{ CurrentYear }}, Meilleurs véhicules en Afrique du Sud, Marché automobile {{ CurrentYear }}, Données de ventes de voitures en Afrique du Sud',
                carLoanCalculatorTitle:
                    'Calculateur de prêt automobile au Kenya - Estimez vos paiements mensuels et intérêts - Auto24.co.ke',
                carLoanCalculatorDescMeta:
                    'Utilisez notre calculateur de prêt automobile pour estimer vos paiements mensuels au Kenya, les intérêts totaux et les coûts globaux du prêt. Planifiez votre prochain achat de voiture au Kenya en toute confiance et trouvez le meilleur prêt pour votre budget',
                carLoanCalculatorKeywords:
                    'Calculateur de prêt automobile, Calculateur de prêt auto, Financement de véhicule, Intérêt sur prêt automobile, Paiement mensuel de voiture, Remboursement de prêt, Outil de financement automobile, EMI de prêt automobile, Estimateur de prêt automobile',
                techDocsTitle:
                    "Documents Techniques de Véhicules - AUTO24.co.za | Voitures d'Occasion Certifiées en Kenya",
                techDocsDesc:
                    "Explorez une collection complète de documents techniques pour les voitures d'occasion certifiées sur AUTO24.co.ke. Trouvez des spécifications, des manuels et des guides pour vous aider à prendre des décisions éclairées en Kenya.",
                techDocsKeyword:
                    "documents techniques, manuels de voiture, voitures d'occasion, AUTO24 Kenya, spécifications de voiture, guides de véhicule"
            };
        default:
            return {
                homePageTitle:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDesc:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywords:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                homePageTitleOg:
                    "AUTO24.ma - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays",
                homePageDescOg:
                    "Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.",
                homePageKeywordsOg:
                    "Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc",
                buyPageTitle:
                    "Voitures à vendre au Côte d'Ivoire | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ci",
                buyPageDesc:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywords:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                buyPageTitleOg:
                    "Voitures à vendre au Côte d'Ivoire | Obtenez les meilleurs prix sur les voitures dès aujourd'hui - auto24.ci",
                buyPageDescOg:
                    "Découvrez une large sélection de voitures d'occasion certifiées sur AUTO24.ma . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.",
                buyPageKeywordsOg:
                    "Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc",
                sellPageTitle:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ma",
                sellPageDesc:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.ma! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywords:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                sellPageTitleOg:
                    "Vendez Votre Voiture Rapidement et Facilement | Obtenez des Offres Instantanées en Espèces Aujourd'hui - auto24.ma",
                sellPageDescOg:
                    "Vous souhaitez vendre votre voiture? Obtenez une offre en espèces immédiate sur auto24.ma! Avec un processus rapide et sans tracas, vous pouvez vendre votre voiture aujourd'hui et être payé rapidement. N'attendez pas—découvrez la valeur de votre voiture dès maintenant!",
                sellPageKeywordsOg:
                    "Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc",
                aboutPageTitle:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDesc:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywords:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                aboutPageTitleOg:
                    "À Propos de AUTO24.ma - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc",
                aboutPageDescOg:
                    "En savoir plus sur AUTO24.ma , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.",
                aboutPageKeywordsOg:
                    " À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc",
                financePageTitle:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ma',
                financePageDesc:
                    "Explorez un financement automobile sans tracas avec Auto24.ma - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywords:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                financePageTitleOg:
                    'Financement Auto Simplifié | 100% en Ligne & Taux Compétitifs - auto24.ma',
                financePageDescOg:
                    "Explorez un financement automobile sans tracas avec Auto24.ma - 100% en ligne, taux compétitifs et conditions flexibles. Réalisez votre rêve automobile aujourd'hui!",
                financePageKeywordsOg:
                    'Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto',
                insurePageTitle:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ma',
                insurePageDesc:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ma. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywords:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                insurePageTitleOg:
                    'Assurance Auto Fiable et Abordable, Couverture Sans Stress au Meilleur Prix - auto24.ma',
                insurePageDescOg:
                    "Obtenez la meilleure police d'assurance automobile au meilleur prix avec Auto24.ma. Nous vous offrons une couverture sans stress, adaptée à vos besoins - protégez votre voiture dès aujourd'hui!",
                insurePageKeywordsOg:
                    "Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance",
                blogPageTitle:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ma",
                blogPageDesc:
                    "Visitez Auto24.ma pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywords:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                blogPageTitleOg:
                    "Conseils d'Entretien Automobile et Actualités de l'Industrie - auto24.ma",
                blogPageDescOg:
                    "Visitez Auto24.ma pour des conseils d'entretien automobile d'experts, des tendances de l'industrie et des informations sur les dernières actualités automobiles. Restez informé sur les soins de votre voiture et les mises à jour du secteur!",
                blogPageKeywordsOg:
                    'Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture',
                requestCarPageTitle:
                    'Trouvez la voiture de vos rêves | Demande de la voiture que vous voulez -auto24.ma',
                requestCarPageDesc:
                    'Soumettez une demande et nous vous aiderons à trouver la voiture parfaite adaptée à vos besoins.',
                requestCarPageKeywords:
                    'Trouver une voiture, soumettre une demande, Auto 24 Maroc',
                requestCarPageTitleOg:
                    'Trouvez la voiture de vos rêves | Demande de la voiture que vous voulez -auto24.ma',
                requestCarPageDescOg:
                    'Soumettez une demande et nous vous aiderons à trouver la voiture parfaite adaptée à vos besoins.',
                requestCarPageKeywordsOg:
                    'Trouver une voiture, soumettre une demande, Auto 24 Maroc',
                qNaPageTitle: 'AUTO24 - Questions & réponses fréquentes',
                contactUsPageTitle: 'AUTO24 - Nous contacter',
                privacyPolicyPageTitle: 'AUTO24 - Politique de confidentialité',
                termsNusePageTitle:
                    'AUTO24 - Conditions générales d’utilisation',
                carDetailPageTitle: 'Acheter {{make}},{{price}} - AUTO24',
                carDetailPageDesc:
                    ' Acheter une {{make}} en occasion certifiée à {{PRIX}} DH avec AUTO24',
                buyPageDescWhenBrand:
                    'Les meilleurs prix et services pour les voitures d’occasion {{BRAND}}. Satisfait ou remboursé, garantie, assurance, maintenance et plus !',
                brandPageTitle:
                    'Voitures {{ BRAND }} à vendre au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',
                bodyTypePageTitle:
                    'Voitures {{ BODY_TYPE }} à vendre au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',
                carsUnderPageTitle:
                    'Achetez des voitures à moins de {{ PRICE }} au Maroc | Obtenez le meilleur prix sur AUTO24 Maroc',
                carsUnderPageDesc:
                    "Parcourez une large sélection de voitures d'occasion certifiées à moins de {{ PRICE }} sur AUTO24.bj. Nous facilitons la recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie au Bénin.",
                fuelPriceTrackerTitle:
                    'Derniers prix du carburant au Maroc - AUTO24.ma',
                fuelPriceTrackerDescMeta:
                    "Restez informé des derniers prix du carburant à travers le Maroc. Consultez les tarifs actuels de l'essence, du diesel et du GPL",
                fuelPriceTrackerKeywords:
                    'Derniers prix du carburant au Maroc, actualités sur le carburant au Maroc, suivi des prix du carburant, comparaison des prix du carburant, prix du carburant au Maroc 2025',
                electricityPriceTrackerTitle:
                    "Derniers prix de l'électricité en Maroc - AUTO24.ma",
                electricityPriceTrackerDescMeta:
                    "Restez informé des derniers prix de l'électricité en Maroc. Suivez les tarifs de l'électricité, surveillez les fluctuations des prix et gérez efficacement vos coûts énergétiques.",
                electricityPriceTrackerKeywords:
                    "Derniers prix de l'électricité en Maroc, mises à jour des tarifs de l'électricité, suivi des prix de l'électricité, comparaison des coûts énergétiques, tarifs de l'électricité en Maroc 2025",
                currencyConverterTitle:
                    'Convertisseur de devises - Taux de change en direct aujourd’hui en {{ Country_name }} - Auto24.ma',

                currencyConverterDescMeta:
                    'Calculez les taux de change et de devises en temps réel avec le Convertisseur de devises Auto24 à {{ Country_name }}. Convertissez entre toutes les principales devises mondiales avec cet outil et consultez les taux du marché en direct.',

                currencyConverterKeywords:
                    'Taux de change en temps réel, Calculateur Forex, Taux de change des devises, Convertisseur de devises AUTO 24, Meilleurs taux de change, Taux Forex, Outil de conversion de devises à {{ Country_name }}',
                distanceConverterTitle: '{{ Name }} - Auto24.ma',
                distanceConverterDescMeta:
                    '{{ title }} en {{ Country_name }}. {{ howToConvert }} facilement',
                distanceConverterKeywords:
                    '{{ title }}. Convertir {{ op1 }} et {{ op2 }} en {{ Country_name }}',
                drivingCostCalculatorTitle:
                    'Calculateur de Coûts de Conduite | Estimez vos Dépenses de Carburant pour Tout Trajet en {{ Country_name }} - Auto24.ma',
                drivingCostCalculatorDescMeta:
                    'Calculez vos coûts de conduite en {{ Country_name }} avec Auto24.ma. Estimez les dépenses de carburant selon le prix du carburant, la distance et la consommation du véhicule. Planifiez vos trajets intelligemment et économisez sur les coûts de carburant !',
                drivingCostCalculatorKeywords:
                    'Calculateur de coûts de conduite, Estimateur de coûts de carburant, Calculateur de coûts de trajet, Calculateur de consommation de carburant, Calculateur de prix de carburant, Calculateur de coûts de voyage Auto24, Prix du carburant en {{ Country_name }}, Dépenses de voyage en voiture {{ Country_name }}',
                emissionCalculatorTitle:
                    "Calculateur d'Émissions CO2 à {{ Country_name }} – Mesurez Votre Empreinte Carbone - Auto24.ma",
                emissionCalculatorDescMeta:
                    "Utilisez notre calculateur d'émissions CO2 à {{ Country_name }} pour estimer votre empreinte carbone. Calculez les émissions liées aux transports, à la consommation d'énergie et aux activités quotidiennes pour réduire votre impact environnemental.",
                emissionCalculatorKeywords:
                    "Calculateur d'émissions CO2 {{ Country_name }}, calculateur d'empreinte carbone {{ Country_name }}, émissions de gaz à effet de serre {{ Country_name }}, suivi CO2 {{ Country_name }}, réduction des émissions {{ Country_name }}",
                bestSellerTitle:
                    'Voitures les plus vendues de {{ CurrentYear }} au Maroc - Auto24.ma',
                bestSellerDescMeta:
                    "Découvrez les voitures les plus vendues de {{ CurrentYear }} au Maroc. Explorez les véhicules les mieux notés en fonction des ventes, des performances et des avis des clients. Trouvez votre prochaine voiture avec Auto24.ma aujourd'hui !",
                bestSellerKeywords:
                    'Voitures les plus vendues {{ CurrentYear }}, Meilleures voitures {{ CurrentYear }}, voitures populaires {{ CurrentYear }}, Meilleures voitures au Maroc, Voitures les plus vendues au Maroc, Classements des voitures de {{ CurrentYear }}, Meilleurs véhicules au Maroc, Marché automobile {{ CurrentYear }}, Données de ventes de voitures au Maroc',
                carLoanCalculatorTitle:
                    'Calculateur de prêt automobile au Maroc - Estimez vos paiements mensuels et intérêts - Auto24.ma',
                carLoanCalculatorDescMeta:
                    'Utilisez notre calculateur de prêt automobile pour estimer vos paiements mensuels au Maroc, les intérêts totaux et les coûts globaux du prêt. Planifiez votre prochain achat de voiture au Maroc en toute confiance et trouvez le meilleur prêt pour votre budget',
                carLoanCalculatorKeywords:
                    'Calculateur de prêt automobile, Calculateur de prêt auto, Financement de véhicule, Intérêt sur prêt automobile, Paiement mensuel de voiture, Remboursement de prêt, Outil de financement automobile, EMI de prêt automobile, Estimateur de prêt automobile',
                techDocsTitle:
                    "Documents Techniques de Véhicules - AUTO24.ma | Voitures d'Occasion Certifiées au Maroc",
                techDocsDesc:
                    "Explorez une collection complète de documents techniques pour les voitures d'occasion certifiées sur AUTO24.ma. Trouvez des spécifications, des manuels et des guides pour vous aider à prendre des décisions éclairées au Maroc.",
                techDocsKeyword:
                    "documents techniques, manuels de voiture, voitures d'occasion, AUTO24 Maroc, spécifications de voiture, guides de véhicule"
            };
    }
};

export default getMetaFrench;
