import React, { ReactNode, useEffect } from 'react';
import { addImageFallback } from '../../utils/Helper/ImagePlaceholder';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ICombineReducerType } from '../../redux/reducers';
import FooterContent from '../FooterContent';
import NeedHelp from '../NeedHelp';
import CarSection from '../CarSection';
import { getCars } from '../../redux/actions/carActions';
import AllToolsLink from '../AllToolsLink';

interface LayoutProps {
    effectiveDate: String;
    coverImage: string;
    title: string;
    description: string;
    tableHeading: string;
    tableData: Record<string, string | number>[];
    content: ReactNode | (() => JSX.Element);
    hideTableHeader?: boolean;
    converterNode?: ReactNode | (() => JSX.Element);
    tableHeadIcon?: ReactNode | (() => ReactNode);
}
const TrackerLayout = ({
    effectiveDate,
    coverImage,
    title,
    description,
    tableHeading,
    tableData,
    content,
    hideTableHeader,
    converterNode,
    tableHeadIcon
}: LayoutProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        getCars(dispatch, { page: 1, limit: 6 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { cars: recentlyAdded } = useSelector(
        (state: ICombineReducerType) => state.car
    );

    const tableHeaders =
        tableData && tableData?.length > 0
            ? Object.keys(tableData[0]).filter((th) => th !== '_id')
            : [];

    return (
        <>
            <div className="tracker-layout padding">
                <div className="cover-img">
                    <img
                        src={coverImage}
                        alt="Cover preview"
                        onError={addImageFallback}
                        height={430}
                    />
                </div>
                <div className="tracker-header">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>

                {typeof converterNode === 'function'
                    ? converterNode()
                    : converterNode}

                <div className="tracker-content-container">
                    {tableData && tableData.length > 0 && (
                        <div className="tracker-table">
                            <div className="tracker-heading">
                                {tableHeadIcon &&
                                    (typeof tableHeadIcon === 'function'
                                        ? tableHeadIcon()
                                        : tableHeadIcon)}
                                {tableHeading}
                            </div>
                            <hr />
                            {!hideTableHeader && (
                                <div className="tracker-table-header">
                                    <span> {t('lastUpdateDate')}</span>
                                    <h6>
                                        <strong>
                                            {moment(
                                                new Date(
                                                    effectiveDate.toString()
                                                )
                                            ).format('MMM DD, YYYY')}
                                        </strong>
                                    </h6>
                                </div>
                            )}
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            {tableHeaders?.map(
                                                (header, index) => (
                                                    <th key={index}>
                                                        {header
                                                            .replace(
                                                                /([A-Z])/g,
                                                                ' $1'
                                                            )
                                                            .trim()}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData?.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {tableHeaders.map(
                                                    (header, colIndex) => (
                                                        <td key={colIndex}>
                                                            {row[header] ??
                                                                'N/A'}
                                                        </td>
                                                    )
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    {typeof content === 'function' ? content() : content}
                </div>
            </div>
            <AllToolsLink />
            <div className="tracker-layout-cars">
                <CarSection
                    sectionTitle="recentlyAdded"
                    carData={recentlyAdded.cars}
                    additionalSectionClass=""
                />
            </div>

            <FooterContent />
            <NeedHelp />
        </>
    );
};

export default TrackerLayout;
