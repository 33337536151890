import { Envelope, PhoneCall, WhatsappLogo } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { ICombineReducerType } from '../redux/reducers';

const NeedHelp = () => {
  const { email, phoneNumber, whatsapp } = useSelector((state: ICombineReducerType) => state.contact.contact);
  const fullPhoneNum = `${phoneNumber?.code} ${phoneNumber?.number}`;

  return (
    <>
      <div className="need-help-container">
        <div className="padding">
          <div className="wrapper">
            <div className="segment-info">
              <h2 className="title">Need Help? We are here to help</h2>
              <p className="subtitle mb-0">Contact us thought email or phone number</p>
            </div>
            <div className="contact-info">
              <div className="phone-number-info info-container">
                <div className="icon">
                  <PhoneCall size={24} />
                </div>
                <div className="content">
                  <p className="label">Phone Number</p>
                  <p className="value">{fullPhoneNum}</p>
                </div>
              </div>
              <div className="whatsapp-info info-container">
                <div className="icon">
                  <WhatsappLogo size={24} />
                </div>
                <div className="content">
                  <p className="label">Whatsapp</p>
                  <p className="value">{whatsapp}</p>
                </div>
              </div>
              <div className="email-info info-container">
                <div className="icon">
                  <Envelope size={24} />
                </div>
                <div className="content">
                  <p className="label">Email Address</p>
                  <p className="value">{email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NeedHelp