import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DownloadApp = () => {
    const { t } = useTranslation();
    return (
        <div className="download-section" style={{ background: '#f5f8ff' }}>
            <div className="download-app-container padding">
                <div className="content-div">
                    <h2 className="download-app-title">
                        {t('downloadAuto24App')}
                    </h2>
                    <p>{t('browseMore')}</p>
                    <div className="playstore-img">
                        <Link
                            to={`https://play.google.com/store/apps/details?id=auto24.africa`}
                            target="_blank"
                        >
                            <img
                                src="/images/googleplay.svg"
                                alt="googleplay"
                                loading="lazy"
                                width="180"
                            />
                        </Link>{' '}
                    </div>
                </div>
                <div className="image-div">
                    <img
                        src="/images/auto24iphone.webp"
                        alt="app-promotion"
                        style={{ maxHeight: '235px' }}
                        loading="lazy"
                    />
                </div>
            </div>
        </div>
    );
};

export default DownloadApp;
