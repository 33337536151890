import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
//
import { ICombineReducerType } from "../redux/reducers";
import { getRecentBlogs } from "../redux/actions/blogActions";
import useLanguageDir from "../utils/Hooks/useLanguageDir";
import { NextChevron } from "./icons/common.icons";
import { BlogCard } from "./BlogCard";
import { contentAccordToCurrentLang } from "../utils/Helper/jsHelpers";

const BlogSection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { recentBlogs: blogs } = useSelector((state: ICombineReducerType) => state.blogs);
    const { t } = useTranslation();
    const { language_dir } = useLanguageDir();

    const handleExploreBlogClick = () => {
        navigate(t('/blog', { ns: 'routes' }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        if (!blogs.length) getRecentBlogs(dispatch, { page: 1, limit: 4 });
    }, []);


    return <section className="blog-section-main-container">
        <div className="blog-section-container">
            <div className="blog-section-main-header">
                <div className="blog-section-title padding">
                    <h2 className="blog-section-heading" >
                        <span className="desktop-only" dir={language_dir}>{t('blogTitle')}</span>
                        <span className="mobile-only" dir={language_dir}>{t('blogTitleMobile')}</span>
                    </h2>
                </div>
                <div className="blog-section-header padding">
                    <div className="blog-section-title">
                        <p className="desktop-only">{t('blogSubtitle')}</p>
                        <p className="mobile-only">{t('blogSubtitleMobile')}</p>
                    </div>
                    <a href="#" className="blog-section-detail-link text-uppercase" onClick={handleExploreBlogClick} >
                        <span dir={language_dir}>{t('exploreBlogs')}</span>
                        <NextChevron />
                    </a>
                </div>
            </div>
            <div className="blog-section-cars no-scrollbar">
                {
                    blogs.map((blog) => (
                        <BlogCard
                            key={blog._id}
                            title={contentAccordToCurrentLang(
                                blog
                            )?.title}
                            image={blog.image}
                            authors={blog.author.name}
                            date={blog.createdAt}
                            slug={blog.slug}
                            type={
                                contentAccordToCurrentLang(
                                    blog.category
                                ).category
                            }
                        />
                    ))
                }
            </div>
        </div>
    </section >
}

export default BlogSection;