import { useEffect, useState } from 'react';

import Layout from '../layout/Layout';

import { appendCurrency, SetMeta } from '../utils/Helper/jsHelpers';

import ListingOverviewWidget from '../components/ListingOverviewWidget';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTopLevelDomain } from '../utils/translation';
import { COUNTRY_TOP_LEVEL_DOMAIN } from '../constants';
import CarFilterSection from '../components/carListingPage/CarListFilterSection';
import CarListSection from '../components/carListingPage/CarListSection';

const PriceRangeListing = () => {
    const { t } = useTranslation();
    const topLevelDomain = getTopLevelDomain();

    // This ensures the page scrolls to the top on mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { priceRange } = useParams();

    function getMaxPriceAccToCountry() {
        switch (topLevelDomain) {
            case COUNTRY_TOP_LEVEL_DOMAIN.bj:
                return 100000;
            case COUNTRY_TOP_LEVEL_DOMAIN.ci:
                return 8000000;
            case COUNTRY_TOP_LEVEL_DOMAIN.coke:
                return 100000;
            case COUNTRY_TOP_LEVEL_DOMAIN.coza:
                return 200000;
            case COUNTRY_TOP_LEVEL_DOMAIN.ma:
                return 100000;
            case COUNTRY_TOP_LEVEL_DOMAIN.rw:
                return 15000000;
            case COUNTRY_TOP_LEVEL_DOMAIN.sn:
                return 8000000;
            case COUNTRY_TOP_LEVEL_DOMAIN.tg:
                return 100000;
            case COUNTRY_TOP_LEVEL_DOMAIN.tz:
                return 100000;
            case COUNTRY_TOP_LEVEL_DOMAIN.ug:
                return 100000;
            default:
                return 100000;
        }
    }

    const metaTitle = priceRange
        ? t('carsUnderPageTitle', {
              ns: 'meta',
              PRICE: appendCurrency(getMaxPriceAccToCountry())
          })
        : 'Loading...';

    return (
        <>
            <SetMeta
                title={metaTitle}
                desc={t('carsUnderPageDesc', {
                    ns: 'meta',
                    PRICE: appendCurrency(getMaxPriceAccToCountry())
                })}
                keywords="buyPageKeywords"
                properties={[
                    {
                        key: 'og:title',
                        value: metaTitle
                    },
                    {
                        key: 'og:description',
                        value: t('carsUnderPageDesc', {
                            ns: 'meta',
                            PRICE: appendCurrency(getMaxPriceAccToCountry())
                        })
                    },
                    {
                        key: 'og:keywords',
                        value: 'buyPageKeywordsOg'
                    }
                ]}
            />
            <Layout showWhiteNavbar>
                <div className="listing-page">
                    <CarFilterSection priceRange={priceRange} />

                    <ListingOverviewWidget
                        title={t('cheapestCarList', {
                            X: appendCurrency(getMaxPriceAccToCountry())
                        })}
                        description={t('carsUnderPriceDescription')}
                    />
                    <CarListSection priceRange={priceRange} />
                </div>
            </Layout>
        </>
    );
};
export default PriceRangeListing;
