import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
//
import { IMAGE_URL } from '../constants';
import { ICombineReducerType } from '../redux/reducers';
import SectionContainer from './SectionContainer';
import Slider from './Slider';
import { SwiperSlide } from 'swiper/react';

const SearchByBrands = () => {
    const { carAvailableBrands } = useSelector(
        (state: ICombineReducerType) => state.carBrands
    );

    const { t } = useTranslation();

    const navigate = useNavigate();

    const searchedListViaBrandType = (selectedBrand: string) => {
        navigate(
            `${t('/buy-cars', { ns: 'routes' })}/${
                !!selectedBrand ? selectedBrand : ''
            }`
        );
    };

    const handleViewAllCarsClick = () => {
        navigate(t('/buy-cars', { ns: 'routes' }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <SectionContainer
            additionalClasses="search-by-brand-type-section"
            sectionTitle={t('searchByBrands')}
            detailObj={{
                clickHandler: handleViewAllCarsClick,
                detailLinkText: t('viewAllCars')
            }}
        >
            <Slider
                additionalSliderClasses="brand-type-slider remove-arrow-for-mobile"
                swiperOptions={{
                    breakpoints: {
                        320: {
                            slidesPerView: 4.5,
                            spaceBetween: 24
                        },
                        640: {
                            slidesPerView: 8,
                            spaceBetween: 26
                        },
                        1024: {
                            slidesPerView: 10,
                            spaceBetween: 26
                        },
                        1400: {
                            slidesPerView: 12,
                            spaceBetween: 48
                        }
                    }
                }}
                totalItem={carAvailableBrands.length}
            >
                {carAvailableBrands.map((body) => (
                    <SwiperSlide
                        key={`${body._id}`}
                        onClick={() => searchedListViaBrandType(body.slug!)}
                    >
                        <div className="brand-type-slider-card">
                            <img
                                src={IMAGE_URL + body.image}
                                alt={body.brand}
                            />
                            <div className="body-type-slider-text text-capitalize">
                                <p>{body.brand.toLowerCase()}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Slider>
        </SectionContainer>
    );
};

export default SearchByBrands;
