import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
//
import { ICombineReducerType } from '../redux/reducers';
import SectionContainer from './SectionContainer';
import { useTranslation } from 'react-i18next';
import Slider from './Slider';
import { IMAGE_URL } from '../constants';
import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';
import useLanguageDir from '../utils/Hooks/useLanguageDir';

const SearchByBodyType = () => {
    const bodyTypes = useSelector(
        (state: ICombineReducerType) => state.car.availableBodyTypes
    );

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { language_dir } = useLanguageDir();

    const searchedListViaBodyType = (selectedBodyType: string) => {
        navigate(
            `${t('/body-type', { ns: 'routes' })}/${
                !!selectedBodyType ? selectedBodyType : ''
            }`
        );
    };

    const handleViewAllCarsClick = () => {
        navigate(t('/buy-cars', { ns: 'routes' }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <SectionContainer
            additionalClasses="search-by-body-type-section"
            sectionTitle={t('exploreCarByBodyType')}
            detailObj={{
                clickHandler: handleViewAllCarsClick,
                detailLinkText: t('viewAllCars')
            }}
        >
            <Slider
                additionalSliderClasses="body-type-slider remove-arrow-for-mobile"
                swiperOptions={{
                    breakpoints: {
                        320: {
                            slidesPerView: 3.5,
                            spaceBetween: 42
                        },
                        640: {
                            slidesPerView: 4,
                            spaceBetween: 46
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 50
                        },
                        1400: {
                            slidesPerView: 7,
                            spaceBetween: 54
                        }
                    }
                }}
                totalItem={bodyTypes.length}
            >
                {bodyTypes.map((body) => (
                    <SwiperSlide
                        key={`${body._id}`}
                        onClick={() => searchedListViaBodyType(body.slug!)}
                    >
                        <div className="body-type-slider-card">
                            <img
                                src={IMAGE_URL + body.image}
                                alt={body.bodyType}
                            />
                            <div className="body-type-slider-text text-capitalize">
                                <p dir={language_dir}>
                                    {contentAccordToCurrentLang(
                                        body
                                    ).bodyType.toLowerCase() ?? 'n/a'}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Slider>
        </SectionContainer>
    );
};

export default SearchByBodyType;
