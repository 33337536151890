import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
//
import { ICombineReducerType } from '../../redux/reducers';
import { Select } from 'antd';
import { CaretDown } from 'phosphor-react';
import CarCard from '../CarCard';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    resetBuyCarsList,
    setBuyCarListPage
} from '../../redux/actions/carListAction';
import { getCarData } from '../../redux/actions/carListingAction';
import { Stringfy } from '../../utils/Helper/jsHelpers';
import { CarFilterType, CarSortType } from '../../interface/car';
import Loading from '../ui/Loading';
import {
    getCarAvailableBrand,
    getCarAvailablsModels
} from '../../redux/actions/carModelsAction';
import { useDebouncedCallback } from 'use-debounce';
import { decodeCompactNumber } from '../../utils/Helper/PriceFormatter';

// TODO confirm this value
const SortByArray = [
    { name: 'highestPriceFirst', value: '-price' },
    { name: 'lowestPriceFirst', value: 'price' },
    { name: 'highestMileageFirst', value: '-mileage' },
    { name: 'lowestMileageFirst', value: 'mileage' }
];

const CarListSection = ({
    activeBrand,
    activeModel,
    activeBodyType,
    priceRange
}: {
    activeBrand?: string;
    activeModel?: string;
    activeBodyType?: string;
    priceRange?: string;
}) => {
    const { t } = useTranslation();
    const [is_loading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { totalCar, cars } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const carListData = useSelector(
        (state: ICombineReducerType) => state.carListReducer
    );
    const observerRef = useRef<IntersectionObserver>();
    const [has_more, setHasMore] = useState(false);
    const dispatch = useDispatch();

    const onSortSelect = (sort_value: string) => {
        setSearchParams((prevSearchParam) => {
            const newParams = new URLSearchParams(prevSearchParam);
            newParams.set('sort', sort_value);
            return newParams;
        });
    };

    /**
     *
     * @param reset_page Determine if the data fetched is from changing filter or scrolling to end
     * In case when fetching data from filter we don't increment the page count, but want to reset it to 1
     * And In case when fetching data from scrolling to the end we want to increment the page count so we can use that increment value next time
     */
    const fetchData = async (reset_page: boolean) => {
        try {
            setIsLoading(true);
            const carBrands = searchParams.getAll('brand');
            if (carBrands.length === 0) {
                if (!activeBrand) {
                    getCarAvailablsModels(dispatch);
                }
            } else {
                carBrands.forEach((brand) => {
                    getCarAvailableBrand(dispatch, brand);
                });

                if (activeBrand) {
                    getCarAvailableBrand(dispatch, activeBrand);
                }
            }

            if (reset_page) {
                await resetBuyCarsList(dispatch);
            }
            const resData = await getCarData(dispatch, {
                search: searchParams.get('search') ?? undefined,
                brands: activeBrand
                    ? Stringfy([activeBrand])
                    : Stringfy(searchParams.getAll('brand')) ?? undefined,
                models: activeModel
                    ? Stringfy([activeModel])
                    : Stringfy(searchParams.getAll('model')) ?? undefined,
                minPrice: searchParams.get('minPrice') ?? undefined,
                maxPrice: priceRange
                    ? decodeCompactNumber(priceRange)
                    : searchParams.get('maxPrice') ?? undefined,
                colors: Stringfy(searchParams.getAll('color')) ?? undefined,
                transmissions:
                    Stringfy(searchParams.getAll('transmission')) ?? undefined,
                bodyTypes: activeBodyType
                    ? Stringfy([activeBodyType])
                    : Stringfy(searchParams.getAll('bodyType')) ?? undefined,
                fuelType: searchParams.get('fuelType') ?? undefined,
                sort: (searchParams.get('sort') as CarSortType) ?? '-price',
                isNewCar:
                    (searchParams.get('isNewCar') as CarFilterType) ??
                    undefined,
                isElectric:
                    (searchParams.get('isElectric') as CarFilterType) ??
                    undefined,
                imported:
                    (searchParams.get('imported') as CarFilterType) ??
                    undefined,
                minMileage: searchParams.get('minMileage') ?? undefined,
                maxMileage: searchParams.get('maxMileage') ?? undefined,
                minMonthlyInstallment:
                    searchParams.get('minMonthlyInstallment') ?? undefined,
                maxMonthlyInstallment:
                    searchParams.get('maxMonthlyInstallment') ?? undefined,
                discount:
                    (searchParams.get('discount') as CarFilterType) ??
                    undefined,
                minModelYear: searchParams.get('minModelYear') ?? undefined,
                maxModelYear: searchParams.get('maxModelYear') ?? undefined,
                page: reset_page ? 1 : carListData?.buyPage,
                limit: 16
            });
            if (resData.status === 200) {
                await setBuyCarListPage(
                    dispatch,
                    reset_page ? 2 : carListData?.buyPage + 1
                );
                setHasMore(resData?.data?.cars.length > 0);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.trace(error);
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedFetch = useDebouncedCallback(fetchData, 1000);

    const lastItemRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (is_loading) return;
            if (observerRef.current) observerRef.current.disconnect();
            observerRef.current = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        if (
                            !is_loading &&
                            carListData?.buyPage < carListData?.totalBuyPage
                        ) {
                            setIsLoading(true);
                            debouncedFetch(false);
                            return;
                        }
                        setHasMore(false);
                    }
                },
                {
                    rootMargin: '20px'
                }
            );
            if (node) observerRef.current.observe(node);
        },
        [is_loading, has_more, carListData]
    );

    useEffect(() => {
        fetchData(true);
    }, [searchParams, activeBrand, activeModel, activeBodyType, priceRange]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeModel, activeBrand]);

    return (
        <section>
            <div className="car-list-section">
                <div className="padding">
                    <div className="car-list-header">
                        <h3 className="car-list-header-title">
                            {`${t('xlistingPageResultTitle', {
                                number: totalCar
                            })}`}
                        </h3>
                        <div className="car-list-header-sort">
                            <span className="sort-title">{t('sortBy')}</span>
                            <Select
                                defaultValue={
                                    searchParams.get('sort') ??
                                    SortByArray[0].value
                                }
                                className="car-list-sort-select"
                                defaultActiveFirstOption={true}
                                bordered={false}
                                getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                }
                                onChange={onSortSelect}
                                suffixIcon={
                                    <CaretDown
                                        size={20}
                                        color="#175adc"
                                        weight="bold"
                                    />
                                }
                            >
                                {SortByArray.map((options, index) => {
                                    return (
                                        <Select.Option
                                            value={options.value}
                                            key={index}
                                            dropdownClassName="sortby-dropdown"
                                        >
                                            {t(options.name)}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                    {
                        <div className="car-list-grid">
                            {cars.map((el, index) => (
                                <div
                                    key={`${el._id}-${index}`}
                                    ref={
                                        index === cars.length - 1
                                            ? lastItemRef
                                            : null
                                    }
                                >
                                    <CarCard
                                        data={el}
                                        newCarFlag={el.isNewCar}
                                    />
                                </div>
                            ))}
                        </div>
                    }
                    {is_loading ? (
                        <Loading
                            style={{
                                display: 'block'
                            }}
                            id="lower"
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </section>
    );
};

export default CarListSection;
