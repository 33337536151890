import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//
import { ICombineReducerType } from "../redux/reducers";
import useLanguageDir from "../utils/Hooks/useLanguageDir";
import CustomerReviewCard from "./ReviewCard";
import { contentAccordToCurrentLang } from "../utils/Helper/jsHelpers";
import StarRating from "./StartRating";
import { useEffect } from "react";
import { getCustomerGallery } from "../redux/actions/customerGalleryActions";

const CompanyRatings = [
    {
        company_name: "Google",
        company_image: "/images/logos/google.svg",
        rating: 4.8
    },
    {
        company_name: "Hello Peter",
        company_image: "/images/logos/hello-peter.svg",
        rating: 4.3
    }
]


const CustomerReviewSection = () => {
    const dispatch = useDispatch();
    const { gallery } = useSelector(
        (state: ICombineReducerType) => state.customerGallery
    );
    const { t } = useTranslation();
    const { language_dir } = useLanguageDir();
    useEffect(() => {
        if (!gallery || gallery.length === 0) {
            getCustomerGallery(dispatch)
        }
    }, []);
    return (
        <>{
            gallery && Array.isArray(gallery) && gallery.length > 0 &&
            <section className="review-section">
                <div className="">
                    <div className="padding">
                        <div className="review-title-container">
                            <h2 className="review-section-title" dir={language_dir}>
                                {t('happycustomerHappyus', { ns: 'hero' })}
                            </h2>
                        </div>
                    </div>
                    <div className="review-grid no-scrollbar">
                        {
                            gallery.slice(0, 4).map((item, index) => (
                                <CustomerReviewCard
                                    key={`${item._id}-${index}`}
                                    rating={item.rating}
                                    review={contentAccordToCurrentLang(item).caption}
                                    created_date={item.review_date}
                                    reviewer={item.customerName}
                                    source={item.source}
                                />
                            ))
                        }
                    </div>
                    <div className="review-company-ratings padding">
                        {
                            CompanyRatings.map((el, index) => <div key={`${index}-${el.company_name}`} className="review-company-review">
                                <img src={el.company_image} alt={`${el.company_name}-review`} />
                                <span className="review-company-name">{el.company_name}</span>
                                <div className="review-company-rating">
                                    <span>{el.rating}</span>
                                    <StarRating rating={el.rating} />
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </section>
        }</>
    )
}

export default CustomerReviewSection;