import { ReactNode } from "react";
import { NextChevron } from "./icons/common.icons";
import useLanguageDir from "../utils/Hooks/useLanguageDir";

interface ISectionContainer {
    sectionTitle: ReactNode,
    sectionSubTitle?: ReactNode,
    detailObj?: {
        detailLinkText: String,
        clickHandler: () => void
    },
    additionalClasses?: String,
    children: ReactNode
}

const SectionContainer = ({ sectionTitle, sectionSubTitle, detailObj, children, additionalClasses="" }: ISectionContainer) => {
    const {language_dir} = useLanguageDir()
    
    return (
        <section className={`section-main-container ${additionalClasses}`}>
            <div className="section-container padding">
                <div className="section-container-heading-row">
                    <div>
                        <h2 className="section-container-title" dir={language_dir}>{sectionTitle}</h2>
                        {sectionSubTitle && <div>{sectionSubTitle}</div>}
                    </div>
                    {
                        detailObj && <a href="#" className="section-container-detail-link text-uppercase" onClick={detailObj.clickHandler} >
                            <span dir={language_dir}>{detailObj.detailLinkText}</span>
                            <NextChevron />
                        </a>
                    }
                </div>
                <div className="section-list">
                    {children}
                </div>
            </div>
        </section>
    )
}

export default SectionContainer;