import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
//
import Hero from '../components/Hero';
import Layout from '../layout/Layout';
import FooterContent from '../components/FooterContent';
import DownloadApp from '../components/ui/DownloadApp';
import FindUs from '../components/FindUs';
import CarSection from '../components/CarSection';

import { getCarBodyTypes, getCars } from '../redux/actions/carActions';
import {
    resetNewCarsList,
    resetRecentCarsList,
    setNewCarListPage,
    setRecentCarListPage
} from '../redux/actions/carListAction';
import { getCustomerGallery } from '../redux/actions/customerGalleryActions';
import { fetchFeaturedCars } from '../redux/actions/fetchFeaturedCars';
import {
    fetchCheapestCarList,
    fetchNewCarList
} from '../redux/actions/fetchNewCarListAction';
import { ICombineReducerType } from '../redux/reducers';
import { getMaxPriceAccToCountry, SetMeta } from '../utils/Helper/jsHelpers';
import { getTopLevelDomain } from '../utils/translation';
import NeedHelp from '../components/NeedHelp';
import { CustomerAssurance } from '../components/CustomerAssurance';
import SearchByBodyType from '../components/SearchByBodyType';
import SearchByBrands from '../components/SearchByBrands';
import CalculateLoanSection from '../components/CalculateLoanSection';
import MatchBudgetSection from '../components/MatchBudgetSection';
import SellMyCarSection from '../components/SellMyCarSection';
import CustomerReviewSection from '../components/CustomerReviewSection';
import BlogSection from '../components/BlogSection';
import MediaRecognitionSection from '../components/MediaRecognitionSection';

export const CAR_LIST_TYPE_ENUM = {
    electricCars: 'electricCars',
    importedCars: 'importedCars',
    newCars: 'newCars',
    cheapestCars: 'cheapestCars',
    fuelAndBodyType: 'fuelAndBodyType',
    custom: 'custom'
} as const;

export type ICarListType = keyof typeof CAR_LIST_TYPE_ENUM;

const Home = () => {
    const dispatch = useDispatch();
    const { featuredCars } = useSelector(
        (state: ICombineReducerType) => state.featuredCars
    );
    const { cars: recentlyAdded } = useSelector(
        (state: ICombineReducerType) => state.car
    );

    const topLevelDomain = getTopLevelDomain();

    useEffect(() => {
        // (async function () {
        getCars(dispatch, { page: 1, limit: 8 });
        getCustomerGallery(dispatch);
        fetchNewCarList(dispatch, { page: 1, limit: 6 });
        fetchFeaturedCars(dispatch, { page: 1, limit: 6 });
        fetchCheapestCarList(
            dispatch,
            getMaxPriceAccToCountry(topLevelDomain),
            {
                page: 1,
                limit: 3,
                random: true
            }
        );
        // Increase recently and new car listing page number by one
        setNewCarListPage(dispatch, 2);
        setRecentCarListPage(dispatch, 2);

        getCarBodyTypes(dispatch, true);
        // })();

        return () => {
            resetNewCarsList(dispatch);
            resetRecentCarsList(dispatch);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const location = useLocation();
    useEffect(() => {
        const hash = location.hash;

        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element?.id !== 'find-us') return;
            element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location]);

    return (
        <>
            <SetMeta
                title="homePageTitle"
                desc="homePageDesc"
                keywords="homePageKeywords"
            />
            <div className="home">
                <Layout>
                    <Hero recentlyAddedCars={recentlyAdded} />
                    <CustomerAssurance />
                    {/* Featured Cars */}
                    <CarSection
                        sectionTitle="featuredCars"
                        carData={featuredCars.cars}
                        additionalSectionClass="transparent-green"
                    />
                    <SearchByBodyType />
                    <CarSection
                        sectionTitle="recentlyAdded"
                        carData={recentlyAdded.cars}
                        additionalSectionClass=""
                    />
                    <SearchByBrands />
                    <CalculateLoanSection />
                    <MatchBudgetSection />
                    <div className="bg-white">
                        <CustomerReviewSection />
                    </div>
                    <SellMyCarSection />
                    <BlogSection />
                    <div className="bg-white">
                        <MediaRecognitionSection />
                    </div>
                    <FindUs />
                    <div className="bg-white">
                        <FooterContent />
                    </div>
                    <div className="bg-white">
                        <NeedHelp />
                    </div>
                    <DownloadApp />
                </Layout>
            </div>
        </>
    );
};

export default Home;
