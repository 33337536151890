const metaKinyarwanda = {
    homePageTitle:
        'AUTO24.rw - Imodoka Zikoreshwa zemerewe mu Rwanda | Gura no Kugurisha mu Rwanda',
    homePageDesc:
        "Isoko yawe yo mu Rwanda yo gurisha no gukomera imodoka zikoreshwa zemerewe. Reba ubusobanuro bw'ibyerekeye imodoka bwawe, gukomera neza no kugurisha cyane.",
    homePageKeywords:
        "Imodoka Zikoreshwa, Gura Imodoka Rwanda, Kugurisha Imodoka Rwanda, Isoko rya AUTO24 mu Rwanda, Imodoka z'ubwoko bwiza, Isoko rya Imodoka mu Rwanda",
    homePageTitleOg:
        'AUTO24.rw - Imodoka Zikoreshwa zemerewe mu Rwanda | Gura no Kugurisha mu Rwanda',
    homePageDescOg:
        "Isoko yawe yo mu Rwanda yo gurisha no gukomera imodoka zikoreshwa zemerewe. Reba ubusobanuro bw'ibyerekeye imodoka bwawe, gukomera neza no kugurisha cyane.",
    homePageKeywordsOg:
        "Imodoka Zikoreshwa, Gura Imodoka Rwanda, Kugurisha Imodoka Rwanda, Isoko rya AUTO24 mu Rwanda, Imodoka z'ubwoko bwiza, Isoko rya Imodoka mu Rwanda",
    buyPageTitle:
        'Ibinyabiziga byagurishijwe mu Rwanda | Bikuze ibiciro byiza ku binyabiziga uyu munsi - auto24.rw',
    buyPageDesc:
        "Reba imodoka zikoreshwa zemerewe zishoboka ku isoko rya AUTO24.rw . Dufasha kugira ngo uhite ubona imodoka izagufasha mu bucuruzi n'ubuzima bwawe mu Rwanda.",
    buyPageKeywords:
        'Gura Imodoka Rwanda, Imodoka Zikoreshwa Zemerewe, AUTO24 Rwanda, Kugura Imodoka, Imodoka Zishoboka',
    buyPageTitleOg:
        'Ibinyabiziga byagurishijwe mu Rwanda | Bikuze ibiciro byiza ku binyabiziga uyu munsi - auto24.rw',
    buyPageDescOg:
        "Reba imodoka zikoreshwa zemerewe zishoboka ku isoko rya AUTO24.rw . Dufasha kugira ngo uhite ubona imodoka izagufasha mu bucuruzi n'ubuzima bwawe mu Rwanda.",
    buyPageKeywordsOg:
        'Gura Imodoka Rwanda, Imodoka Zikoreshwa Zemerewe, AUTO24 Rwanda, Kugura Imodoka, Imodoka Zishoboka',
    sellPageTitle:
        "Gurisha Imodoka Yawe Byihuse kandi Byoroshye | Fata Amasezerano y'Amavuta byihuse uyu munsi - auto24.rw",
    sellPageDesc:
        "Urashaka kugurisha imodoka yawe? Fata amafaranga byihuse kuri auto24.rw! Hamwe n'uburyo bwihuse kandi budateye amakenga, ushobora kugurisha imodoka yawe uyu munsi ukabona amafaranga vuba. Ntutinze—reba agaciro k'imodoka yawe ubu!",
    sellPageKeywords:
        "Kugurisha Imodoka Rwanda, Kugurisha Imodoka Zikoreshwa, AUTO24 Kugurisha, Ibisobanuro by'Imodoka",
    sellPageTitleOg:
        "Gurisha Imodoka Yawe Byihuse kandi Byoroshye | Fata Amasezerano y'Amavuta byihuse uyu munsi - auto24.rw",
    sellPageDescOg:
        "Urashaka kugurisha imodoka yawe? Fata amafaranga byihuse kuri auto24.rw! Hamwe n'uburyo bwihuse kandi budateye amakenga, ushobora kugurisha imodoka yawe uyu munsi ukabona amafaranga vuba. Ntutinze—reba agaciro k'imodoka yawe ubu!",
    sellPageKeywordsOg:
        "Kugurisha Imodoka Rwanda, Kugurisha Imodoka Zikoreshwa, AUTO24 Kugurisha, Ibisobanuro by'Imodoka",
    aboutPageTitle:
        "Ku Ibisobanuro bya AUTO24.rw - Isoko ry'Imodoka Zikoreshwa Zemerewe Iyobewe mu Rwanda",
    aboutPageDesc:
        "Menya ibisobanuro bya AUTO24.rw , isoko rya imodoka zikoreshwa zemerewe ryiyobowe mu Rwanda. Dufasha kugurisha imodoka z'ubwoko bwiza.",
    aboutPageKeywords:
        "Ibisobanuro bya AUTO24 Rwanda, Isoko ry'Imodoka Zikoreshwa, Isoko ry'Imodoka Riyobowe, Isoko ry'Imodoka mu Rwanda",
    aboutPageTitleOg:
        "Ku Ibisobanuro bya AUTO24.rw - Isoko ry'Imodoka Zikoreshwa Zemerewe Iyobewe mu Rwanda",
    aboutPageDescOg:
        "Menya ibisobanuro bya AUTO24.rw , isoko rya imodoka zikoreshwa zemerewe ryiyobowe mu Rwanda. Dufasha kugurisha imodoka z'ubwoko bwiza.",
    aboutPageKeywordsOg:
        "Ibisobanuro bya AUTO24 Rwanda, Isoko ry'Imodoka Zikoreshwa, Isoko ry'Imodoka Riyobowe, Isoko ry'Imodoka mu Rwanda",
    financePageTitle:
        "Finansiyo y'Imodoka Yoroheje | 100% ku mbuga & Amategeko y'Ubwiza - auto24.rw",
    financePageDesc:
        "Reba uburyo bwo kubona amafaranga y'imodoka bitagoranye kuri Auto24.rw - 100% ku mbuga, amategeko y'ubwiza n'ibihe byoroshye. Fata imodoka yawe y'ikitegererezo uyu munsi!",
    financePageKeywords:
        'Amansuzi yo Gura Imodoka, AUTO24 Amansuzi, Amansuzi Zishoboka',
    financePageTitleOg:
        "Finansiyo y'Imodoka Yoroheje | 100% ku mbuga & Amategeko y'Ubwiza - auto24.rw",
    financePageDescOg:
        "Reba uburyo bwo kubona amafaranga y'imodoka bitagoranye kuri Auto24.rw - 100% ku mbuga, amategeko y'ubwiza n'ibihe byoroshye. Fata imodoka yawe y'ikitegererezo uyu munsi!",
    financePageKeywordsOg:
        'Amansuzi yo Gura Imodoka, AUTO24 Amansuzi, Amansuzi Zishoboka',
    insurePageTitle:
        "Ibiranga Icyizere mu Bicuruzwa by'Ubwishingizi, Gukora mu Gahunda nziza ku Giciro cyiza - auto24.rw",
    insurePageDesc:
        "Fata politiki y'ubwishingizi bwiza ku giciro cyiza hamwe na Auto24.rw. Turakora ibikorwa by’ubwishingizi bitagoranye byateguwe ku buryo bikwiranye nawe - kurinda imodoka yawe uyu munsi!",
    insurePageKeywords:
        "Umutekano w'Imodoka, AUTO24 Umutekano, Umutekano mu Rwanda",
    insurePageTitleOg:
        "Ibiranga Icyizere mu Bicuruzwa by'Ubwishingizi, Gukora mu Gahunda nziza ku Giciro cyiza - auto24.rw",
    insurePageDescOg:
        "Fata politiki y'ubwishingizi bwiza ku giciro cyiza hamwe na Auto24.rw. Turakora ibikorwa by’ubwishingizi bitagoranye byateguwe ku buryo bikwiranye nawe - kurinda imodoka yawe uyu munsi!",
    insurePageKeywordsOg:
        "Umutekano w'Imodoka, AUTO24 Umutekano, Umutekano mu Rwanda",
    blogPageTitle:
        "Inama z'Ubwiza bw'Imodoka no Kumenya Amakuru y'Uruganda rw'Ibinyabiziga - auto24.rw",
    blogPageDesc:
        "Sura auto24.rw kugira ngo ubone inama z'abahanga mu kwita ku binyabiziga, amakuru ku bigenzi ndetse no kumenya amakuru mashya y’ibinyabiziga. Gumana amakuru yo kubungabunga imodoka no kuzamura urwego rw'ubumenyi bw’ibinyabiziga!",
    blogPageKeywords:
        "AUTO24 Inkuru, Amakuru y'Imodoka, Amakuru yo Ku Rwanda, Amakuru y'Ibisobanuro",
    blogPageTitleOg:
        "Inama z'Ubwiza bw'Imodoka no Kumenya Amakuru y'Uruganda rw'Ibinyabiziga - auto24.rw",
    blogPageDescOg:
        "Sura auto24.rw kugira ngo ubone inama z'abahanga mu kwita ku binyabiziga, amakuru ku bigenzi ndetse no kumenya amakuru mashya y’ibinyabiziga. Gumana amakuru yo kubungabunga imodoka no kuzamura urwego rw'ubumenyi bw’ibinyabiziga!",
    blogPageKeywordsOg:
        "AUTO24 Inkuru, Amakuru y'Imodoka, Amakuru yo Ku Rwanda, Amakuru y'Ibisobanuro",
    qNaPageTitle: 'AUTO24 - Frequently asked questions ',
    contactUsPageTitle: ' AUTO24 - Contact us',
    privacyPolicyPageTitle: ' AUTO24 - Privacy policy',
    termsNusePageTitle: 'AUTO24 - Terms & conditions',
    carDetailPageTitle: 'Buy {{make}},{{price}} RWF - AUTO24',
    carDetailPageDesc:
        'Buy a {{make}} certified used car at {{PRIX}} RWF with AUTO24, Rwanda',
    buyPageDescWhenBrand:
        'Best prices and services for {{BRAND}} used cars in Rwanda. Refund policy, warranty, insurance, maintenance, and all included!',
    brandPageTitle:
        'Imodoka {{ BRAND }} zigurishwa mu Rwanda | Bona igiciro cyiza kuri AUTO24 Rwanda',
    bodyTypePageTitle:
        'Imodoka {{ BODY_TYPE }} zigurishwa mu Rwanda | Bona igiciro cyiza kuri AUTO24 Rwanda',
    carsUnderPageTitle:
        'Tegura imodoka munsi ya {{ PRICE }} mu Rwanda | Bona igiciro cyiza kuri AUTO24 Rwanda',
    carsUnderPageDesc:
        "Reba imodoka zikoreshwa zemerewe zishoboka ku isoko {{ PRICE }} rya AUTO24.rw . Dufasha kugira ngo uhite ubona imodoka izagufasha mu bucuruzi n'ubuzima bwawe mu Rwanda.",
    fuelPriceTrackerTitle: 'Ibiciro bya lisansi bishya muri Maroc - AUTO24.ma',
    fuelPriceTrackerDescMeta:
        "Komeza kumenya ibiciro bya lisansi muri Maroc. Reba ibiciro by'ibitoro, mazutu, na LPG ubu",
    fuelPriceTrackerKeywords:
        "Ibiciro bya lisansi bishya muri Maroc, amakuru ya lisansi muri Maroc, gukurikirana ibiciro bya lisansi, ugereranyo w'ibiciro bya lisansi, igiciro cya lisansi muri Maroc 2025",
    electricityPriceTrackerTitle:
        "Ibiciro by'Umuriro mu Rwanda biheruka - AUTO24.rw",
    electricityPriceTrackerDescMeta:
        "Guma ugezweho ku biciro by'umuriro mu Rwanda. Kurikirana ibiciro by’amashanyarazi, urebe uko bihindagurika, kandi ucunge neza ikiguzi cy’ingufu uko bikwiye.",
    electricityPriceTrackerKeywords:
        "Ibiciro by'umuriro mu Rwanda biheruka, amakuru mashya ku biciro by’amashanyarazi, gukurikirana ibiciro by’amashanyarazi, ugereranye ibiciro by’ingufu, ibiciro by’amashanyarazi mu Rwanda 2025",
    currencyConverterTitle:
        'Igikoresho cyo guhindura amafaranga - Igiciro cyo guhinduranya amafaranga ku isoko muri {{ Country_name }} - Auto24 Rwanda',

    currencyConverterDescMeta:
        'Ihinduranya amafaranga mu gihe nyacyo ukoresheje Auto24 kuri {{ Country_name }}. Hindura amafaranga hagati y’amafaranga yose akomeye ku isi ukoresheje iyi mashini, kandi urebe igiciro kiri ku isoko.',

    currencyConverterKeywords:
        'Igiciro cyo guhindura amafaranga mu gihe nyacyo, Imashini y’ifaranga, Igiciro cy’ifaranga ku isoko, Auto24 Igikoresho cyo guhindura amafaranga, Igiciro cyiza cyo guhindura amafaranga, Igiciro cy’ifaranga, Igikoresho cyo guhindura amafaranga kuri {{ Country_name }}',
    distanceConverterTitle: '{{ Name }} - Auto24.rw',
    distanceConverterDescMeta:
        '{{ title }} muri {{ Country_name }}. {{ howToConvert }} byoroshye',
    distanceConverterKeywords:
        '{{ title }}. Hindura {{ op1 }} na {{ op2 }} muri {{ Country_name }}',
    emissionCalculatorTitle:
        "Ishuzo ry'imiterere ya CO2 muri {{ Country_name }} – Gerageza Ibimera byawe bya Carbon - Auto24.rw",
    emissionCalculatorDescMeta:
        "Gukoresha ishuzo ry'imiterere ya CO2 muri {{ Country_name }} kugirango ugerageze ibimera byawe bya carbon. Bara ibimera biva mu byendagereko, gukoresha ingufu, n'ibikorwa by'umunsi wose kugirango ugabanye ingaruka ku bidukikije.",
    emissionCalculatorKeywords:
        "Ishuzo ry'imiterere ya CO2 {{ Country_name }}, ishuzo ry'ibimera bya carbon {{ Country_name }}, imiterere ya gaz y'umwuka {{ Country_name }}, gukurikiza CO2 {{ Country_name }}, kugabanya imiterere {{ Country_name }}",
    bestSellerTitle:
        'Imodoka zigurishwa cyane mu mwaka wa {{ CurrentYear }} mu Rwanda - Auto24.rw',
    bestSellerDescMeta:
        'Menya imodoka zigurishwa cyane mu mwaka wa {{ CurrentYear }} mu Rwanda. Sura imodoka zifite amanota menshi hashingiwe ku kugurishwa, imikorere, n’ibitekerezo by’abakiriya. Shaka imodoka yawe ikurikira hamwe na Auto24.ma uyu munsi!',
    bestSellerKeywords:
        'Imodoka zigurishwa cyane {{ CurrentYear }}, Imodoka nziza {{ CurrentYear }}, imodoka zikunzwe {{ CurrentYear }}, Imodoka nziza mu Rwanda, Imodoka zigurishwa cyane mu Rwanda, Icyiciro cy’imodoka mu mwaka wa {{ CurrentYear }}, Imodoka nziza mu Rwanda, Isoko ry’imodoka {{ CurrentYear }}, Amakuru y’igurishwa ry’imodoka mu Rwanda',
    carLoanCalculatorTitle:
        "Kalkulateri y'Inguzanyo y'Imodoka mu Rwanda - Kubara Ubwishyu bwawe bwa Buri Kwezi n'Inyungu - Auto24.rw",
    carLoanCalculatorDescMeta:
        "Koresha kalkulateri yacu y'inguzanyo y'imodoka kugira ngo ubare ubwishyu bwawe bwa buri kwezi muri Rwanda, inyungu zose, n'igiciro rusange cy'inguzanyo. Teganya guhaha imodoka yawe ikurikira muri Rwanda ufite icyizere kandi ubone inguzanyo ibereye ingengo y'imari yawe",
    carLoanCalculatorKeywords:
        "Kalkulateri y'inguzanyo y'imodoka, Kalkulateri y'inguzanyo y'ikinyabiziga, Gutera inkunga ibinyabiziga, Inyungu ku nguzanyo y'imodoka, Ubwishyu bwa buri kwezi bw'imodoka, Kwishyura inguzanyo, Igikoresho cyo gutera inkunga imodoka, EMI y'inguzanyo y'imodoka, Kubara inguzanyo y'imodoka"
};

export default metaKinyarwanda;
