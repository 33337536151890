import { ALL_LANGUAGES, ROUTES } from '../../constants';
import { getDefaultLang } from '../../utils/translation';

const getLocalizedRoute = (
    path: string,
    locale: string,
    defaultLocale = getDefaultLang()
) => {
    return locale === defaultLocale ? path : `/${locale}${path}`;
};

export const routesEnglish = {
    [ROUTES.home]: getLocalizedRoute('/', 'en'),
    [ROUTES.slash]: getLocalizedRoute('/', 'en'),
    [ROUTES.buyCars]: getLocalizedRoute('/buy-cars', 'en'),
    [ROUTES.buy]: getLocalizedRoute('/buy', 'en'),
    [ROUTES.sellCar]: getLocalizedRoute('/sell-car', 'en'),
    [ROUTES.carFinance]: getLocalizedRoute('/car-finance', 'en'),
    [ROUTES.carInsurance]: getLocalizedRoute('/car-insurance', 'en'),
    [ROUTES.about]: getLocalizedRoute('/about', 'en'),
    [ROUTES.faqs]: getLocalizedRoute('/faqs', 'en'),
    [ROUTES.contactUs]: getLocalizedRoute('/contact-us', 'en'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'en'),
    [ROUTES.account]: getLocalizedRoute('/account', 'en'),
    [ROUTES.press]: getLocalizedRoute('/press', 'en'),

    [ROUTES.category]: getLocalizedRoute('/category', 'en'),

    [ROUTES.privacyPolicy]: getLocalizedRoute('/privacy-policy', 'en'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/terms-of-use', 'en'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/unsubscribe-blog', 'en'),
    [ROUTES.bodyType]: getLocalizedRoute('/body-type', 'en'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/buy-cars-under', 'en'),
    [ROUTES.requestCarPage]: getLocalizedRoute('/request-car', 'en'),
    [ROUTES.fuelPriceTracker]: getLocalizedRoute('/fuel-price-tracker', 'en'),
    [ROUTES.electricityPriceTracker]: getLocalizedRoute(
        '/electricity-price-tracker',
        'en'
    ),
    [ROUTES.currencyConverter]: getLocalizedRoute('/currency-converter', 'en'),
    [ROUTES.distanceConverter]: getLocalizedRoute('/distance-converter', 'en'),
    [ROUTES.taxCalculator]: getLocalizedRoute('/vehicle-tax-calculator', 'en'),
    [ROUTES.topCarSales]: getLocalizedRoute(
        `/best-selling-cars-of-the-year`,
        'en'
    ),
    [ROUTES.emissionEstimator]: getLocalizedRoute(
        '/co2-emission-estimator',
        'en'
    ),
    [ROUTES.drivingCostCalculator]: getLocalizedRoute(
        '/driving-cost-calculator',
        'en'
    ),
    [ROUTES.insuranceEstimator]: getLocalizedRoute(
        '/insurance-premium-estimator',
        'en'
    ),
    [ROUTES.carLoanCalculator]: getLocalizedRoute('/car-loan-calculator', 'en'),
    [ROUTES.technicalDocs]: getLocalizedRoute(
        '/vehicle-technical-documents',
        'en'
    ),

    [ROUTES.editProfile]: ROUTES.editProfile,
    [ROUTES.sellYourCar]: ROUTES.sellYourCar,
    [ROUTES.financing]: ROUTES.financing,
    [ROUTES.favourites]: ROUTES.favourites,
    [ROUTES.resetPassword]: ROUTES.resetPassword
};

export const routesFrench = {
    [ROUTES.home]: getLocalizedRoute('/', 'fr'),
    [ROUTES.slash]: getLocalizedRoute('/', 'fr'),
    [ROUTES.buyCars]: getLocalizedRoute('/acheter-voitures', 'fr'),
    [ROUTES.buy]: getLocalizedRoute('/acheter', 'fr'),
    [ROUTES.sellCar]: getLocalizedRoute('/vendre-voiture', 'fr'),
    [ROUTES.carFinance]: getLocalizedRoute('/financement-voiture', 'fr'),
    [ROUTES.carInsurance]: getLocalizedRoute('/assurance-voiture', 'fr'),
    [ROUTES.about]: getLocalizedRoute('/a-propos', 'fr'),
    [ROUTES.faqs]: getLocalizedRoute('/faq', 'fr'),
    [ROUTES.contactUs]: getLocalizedRoute('/contactez-nous', 'fr'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'fr'),
    [ROUTES.account]: getLocalizedRoute('/compte', 'fr'),
    [ROUTES.press]: getLocalizedRoute('/presse', 'fr'),
    [ROUTES.category]: getLocalizedRoute('/categorie', 'fr'),
    [ROUTES.privacyPolicy]: getLocalizedRoute(
        '/politique-de-confidentialite',
        'fr'
    ),
    [ROUTES.termsOfUse]: getLocalizedRoute('/conditions-dutilisation', 'fr'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/se-desabonner-blog', 'fr'),
    [ROUTES.bodyType]: getLocalizedRoute('/type-de-carrosserie', 'fr'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/acheter-voiture-sous', 'fr'),
    [ROUTES.fuelPriceTracker]: getLocalizedRoute('/suivi-prix-carburant', 'fr'),
    [ROUTES.electricityPriceTracker]: getLocalizedRoute(
        '/suivi-des-prix-de-l-electricite',
        'fr'
    ),
    [ROUTES.currencyConverter]: getLocalizedRoute(
        '/convertisseur-de-devises',
        'fr'
    ),
    [ROUTES.distanceConverter]: getLocalizedRoute(
        '/convertisseur-distance',
        'fr'
    ),
    [ROUTES.taxCalculator]: getLocalizedRoute(
        '/calculateur-taxe-vehicules',
        'fr'
    ),
    [ROUTES.topCarSales]: getLocalizedRoute(
        '/voitures-les-plus-vendues-de-lannee',
        'fr'
    ),
    [ROUTES.emissionEstimator]: getLocalizedRoute(
        '/estimateur-emissions-co2',
        'fr'
    ),
    [ROUTES.drivingCostCalculator]: getLocalizedRoute(
        '/calculateur-cout-conduite',
        'fr'
    ),
    [ROUTES.insuranceEstimator]: getLocalizedRoute(
        '/estimateur-prime-assurance',
        'fr'
    ),
    [ROUTES.carLoanCalculator]: getLocalizedRoute(
        '/calculateur-pret-automobile',
        'fr'
    ),
    [ROUTES.technicalDocs]: getLocalizedRoute(
        '/documents-techniques-des-vehicules',
        'fr'
    ),

    [ROUTES.editProfile]: '/modifier-profil',
    [ROUTES.sellYourCar]: '/vendre-votre-voiture',
    [ROUTES.financing]: '/financement',
    [ROUTES.favourites]: '/favoris',
    [ROUTES.resetPassword]: '/reinitialiser-mot-de-passe'
};

export const routesArabic = {
    [ROUTES.home]: getLocalizedRoute('/', 'ar'),
    [ROUTES.slash]: getLocalizedRoute('/', 'ar'),
    [ROUTES.buyCars]: getLocalizedRoute('/buy-cars', 'ar'),
    [ROUTES.buy]: getLocalizedRoute('/buy', 'ar'),
    [ROUTES.sellCar]: getLocalizedRoute('/sell-car', 'ar'),
    [ROUTES.carFinance]: getLocalizedRoute('/car-finance', 'ar'),
    [ROUTES.carInsurance]: getLocalizedRoute('/car-insurance', 'ar'),
    [ROUTES.about]: getLocalizedRoute('/about', 'ar'),
    [ROUTES.faqs]: getLocalizedRoute('/faqs', 'ar'),
    [ROUTES.contactUs]: getLocalizedRoute('/contact-us', 'ar'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'ar'),
    [ROUTES.account]: getLocalizedRoute('/account', 'ar'),
    [ROUTES.press]: getLocalizedRoute('/press', 'ar'),
    [ROUTES.category]: getLocalizedRoute('/category', 'ar'),
    [ROUTES.privacyPolicy]: getLocalizedRoute('/privacy-policy', 'ar'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/terms-of-use', 'ar'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/unsubscribe-blog', 'ar'),
    [ROUTES.bodyType]: getLocalizedRoute('/body-type', 'ar'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/buy-cars-under', 'ar'),
    [ROUTES.fuelPriceTracker]: getLocalizedRoute('/fuel-price-tracker', 'ar'),
    [ROUTES.electricityPriceTracker]: getLocalizedRoute(
        '/electricity-price-tracker',
        'ar'
    ),
    [ROUTES.currencyConverter]: getLocalizedRoute('/currency-converter', 'ar'),
    [ROUTES.distanceConverter]: getLocalizedRoute('/distance-converter', 'ar'),
    [ROUTES.taxCalculator]: getLocalizedRoute('/vehicle-tax-calculator', 'ar'),
    [ROUTES.topCarSales]: getLocalizedRoute(
        '/best-selling-cars-of-the-year',
        'ar'
    ),
    [ROUTES.emissionEstimator]: getLocalizedRoute(
        '/co2-emission-estimator',
        'ar'
    ),
    [ROUTES.drivingCostCalculator]: getLocalizedRoute(
        '/driving-cost-calculator',
        'ar'
    ),
    [ROUTES.insuranceEstimator]: getLocalizedRoute(
        '/insurance-premium-estimator',
        'ar'
    ),
    [ROUTES.carLoanCalculator]: getLocalizedRoute('/car-loan-calculator', 'ar'),
    [ROUTES.technicalDocs]: getLocalizedRoute(
        '/vehicle-technical-documents',
        'ar'
    ),

    [ROUTES.editProfile]: '/edit-profile',
    [ROUTES.sellYourCar]: '/sell-your-car',
    [ROUTES.financing]: '/financing',
    [ROUTES.favourites]: '/favourites',
    [ROUTES.resetPassword]: '/reset-password'
};

export const routesKinyarwanda = {
    [ROUTES.home]: getLocalizedRoute('/', 'rw'),
    [ROUTES.slash]: getLocalizedRoute('/', 'rw'),
    [ROUTES.buyCars]: getLocalizedRoute('/kugura-imodoka', 'rw'),
    [ROUTES.buy]: getLocalizedRoute('/kugura', 'rw'),
    [ROUTES.sellCar]: getLocalizedRoute('/gura-imodoka', 'rw'),
    [ROUTES.carFinance]: getLocalizedRoute('/finansi-iyomodoka', 'rw'),
    [ROUTES.carInsurance]: getLocalizedRoute('/ubwishingizi-bwimodoka', 'rw'),
    [ROUTES.about]: getLocalizedRoute('/turi-iki', 'rw'),
    [ROUTES.faqs]: getLocalizedRoute('/ibibazo-bikunze-gusubirwamo', 'rw'),
    [ROUTES.contactUs]: getLocalizedRoute('/tuvugishe', 'rw'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'rw'),
    [ROUTES.account]: getLocalizedRoute('/konti', 'rw'),
    [ROUTES.press]: getLocalizedRoute('/itangazamakuru', 'rw'),
    [ROUTES.category]: getLocalizedRoute('/kategori', 'rw'),
    [ROUTES.privacyPolicy]: getLocalizedRoute('/politik-pribacy', 'rw'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/amategeko-yakoreshejwe', 'rw'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute(
        '/kureka-kwiyandikisha-mu-blog',
        'rw'
    ),
    [ROUTES.bodyType]: getLocalizedRoute('/ubwoko-bwimodoka', 'rw'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/gura-imodoka-munsi-ya', 'rw'),
    [ROUTES.fuelPriceTracker]: getLocalizedRoute(
        '/ikurikiranabiciro-biyagaz',
        'rw'
    ),
    [ROUTES.electricityPriceTracker]: getLocalizedRoute(
        '/ikurikiranabiciro-byumuriro',
        'rw'
    ),
    [ROUTES.currencyConverter]: getLocalizedRoute('/guhindura-ifaranga', 'rw'),
    [ROUTES.distanceConverter]: getLocalizedRoute('/guhindura-intera', 'rw'),
    [ROUTES.taxCalculator]: getLocalizedRoute(
        '/umubarizi-umusoro-ku-modoka',
        'rw'
    ),
    [ROUTES.topCarSales]: getLocalizedRoute(
        `/imodoka-zigurishwa-cyane-mu-mwaka`,
        'rw'
    ),
    [ROUTES.emissionEstimator]: getLocalizedRoute(
        '/umugenagaciro-wo-gusohora-co2',
        'rw'
    ),
    [ROUTES.drivingCostCalculator]: getLocalizedRoute(
        '/umubarizi-igiciro-cyo-gutwara',
        'rw'
    ),
    [ROUTES.insuranceEstimator]: getLocalizedRoute(
        '/umugenagaciro-ubwishingizi',
        'rw'
    ),
    [ROUTES.carLoanCalculator]: getLocalizedRoute(
        '/umubarizi-inguzanyo-imodoka',
        'rw'
    ),
    [ROUTES.technicalDocs]: getLocalizedRoute(
        '/impapuro-za-tekiniki-zimodoka',
        'rw'
    ),

    [ROUTES.editProfile]: '/hindura-porofayilo',
    [ROUTES.sellYourCar]: '/gura-imodoka-yawe',
    [ROUTES.financing]: '/inguzanyo',
    [ROUTES.favourites]: '/ibikundwa',
    [ROUTES.resetPassword]: '/subiramo-ijambo-banga'
};

export const routesZulu = {
    [ROUTES.home]: getLocalizedRoute('/', 'zu'),
    [ROUTES.slash]: getLocalizedRoute('/', 'zu'),
    [ROUTES.buyCars]: getLocalizedRoute('/thenga-imoto', 'zu'),
    [ROUTES.buy]: getLocalizedRoute('/thenga', 'zu'),
    [ROUTES.sellCar]: getLocalizedRoute('/thengisa-imoto', 'zu'),
    [ROUTES.carFinance]: getLocalizedRoute('/imali-yemoto', 'zu'),
    [ROUTES.carInsurance]: getLocalizedRoute('/umshwalense-wemoto', 'zu'),
    [ROUTES.about]: getLocalizedRoute('/mayelana-nathi', 'zu'),
    [ROUTES.faqs]: getLocalizedRoute('/imibuzo-evame-ukuphindaphindwa', 'zu'),
    [ROUTES.contactUs]: getLocalizedRoute('/xhumana-nathi', 'zu'),
    [ROUTES.blog]: getLocalizedRoute('/ibhulogi', 'zu'),
    [ROUTES.account]: getLocalizedRoute('/akhawunti', 'zu'),
    [ROUTES.press]: getLocalizedRoute('/izindaba', 'zu'),
    [ROUTES.category]: getLocalizedRoute('/umkhakha', 'zu'),
    [ROUTES.privacyPolicy]: getLocalizedRoute('/umgomo-wobumfihlo', 'zu'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/imigomo-nokusetshenziswa', 'zu'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/khansela-ibhulogi', 'zu'),
    [ROUTES.bodyType]: getLocalizedRoute('/uhlobo-lwemoto', 'zu'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute(
        '/thenga-izimoto-ngaphansi-kwe',
        'zu'
    ),
    [ROUTES.fuelPriceTracker]: getLocalizedRoute(
        '/ukulandelela-amanani-petroli',
        'zu'
    ),
    [ROUTES.electricityPriceTracker]: getLocalizedRoute(
        '/umkhondo-wamanani-kagesi',
        'zu'
    ),
    [ROUTES.currencyConverter]: getLocalizedRoute('/ukuguqula-imali', 'zu'),
    [ROUTES.distanceConverter]: getLocalizedRoute('/ukuguqula-ibanga', 'zu'),
    [ROUTES.taxCalculator]: getLocalizedRoute(
        '/isibali-sentela-yezimoto',
        'zu'
    ),
    [ROUTES.topCarSales]: getLocalizedRoute(
        '/izimoto-ezithengiswa-kakhulu-zonyaka',
        'zu'
    ),
    [ROUTES.emissionEstimator]: getLocalizedRoute(
        '/umlinganisi-wokukhishwa-kwe-co2',
        'zu'
    ),
    [ROUTES.drivingCostCalculator]: getLocalizedRoute(
        '/isibali-sezindleko-zokushayela',
        'zu'
    ),
    [ROUTES.insuranceEstimator]: getLocalizedRoute(
        '/isilinganiso-semali-ye-inshuwa',
        'zu'
    ),
    [ROUTES.carLoanCalculator]: getLocalizedRoute(
        '/isibali-sesikoloto-semoto',
        'zu'
    ),
    [ROUTES.technicalDocs]: getLocalizedRoute(
        '/amadokhumenti-ezobuchwepheshe-ezimoto',
        'zu'
    ),

    [ROUTES.editProfile]: '/hlela-iphrofayela',
    [ROUTES.sellYourCar]: '/thengisa-imoto yakho',
    [ROUTES.financing]: '/imali-mboleko',
    [ROUTES.favourites]: '/izintandokazi',
    [ROUTES.resetPassword]: '/setha-iphasiwedi'
};

export const routesXhosa = {
    [ROUTES.home]: getLocalizedRoute('/', 'xh'),
    [ROUTES.slash]: getLocalizedRoute('/', 'xh'),
    [ROUTES.buyCars]: getLocalizedRoute('/thenga-iimoto', 'xh'),
    [ROUTES.buy]: getLocalizedRoute('/thenga', 'xh'),
    [ROUTES.sellCar]: getLocalizedRoute('/thengisa-imoto', 'xh'),
    [ROUTES.carFinance]: getLocalizedRoute('/imali-yemoto', 'xh'),
    [ROUTES.carInsurance]: getLocalizedRoute('/umshwalense-wemoto', 'xh'),
    [ROUTES.about]: getLocalizedRoute('/ngathi', 'xh'),
    [ROUTES.faqs]: getLocalizedRoute('/imibuzo-ebuzwayo', 'xh'),
    [ROUTES.contactUs]: getLocalizedRoute('/qhagamshelana-nathi', 'xh'),
    [ROUTES.blog]: getLocalizedRoute('/ibhulogi', 'xh'),
    [ROUTES.account]: getLocalizedRoute('/i-akhawunti', 'xh'),
    [ROUTES.press]: getLocalizedRoute('/imibhalo', 'xh'),
    [ROUTES.category]: getLocalizedRoute('/umkhakha', 'xh'),
    [ROUTES.privacyPolicy]: getLocalizedRoute('/umgomo-wobumfihlo', 'xh'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/imigomo-nokusebenzisa', 'xh'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute('/khansela-ibhulogi', 'xh'),
    [ROUTES.bodyType]: getLocalizedRoute('/uhlobo-lwemoto', 'xh'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute(
        '/thenga-iimoto-phantsi-kwe',
        'xh'
    ),
    [ROUTES.fuelPriceTracker]: getLocalizedRoute(
        '/umkhondo-wamaxabiso-epehlo',
        'xh'
    ),
    [ROUTES.electricityPriceTracker]: getLocalizedRoute(
        '/umkhondo-wamaxabiso-ombane',
        'xh'
    ),
    [ROUTES.currencyConverter]: getLocalizedRoute('/uguqulelo-lwemali', 'xh'),
    [ROUTES.distanceConverter]: getLocalizedRoute('/uguqula-umgama', 'xh'),
    [ROUTES.taxCalculator]: getLocalizedRoute(
        '/isibali-serhafu-yezithuthi',
        'xh'
    ),
    [ROUTES.topCarSales]: getLocalizedRoute(
        '/iimoto-ezithengiswa-kakhulu-zonyaka',
        'xh'
    ),
    [ROUTES.emissionEstimator]: getLocalizedRoute(
        '/umlinganiseli-wokukhutshwa-kwe-co2',
        'xh'
    ),
    [ROUTES.drivingCostCalculator]: getLocalizedRoute(
        '/isibali-sendleko-zokuqhuba',
        'xh'
    ),
    [ROUTES.insuranceEstimator]: getLocalizedRoute(
        '/umlinganiseli-werhafu-ye-inshorensi',
        'xh'
    ),
    [ROUTES.carLoanCalculator]: getLocalizedRoute(
        '/isibali-semali-mboleko-yemoto',
        'xh'
    ),
    [ROUTES.technicalDocs]: getLocalizedRoute(
        '/amaxwebhu-ezobuchwepheshe-zeemoto',
        'xh'
    ),

    [ROUTES.editProfile]: '/hlela-iphrofayela',
    [ROUTES.sellYourCar]: '/thengisa-imoto yakho',
    [ROUTES.financing]: '/uxhaso-mali',
    [ROUTES.favourites]: '/izinto-ezithandwayo',
    [ROUTES.resetPassword]: '/seta-fhorete'
};

export const routesWolof = {
    [ROUTES.home]: getLocalizedRoute('/', 'sn'),
    [ROUTES.slash]: getLocalizedRoute('/', 'sn'),
    [ROUTES.buyCars]: getLocalizedRoute('/goxor-ndoxal', 'sn'),
    [ROUTES.buy]: getLocalizedRoute('/goxor', 'sn'),
    [ROUTES.sellCar]: getLocalizedRoute('/vendre-voiture', 'sn'),
    [ROUTES.carFinance]: getLocalizedRoute('/financement-voiture', 'sn'),
    [ROUTES.carInsurance]: getLocalizedRoute('/assurance-voiture', 'sn'),
    [ROUTES.about]: getLocalizedRoute('/turu-iki', 'sn'),
    [ROUTES.faqs]: getLocalizedRoute('/ibungoro-bikunze-gusubirwamo', 'sn'),
    [ROUTES.contactUs]: getLocalizedRoute('/tuvugishe', 'sn'),
    [ROUTES.blog]: getLocalizedRoute('/blog', 'sn'),
    [ROUTES.account]: getLocalizedRoute('/konti', 'sn'),
    [ROUTES.press]: getLocalizedRoute('/itangazamakuru', 'sn'),
    [ROUTES.category]: getLocalizedRoute('/kategori', 'sn'),
    [ROUTES.privacyPolicy]: getLocalizedRoute('/politik-pribacy', 'sn'),
    [ROUTES.termsOfUse]: getLocalizedRoute('/amategeko-yakoreshejwe', 'sn'),
    [ROUTES.unsubscribeBlog]: getLocalizedRoute(
        '/kureka-kwiyandikisha-mu-blog',
        'sn'
    ),
    [ROUTES.bodyType]: getLocalizedRoute('/rudzi-rwemota', 'sn'),
    [ROUTES.buyCarsUnder]: getLocalizedRoute('/tenga-motokari-pasi-pe', 'sn'),
    [ROUTES.fuelPriceTracker]: getLocalizedRoute(
        '/kutevera-mutengo-wemafuta',
        'sn'
    ),
    [ROUTES.electricityPriceTracker]: getLocalizedRoute(
        '/suivi-deretu-korenti',
        'sn'
    ),
    [ROUTES.currencyConverter]: getLocalizedRoute('/shanduko-yemari', 'sn'),
    [ROUTES.distanceConverter]: getLocalizedRoute('/shandura-chinhambwe', 'sn'),
    [ROUTES.taxCalculator]: getLocalizedRoute(
        '/wannekaay-galag-u-watiir',
        'sn'
    ),
    [ROUTES.topCarSales]: getLocalizedRoute(
        '/njaaykat-yi-gen-a-jefandikoo-ci-annee',
        'sn'
    ),
    [ROUTES.emissionEstimator]: getLocalizedRoute('/seetlukaay-gaz-co2', 'sn'),
    [ROUTES.drivingCostCalculator]: getLocalizedRoute(
        '/wannekaay-njegu-watu',
        'sn'
    ),
    [ROUTES.insuranceEstimator]: getLocalizedRoute(
        '/seetlukaay-njegu-asuraas',
        'sn'
    ),
    [ROUTES.carLoanCalculator]: getLocalizedRoute(
        '/wannekaay-lebu-watiir',
        'sn'
    ),
    [ROUTES.technicalDocs]: getLocalizedRoute(
        '/dokkument-yu-teknikal-ji-motos',
        'sn'
    ),

    [ROUTES.editProfile]: '/hindura-porofayilo',
    [ROUTES.sellYourCar]: '/gura-imodoka-yawe',
    [ROUTES.financing]: '/mali',
    [ROUTES.favourites]: '/dithoto',
    [ROUTES.resetPassword]: '/seta-fhorete'
};

// For multiple languages
const getRoutesObj = (route: string) => ({
    [ROUTES.home]: `/${route}`,
    [ROUTES.slash]: `/${route}`,
    [ROUTES.buyCars]: `/${route}${ROUTES.buyCars}`,
    [ROUTES.buy]: `/${route}${ROUTES.buy}`,
    [ROUTES.sellCar]: `/${route}${ROUTES.sellCar}`,
    [ROUTES.carFinance]: `/${route}${ROUTES.carFinance}`,
    [ROUTES.carInsurance]: `/${route}${ROUTES.carInsurance}`,
    [ROUTES.about]: `/${route}${ROUTES.about}`,
    [ROUTES.faqs]: `/${route}${ROUTES.faqs}`,
    [ROUTES.contactUs]: `/${route}${ROUTES.contactUs}`,
    [ROUTES.blog]: `/${route}${ROUTES.blog}`,
    [ROUTES.account]: `/${route}${ROUTES.account}`,

    [ROUTES.category]: `/${route}${ROUTES.category}`,

    [ROUTES.privacyPolicy]: `/${route}${ROUTES.privacyPolicy}`,
    [ROUTES.termsOfUse]: `/${route}${ROUTES.termsOfUse}`,
    [ROUTES.unsubscribeBlog]: `/${route}${ROUTES.unsubscribeBlog}`,

    [ROUTES.editProfile]: `/${ROUTES.editProfile}`,
    [ROUTES.sellYourCar]: `/${ROUTES.sellYourCar}`
});

const routesLang = {
    [ALL_LANGUAGES.english.value]: routesEnglish,
    [ALL_LANGUAGES.french.value]: routesFrench,
    [ALL_LANGUAGES.arabic.value]: routesArabic,
    [ALL_LANGUAGES.kinyarnwanda.value]: routesKinyarwanda,
    [ALL_LANGUAGES.zulu.value]: routesZulu,
    [ALL_LANGUAGES.xhosa.value]: routesXhosa,
    [ALL_LANGUAGES.wolof.value]: routesWolof

    // FOr others routes add like below note: don't use this method as it is giving issues
    // [ALL_LANGUAGES.xhosa.value]: getRoutesObj(ALL_LANGUAGES.xhosa.value),
    // [ALL_LANGUAGES.wolof.value]: getRoutesObj(ALL_LANGUAGES.wolof.value),
};

export default routesLang;
