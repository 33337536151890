import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { FETCH_CHEAPEST_CAR_LIST, FETCH_ELECTRIC_CAR_LIST, FETCH_IMPORTED_CAR_LIST, FETCH_NEW_CAR_LIST, GET_CARS_BY_BUDGET } from '../../helpers/constants';
import { request } from '../../helpers/request';
import { ICarListingPagination } from '../../interface/car';
import { ADD_NEW_CARS_LIST } from '../types/carListTypes';

export const fetchNewCarList = (dispatch: Dispatch, params?: ICarListingPagination) => {
    return request
        .getNewCarList(params)
        .then((response: any) => {
            dispatch({
                type: FETCH_NEW_CAR_LIST,
                payload: response.data ?? []
            });
            dispatch({ type: ADD_NEW_CARS_LIST, payload: response?.data });
            return response;
        })

        .catch((err: AxiosError | Error) => { return err });
};

export const fetchElectricCarList = (dispatch: Dispatch, params?: ICarListingPagination) => {
    return request
        .getElectricCarList(params)
        .then((response: any) => {
            dispatch({
                type: FETCH_ELECTRIC_CAR_LIST,
                payload: response.data ?? []
            });
            return response;
        })
        .catch((err: AxiosError | Error) => { return err });
};

export const fetchImportedCarList = (dispatch: Dispatch, params?: ICarListingPagination) => {
    return request
        .getImportedCarList(params)
        .then((response: any) => {
            dispatch({
                type: FETCH_IMPORTED_CAR_LIST,
                payload: response.data ?? []
            });
            return response;
        })
        .catch((err: AxiosError | Error) => { return err });
};

export const fetchCheapestCarList = (dispatch: Dispatch, maxPrice: number, params?: ICarListingPagination) => {
    return request
        .getCheapestCarList(maxPrice, params)
        .then((response: any) => {
            dispatch({
                type: FETCH_CHEAPEST_CAR_LIST,
                payload: response.data ?? []
            });
            return response;
        })
        .catch((err: AxiosError | Error) => { return err });
};

export const fetchCarsByBudget = (dispatch: Dispatch, maxPrice?: number, minPrice?: number, params?: ICarListingPagination) => {
    let requestPromise;
    if (maxPrice && minPrice) requestPromise = request.getCarListByPriceRange(minPrice, maxPrice, params);
    else if (maxPrice) requestPromise = request.getCheapestCarList(maxPrice, params);
    else if (minPrice) requestPromise = request.getCarListByMinPrice(minPrice, params);

    return requestPromise?.then((response: any) => {
        dispatch({
            type: GET_CARS_BY_BUDGET,
            payload: response.data ?? {
                cars: [],
                total: 0
            }
        })
        return response;
    }).catch((err: AxiosError | Error) => {
        if(err instanceof AxiosError){
            // when no cars exists for given price range
            if(err.response?.status === 404){
                dispatch({
                    type: GET_CARS_BY_BUDGET,
                    payload: {
                        cars: [],
                        total: 0
                    }
                })
                return err.response
            }
        }
        return err;
    });
}