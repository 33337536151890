import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BannerSection from "./BannerSection";

const NoMatchingCarCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const requestClickHandler = () => {
        navigate(t('/request-car', { ns: 'routes' }))
    }
    return (
        <BannerSection
            title={t('opps')}
            subTitle={t('noCarSection')}
            banner_image_url="/images/dead-car.webp"
            buttonText={t('requestCar')}
            onButtonClick={requestClickHandler}
            additional_section_class="no-matching-car-banner"
        />
    )
}

export default NoMatchingCarCard;