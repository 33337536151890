import { ReactNode } from 'react';
//
import useLanguageDir from '../utils/Hooks/useLanguageDir';

interface IBannerSectionProps {
    title: ReactNode;
    subTitle: String;
    buttonText: String;
    onButtonClick: () => void;
    banner_image_url: string;
    additional_section_class?: string;
}

const BannerSection = ({
    title,
    subTitle,
    buttonText,
    onButtonClick,
    banner_image_url,
    additional_section_class = ''
}: IBannerSectionProps) => {
    const { language_dir } = useLanguageDir();

    return (
        <section className="bg-white">
            <div className={`banner-info-section ${additional_section_class}`}>
                <div className="banner-info-circle"></div>
                <div className="banner-info-container">
                    <div className="banner-info-section-content">
                        <h2
                            className="banner-info-section-title"
                            dir={language_dir}
                        >
                            {title}
                        </h2>
                        <p
                            className="banner-info-section-subtitle"
                            dir={language_dir}
                        >
                            {subTitle}
                        </p>
                        <button
                            className="banner-info-link"
                            onClick={onButtonClick}
                        >
                            {buttonText}
                        </button>
                    </div>
                    <div>
                        <img
                            className="banner-info-image"
                            src={banner_image_url}
                            alt="Two People"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerSection;
