interface IconPropsInterface {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
}

const Speedometer = (props: IconPropsInterface) => {
    return (
        <svg
            width={props.width ?? "24"}
            height={props.height ?? "24"}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_12276_2380)">
                <path d="M8.00004 1.33337C11.682 1.33337 14.6667 4.31804 14.6667 8.00004C14.6667 11.682 11.682 14.6667 8.00004 14.6667C4.31804 14.6667 1.33337 11.682 1.33337 8.00004C1.33337 4.31804 4.31804 1.33337 8.00004 1.33337ZM8.00004 2.66671C5.05471 2.66671 2.66671 5.05471 2.66671 8.00004C2.66671 10.9454 5.05471 13.3334 8.00004 13.3334C10.9454 13.3334 13.3334 10.9454 13.3334 8.00004C13.3334 5.05471 10.9454 2.66671 8.00004 2.66671ZM10.5554 4.89137C10.7134 4.78071 10.928 4.79937 11.064 4.93604C11.2 5.07204 11.2174 5.28671 11.106 5.44271C9.65271 7.47337 8.85271 8.56137 8.70737 8.70737C8.31671 9.09737 7.68337 9.09737 7.29271 8.70737C6.90271 8.31671 6.90271 7.68337 7.29271 7.29271C7.54204 7.04404 8.62937 6.24337 10.5554 4.89137ZM11.6667 7.33337C12.0347 7.33337 12.3334 7.63204 12.3334 8.00004C12.3334 8.36804 12.0347 8.66671 11.6667 8.66671C11.2987 8.66671 11 8.36804 11 8.00004C11 7.63204 11.2987 7.33337 11.6667 7.33337ZM4.33337 7.33337C4.70137 7.33337 5.00004 7.63204 5.00004 8.00004C5.00004 8.36804 4.70137 8.66671 4.33337 8.66671C3.96537 8.66671 3.66671 8.36804 3.66671 8.00004C3.66671 7.63204 3.96537 7.33337 4.33337 7.33337ZM5.87871 4.93604C6.13871 5.19604 6.13871 5.61804 5.87871 5.87871C5.61871 6.13871 5.19604 6.13871 4.93604 5.87871C4.67604 5.61871 4.67604 5.19604 4.93604 4.93604C5.19604 4.67604 5.61804 4.67604 5.87871 4.93604ZM8.00004 3.66671C8.36804 3.66671 8.66671 3.96537 8.66671 4.33337C8.66671 4.70137 8.36804 5.00004 8.00004 5.00004C7.63204 5.00004 7.33337 4.70137 7.33337 4.33337C7.33337 3.96537 7.63204 3.66671 8.00004 3.66671Z" fill={props.stroke ?? "#8B8B8B"} />
            </g>
            <defs>
                <clipPath id="clip0_12276_2380">
                    <rect width={props.width ?? "24"} height={props.height ?? "24"} fill={props.fill ?? "white"} />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Speedometer;