import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"

const useLanguageDir = () => {
    const {i18n} = useTranslation();
    const [language_dir, setLanguageDir] = useState('ltr');

    useEffect(()=>{
        const new_language_dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
        setLanguageDir(new_language_dir);
    }, [i18n.language])

    return {language_dir};
}

export default useLanguageDir;