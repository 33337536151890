
import { useNavigate } from "react-router-dom";
import { IMAGE_URL } from "../constants";
import { formatDateString } from "../utils/Helper/dateFormatter";
import { addImageFallback } from "../utils/Helper/ImagePlaceholder";
import useLanguageDir from "../utils/Hooks/useLanguageDir";
import { useTranslation } from "react-i18next";

interface IBlogCardProps {
    image: string,
    type: string,
    title: string,
    date: string,
    authors: string,
    slug: string
}

export const BlogCard = ({image, type, title, date, slug}: IBlogCardProps) => {
    const {language_dir} = useLanguageDir();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleBlogClick = () => {
        navigate(`${t('/blog', {ns: 'routes'})}/${slug}`)
    }
    return (
        <div className="blog-section-card" onClick={handleBlogClick}>
            <div className="blog-image">
                <img
                    src={IMAGE_URL + image}
                    alt="cars"
                    onError={addImageFallback}
                    loading="lazy"
                />
            </div>
            <div className="blog-content">
                <span
                    className="blog-type text-capitalize text-truncate"
                    dir={language_dir}
                >
                    {type}
                </span>
                <h2 className="blog-title text-truncate" dir={language_dir}>{title}</h2>
                <span className="blog-date">{formatDateString(date)}</span>
            </div>
        </div>
    );
};
