import { formatDateString } from "../utils/Helper/dateFormatter";
import useLanguageDir from "../utils/Hooks/useLanguageDir";
import StarRating from "./StartRating";

interface ICustomerReviewCardProps {
    rating: number,
    reviewer: string,
    created_date?: string,
    review: string,
    source: string
}
const CustomerReviewCard = ({ review, rating, created_date, reviewer, source }: ICustomerReviewCardProps) => {
    const { language_dir } = useLanguageDir();
    return (
        <div className="review-card">
            <div className="review-header">
                <div className="review-title">
                    <div className="review-rating">
                        <span>{rating}</span>
                        <StarRating rating={rating} />

                    </div>
                    <img src={`/images/logos/${source}.svg`} alt="google" height="24px" />
                </div>
                <div className="review-content">
                    <p dir={language_dir}>{review}</p>
                </div>
            </div>
            <div className="review-detail">
                <div className="review-name text-capitalize">{reviewer}</div>
                <div className="review-date">{formatDateString(created_date || "")}</div>
            </div>
        </div>
    )
}

export default CustomerReviewCard;