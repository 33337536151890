import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import '../src/styles/main.scss';

import { getCarBrandsList } from './redux/actions/carBrandsAction';
import { getWishlistData } from './redux/actions/wishlistAction';
import { ICombineReducerType } from './redux/reducers';

import Cookies from 'js-cookie';
import SuspenseLoading from './components/ui/SuspenseLoading';
import { COUNTRY_TOP_LEVEL_DOMAIN, ROUTES } from './constants';
import i18n from './localization';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import {
    DEFAULT_LANG,
    languagesList,
    getTopLevelDomain,
    getTrackingId
} from './utils/translation';
import ReactGA from 'react-ga4';
import Clarity from '@microsoft/clarity';
import MessagePlus from './components/ui/MessagePlus';
import Brand from './pages/Brand';
import BodyType from './pages/BodyType';
import PriceRangeListing from './pages/PriceRangeListing';
import DistanceConverter from './pages/DistanceConverter';
import DrivingCostCalculator from './pages/DrivingCostCalculator';
import EmissionCalculator from './pages/EmissionCalculator';
import BestSellingCars from './pages/BestSellingCars';
import CarLoanCalculator from './pages/CarLoanCalculator';

const Home = lazy(() => import('./pages/Home'));
// const DetailPage = lazy(() => import('./pages/DetailPage'));
const DetailPage = lazy(() => import('./pages/CarDetailPage'));
const SellPage = lazy(() => import('./pages/SellPage'));
const VehicleTechnicalDocs = lazy(() => import('./pages/VehicleTechnicalDocs'));
const Finance = lazy(() => import('./pages/Finance'));
const Insure = lazy(() => import('./pages/Insure'));
const About = lazy(() => import('./pages/About'));
const Faq = lazy(() => import('./pages/Faq'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
// const Listingpage = lazy(() => import('./pages/Listingpage'));
const CarListingPage = lazy(() => import('./pages/CarListingPage'));
const Blogs = lazy(() => import('./pages/Blogs'));
const Account = lazy(() => import('./pages/Account'));
const Iminterested = lazy(() => import('./pages/Interest'));
const Categories = lazy(() => import('./pages/Categories'));
const Tab = lazy(() => import('./pages/Tab'));
const BlogDetails = lazy(() => import('./pages/BlogDetails'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));
const OurTeam = lazy(() => import('./pages/OurTeam'));
const FindCarPricePage = lazy(() => import('./pages/FindCarPrice'));
const PressPage = lazy(() => import('./pages/PressPage'));
const Notfound = lazy(() => import('./pages/Notfound'));
const AccountVerification = lazy(
    () => import('./components/AuthFlow/AccountVerfication')
);
const UnsubscribeBlog = lazy(() => import('./components/UnSubscribeBlog'));
const ForgetPasword = lazy(
    () => import('./components/AuthFlow/ForgotPassword')
);
// const WhatsAppChatBox = lazy(() => import('./components/ui/WhatssAppChatBot'));
const ProtectedRoute = lazy(
    () => import('./components/AuthFlow/ProtectedRoute')
);
const CarFinanceForm = lazy(() => import('./pages/CarFinanceForm'));
const RequestCarPage = lazy(() => import('./pages/RequestCarPage'));
const FuelPriceTracker = lazy(() => import('./pages/FuelPriceTracker'));
const ElectricityPriceTracker = lazy(
    () => import('./pages/ElectricityPriceTracker')
);
const CurrencyConverter = lazy(() => import('./pages/CurrencyConverter'));

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
    const dispatch = useDispatch();
    const { isloggedIn, user } = useSelector(
        (state: ICombineReducerType) => state.auth
    );
    !Cookies.get('accessToken') && localStorage.removeItem('user');
    const { t } = useTranslation(['routes']);
    const { t: tEn } = useTranslation(['routeTrans']);

    const topLevelDomain = getTopLevelDomain();

    const { gaId, clarityProjectId } = getTrackingId();

    // Initialize both Google Analytics and Microsoft Clarity
    useEffect(() => {
        // Initialize Google Analytics
        gaId && ReactGA.initialize(gaId);

        // Initialize Microsoft Clarity
        clarityProjectId && Clarity.init(clarityProjectId);
    }, []);

    // Track page views for both Google Analytics and Microsoft Clarity
    useEffect(() => {
        // Track with Google Analytics using the location object
        gaId &&
            ReactGA.send({
                hitType: 'pageview',
                page: window.location.pathname + window.location.search
            });
    }, [location]);

    // When user manually tries to navigate for different language
    useEffect(() => {
        const langList = languagesList();
        let isNoLangSelected = true;
        Object.values(langList).forEach((item) => {
            if (location.pathname.includes(`/${item}`)) {
                isNoLangSelected = false;
                i18n.changeLanguage(item);
            }
        });
        if (isNoLangSelected) {
            if (i18n.language === 'en') i18n.changeLanguage('en');
            else i18n.changeLanguage(DEFAULT_LANG);
        }
    }, []);

    useEffect(() => {
        if (isTablet && location.pathname === t('/account')) {
            navigate(
                `${t('/account', { ns: 'routes' })}${t('/edit-profile', {
                    ns: 'routes'
                })}`
            );
        }
    }, [isTablet]);
    useEffect(() => {
        getCarBrandsList(dispatch, true);
    }, [dispatch]);
    useEffect(() => {
        if (isloggedIn) {
            getWishlistData(dispatch);
        }
    }, [isloggedIn]);
    const currentPath = useLocation();
    const translate = (url: string) => {
        return t(url, { lng: i18n.language });
    };

    useEffect(() => {
        const languagesObj = languagesList();
        const pathSegments = currentPath.pathname.split('/').filter(Boolean); // Split path into segments
        let url = '',
            resturl = '';

        // Detect if the first segment is a language code
        const isLangPrefix = Object.values(languagesObj).includes(
            pathSegments[0]
        );

        if (isLangPrefix) {
            url = pathSegments[1] || ''; // Extract main page
            resturl = pathSegments.slice(2).join('/'); // Remaining path
        } else {
            url = pathSegments[0] || ''; // Extract main page
            resturl = pathSegments.slice(1).join('/');
        }

        // Convert current route from any language to English
        const englishUrl = tEn(url, { lng: 'en' });

        // Translate English to the target language
        const translatedUrl = t(`/${englishUrl}`, { lng: i18n.language });

        // Construct the final path
        let newPath = i18n.language === DEFAULT_LANG ? '' : '';
        if (translatedUrl) newPath += `${translatedUrl}`;
        if (resturl) newPath += `/${resturl}`;
        if (currentPath.search) newPath += currentPath.search;

        // Navigate only if the path has changed
        if (newPath !== currentPath.pathname) {
            navigate(newPath, { replace: true });
        }
    }, [
        currentPath.pathname,
        currentPath.search,
        i18n.language,
        navigate,
        t,
        tEn
    ]);

    const [isMessagePlusInitialized, setIsMessagePlusInitialized] = useState(false);

    useEffect(() => {
        if (!isMessagePlusInitialized) {
            // To prevent multiple initialization of MessagePlus
            setIsMessagePlusInitialized(true);
        }
    }, [isMessagePlusInitialized]);
    return (
        <Suspense fallback={<SuspenseLoading />}>
            <div className="App">
                <Routes>
                    <Route path={translate(ROUTES.home)} element={<Home />} />
                    <Route
                        path={translate(ROUTES.buyCars)}
                        element={<CarListingPage />}
                    />

                    <Route
                        path={`${translate(ROUTES.buyCarsUnder)}/:priceRange`}
                        element={<PriceRangeListing />}
                    />

                    <Route
                        path={`${translate(ROUTES.buyCars)}/:brandName`}
                        element={<Brand />}
                    />
                    <Route
                        path={`${translate(ROUTES.bodyType)}/:bodyTypeName`}
                        element={<BodyType />}
                    />

                    <Route
                        path={`${translate(
                            ROUTES.buy
                        )}/:brand/:model/:year/:price/:id`}
                        element={<DetailPage />}
                    />

                    <Route
                        path={translate(ROUTES.sellCar)}
                        element={<SellPage />}
                    />
                    <Route
                        path={translate(ROUTES.technicalDocs)}
                        element={<VehicleTechnicalDocs />}
                    />
                    <Route
                        path={translate(ROUTES.carFinance)}
                        element={<Finance />}
                    />
                    <Route
                        path={translate(ROUTES.carInsurance)}
                        element={<Insure />}
                    />
                    <Route path={translate(ROUTES.about)} element={<About />} />
                    <Route path={translate(ROUTES.faqs)} element={<Faq />} />
                    <Route
                        path={translate(ROUTES.contactUs)}
                        element={<ContactUs />}
                    />
                    <Route path={translate(ROUTES.blog)} element={<Blogs />} />
                    <Route
                        path={`${translate(ROUTES.account)}/:key`}
                        element={
                            <ProtectedRoute user={user}>
                                <Account />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path={`${translate(ROUTES.imInterested)}/:id`}
                        element={<Iminterested />}
                    />
                    <Route
                        path={`${translate(ROUTES.category)}/:id`}
                        element={<Categories />}
                    />
                    <Route path={translate(ROUTES.account)} element={<Tab />} />

                    <Route
                        path={`${translate(ROUTES.blog)}/:id`}
                        element={<BlogDetails />}
                    />
                    <Route
                        path="/user/:token"
                        element={<AccountVerification />}
                    />
                    <Route
                        path={translate(ROUTES.privacyPolicy)}
                        element={<PrivacyPolicy />}
                    />
                    <Route
                        path={translate(ROUTES.termsOfUse)}
                        element={<TermsOfUse />}
                    />
                    {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.asterdio && ( //Only for dev server
                        <Route
                            path={translate(ROUTES.ourTeam)}
                            element={<OurTeam />}
                        />
                    )}
                    {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.asterdio && ( //Only for dev server
                        <Route
                            path={translate(ROUTES.findCarPrice)}
                            element={<FindCarPricePage />}
                        />
                    )}
                    <Route
                        path={translate(ROUTES.press)}
                        element={<PressPage />}
                    />
                    <Route
                        path={`${translate(ROUTES.unsubscribeBlog)}/:token`}
                        element={<UnsubscribeBlog />}
                    />
                    <Route
                        path="/forgot-password/:token"
                        element={<ForgetPasword />}
                    />
                    {topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza && ( //Only for coza
                        <Route
                            path={translate(ROUTES.carFinanceForm)}
                            element={<CarFinanceForm />}
                        />
                    )}
                    <Route
                        path={`${translate(ROUTES.requestCarPage)}`}
                        element={<RequestCarPage />}
                    />
                    <Route
                        path={translate(ROUTES.fuelPriceTracker)}
                        element={<FuelPriceTracker />}
                    />
                    <Route
                        path={translate(ROUTES.electricityPriceTracker)}
                        element={<ElectricityPriceTracker />}
                    />
                    <Route
                        path={translate(ROUTES.currencyConverter)}
                        element={<CurrencyConverter />}
                    />
                    <Route
                        path={translate(ROUTES.distanceConverter)}
                        element={<DistanceConverter />}
                    />
                    <Route
                        path={translate(ROUTES.drivingCostCalculator)}
                        element={<DrivingCostCalculator />}
                    />
                    <Route
                        path={translate(ROUTES.emissionEstimator)}
                        element={<EmissionCalculator />}
                    />
                    <Route
                        path={translate(ROUTES.topCarSales)}
                        element={<BestSellingCars />}
                    />
                    <Route
                        path={translate(ROUTES.carLoanCalculator)}
                        element={<CarLoanCalculator />}
                    />
                    <Route path="*" element={<Notfound />} />
                </Routes>
            </div>
            {/* <WhatsAppChatBox /> */}
            {isMessagePlusInitialized && <MessagePlus />}
        </Suspense>
    );
}

export default App;
