import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BannerSection from './BannerSection';

const SellMyCarSection = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const toSellCarPage = () => {
        navigate(t('/sell-car', { ns: 'routes' }));
    };
    return (
        <div className="bg-white">
            <BannerSection
                title={t('sellYourVehicle')}
                subTitle={t('sellYourVehicleSubtitle')}
                banner_image_url="/images/driving-car.webp"
                buttonText={t('sellMyCar')}
                onButtonClick={toSellCarPage}
                additional_section_class="sell-my-car-section"
            />
        </div>
    );
};

export default SellMyCarSection;
