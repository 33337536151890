import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { FETCH_FEATURED_CARS } from '../../helpers/constants';
import { request } from '../../helpers/request';
import { ADD_RECENT_CARS_LIST } from '../types/carListTypes';
import { ICarListingPagination } from '../../interface/car';

export const fetchFeaturedCars = (
    dispatch: Dispatch,
    params?: ICarListingPagination
) => {
    return request
        .getFeaturedCars(params)
        .then((response: any) => {
            dispatch({
                type: FETCH_FEATURED_CARS,
                payload: response.data ?? []
            });
            dispatch({ type: ADD_RECENT_CARS_LIST, payload: response?.data });
            return response;
        })

        .catch((err: AxiosError | Error) => {
            return err;
        });
};
