import { ReactNode, useEffect, useRef } from "react"

interface IClickOutside {
    is_popup_visible: boolean,
    hideModal: () => void,
    children: ReactNode
}
const ClickOutside = ({ is_popup_visible, hideModal, children }: IClickOutside) => {
    const divRef = useRef<HTMLDivElement>(null);

    function handleClickOutside(event: MouseEvent){
        if(divRef.current && !divRef.current.contains(event.target as Node)){
            hideModal()
        }
    }

    useEffect(()=>{
        if(is_popup_visible){
            document.addEventListener("mousedown", handleClickOutside);
        }else {
            document.removeEventListener("mousedown", hideModal);
        }

        return () => {
            document.removeEventListener("mousedown", hideModal);
        }
    }, [is_popup_visible, hideModal])

    return <div ref={divRef}>
        {children}
    </div>

}


export default ClickOutside;