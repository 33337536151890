import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CalendarBlank, MapPin } from 'phosphor-react';
//
import { getContactInfo } from '../redux/actions/contactActions';
import { IContactInformationType } from '../redux/types/contactInformationType';
import config from '../utils/config';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';
import useLanguageDir from '../utils/Hooks/useLanguageDir';
import { NextChevron } from './icons/common.icons';

interface OpeningField {
    openingDay: string;
    openingTime: string;
    _id: string;
}

interface Location {
    address: string;
    mapLink: string;
    openingFields: OpeningField[];
    locationTitle: string;
    imageUrl: string;
    _id: string;
}
const FindUs = () => {
    const { t, i18n } = useTranslation(['common']);
    const { language_dir } = useLanguageDir();
    const dispatch = useDispatch();

    const { location } = useSelector(
        (state: IContactInformationType) => state.contact.contact
    );

    useEffect(() => {
        !location?.length && getContactInfo(dispatch);
    }, []);

    return (
        <>
            <div className="full-section" id="find-us">
                {location?.length > 1 ? (
                    <div className="why-us-section-container ">
                        <div className='padding'>
                            <h2
                                className={`title ${i18n.language === 'ar' && 'textAlignLeft'
                                    }`}
                                dir={language_dir}
                            >
                                {t('common:whereFindUs')}
                            </h2>
                            <p
                                className={`subtitle ${i18n.language === 'ar' && 'textAlignLeft'
                                    }`}
                                dir={language_dir}
                            >
                                {t('common:aroundLocationText')}
                            </p>
                        </div>

                        <div
                            className={`why-us-section no-scrollbar padding location-container-${location?.length}`}
                        >
                            {location?.map((loc: Location, index: number) => (
                                <div
                                    key={`${loc._id}-${index}`}
                                    className="container"
                                >
                                    <div className="picture">
                                        <img
                                            src={`${config.backendUrl}/${loc.imageUrl}`}
                                            onError={addImageFallback}
                                            className="img-fluids"
                                            alt={`${loc.locationTitle}`}
                                            loading="lazy"
                                        />
                                        <div className="overlay">
                                            <img
                                                src="/images/google-map.webp"
                                                className="static-image"
                                                alt="Static Placeholder"
                                                loading="lazy"
                                            />
                                            <a
                                                href={loc.mapLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="location-url text-uppercase"
                                            >
                                                {t('common:locateUs')}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="location">
                                        <div className="title text-capitalize mb-0 text-truncate">
                                            {loc.locationTitle}
                                        </div>
                                        <div className="location-details">
                                            <span className="location-address">
                                                <MapPin
                                                    size={20}
                                                    weight="bold"
                                                    color="#595959"
                                                    className="map-pin"
                                                />
                                                <span className='text-truncate location-title'>{loc.address}</span>

                                            </span>
                                            <span className="hour-text">
                                                <CalendarBlank
                                                    size={20}
                                                    weight="bold"
                                                    color="#595959"
                                                    className="calender"
                                                />
                                                <span className='location-title text-truncate'>
                                                    {`${loc?.openingFields?.[0]
                                                        ?.openingDay || ''
                                                        } (${loc?.openingFields?.[0]
                                                            ?.openingTime || ''
                                                        })`}
                                                </span>
                                            </span>
                                        </div>
                                        <a
                                            href={loc.mapLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="location-url-mobile text-uppercase"
                                        >
                                            {t('common:locateUs')}
                                            <NextChevron />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="location-container padding">
                        <div className="location-header">
                            <h2
                                className={`title ${i18n.language === 'ar' && 'textAlignLeft'
                                    }`}
                                dir={language_dir}
                            >
                                {t('common:whereFindUs')}
                            </h2>
                            <p
                                className={`subtitle ${i18n.language === 'ar' && 'textAlignLeft'
                                    }`}
                                dir={language_dir}
                            >
                                {t('youcanfindusaroundthislocation', {
                                    ns: 'hero'
                                })}
                            </p>
                        </div>
                        <div className="location">
                            <div className="location-card ">
                                <div className="location-card-image">
                                    <img
                                        src={`${config.backendUrl}/${location?.[0].imageUrl}`}
                                        alt={location?.[0].locationTitle}
                                        onError={addImageFallback}
                                        loading="lazy"
                                    />
                                    <div className="overlay">
                                        <img
                                            src="/images/google-map.webp"
                                            className="static-image"
                                            alt="Static Placeholder"
                                            loading="lazy"
                                        />
                                        <a
                                            href={location?.[0].mapLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="location-url text-uppercase"
                                        >
                                            {t('common:locateUs')}
                                        </a>
                                    </div>
                                </div>
                                <div className="location-card-content">
                                    <a
                                        href={location?.[0].mapLink}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <h3 className="content-title text-truncate">
                                            {location?.[0].locationTitle}
                                        </h3>
                                        <span className="content-description">
                                            <MapPin
                                                size={20}
                                                weight="bold"
                                                color="#595959"
                                                className="map-pin"
                                            />
                                            {location?.[0].address}
                                        </span>
                                    </a>
                                    <span className="content-description">
                                        <CalendarBlank
                                            size={20}
                                            weight="bold"
                                            color="#595959"
                                            className="calender"
                                        />
                                        {`${location?.[0]?.openingFields?.[0]
                                            ?.openingDay || ''
                                            } (${location?.[0]?.openingFields?.[0]
                                                ?.openingTime || ''
                                            })`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default FindUs;
