interface IconPropsInterface {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
}

const GasPumpSvg = (props: IconPropsInterface) => {
    return (
        <svg
            width={props.width ?? '24'}
            height={props.height ?? '24'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3.5 13.5V3.5C3.5 3.23478 3.60536 2.98043 3.79289 2.79289C3.98043 2.60536 4.23478 2.5 4.5 2.5H9.5C9.76522 2.5 10.0196 2.60536 10.2071 2.79289C10.3946 2.98043 10.5 3.23478 10.5 3.5V13.5" stroke={props.fill ?? "#8B8B8B"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 13.5H12" stroke={props.fill ?? "#8B8B8B"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 7H12C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8946 7.48043 13 7.73478 13 8V10.5C13 10.7652 13.1054 11.0196 13.2929 11.2071C13.4804 11.3946 13.7348 11.5 14 11.5C14.2652 11.5 14.5196 11.3946 14.7071 11.2071C14.8946 11.0196 15 10.7652 15 10.5V5.41421C15 5.149 14.8946 4.89464 14.7071 4.70711L13.5 3.5" stroke={props.fill ?? "#8B8B8B"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.5 7H5.5" stroke="#8B8B8B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default GasPumpSvg;