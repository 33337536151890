import { useTranslation } from 'react-i18next';

const contents = [
    {
        icon: '/images/assurance-refund-policy.webp',
        title: 'caRefundPolicy',
        text: 'caRefundPlicyDesc'
    },
    {
        icon: '/images/assurance-warranty.webp',
        title: 'caWarranty',
        text: 'caWarrantyDesc'
    },
    {
        icon: '/images/assurance-inspected.webp',
        title: 'Inspected',
        text: 'caInspectedDesc'
    }
];

export const CustomerAssurance = () => {
    const { t } = useTranslation();
    return (
        <section className="section-customer-assurance">
            <div className="customer-assurance-row">
                {contents.map((content) => (
                    <div className="customer-assurance-box" key={content.title}>
                        <div className="customer-assurance-box__icon">
                            <img
                                src={content.icon}
                                alt={t(content.title)}
                                className="customer-assurance-box__photo"
                            />
                        </div>
                        <div className="customer-assurance-box__text-group">
                            <div className="customer-assurance-box__title">
                                {t(content.title)}
                            </div>
                            <div className="customer-assurance-box__text">
                                {t(content.text)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};
